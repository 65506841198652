import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { Targeting } from '@feathr/blackbox';
import { FormSummaryItem, Skeleton } from '@feathr/components';
import { useStore } from '@feathr/extender/state';

import { CampaignContext } from '../CampaignEditPage/CampaignEditPage.context';

interface IProps {
  validationErrors?: {
    targets?: string[];
  };
}

const TargetsSummary = observer(({ validationErrors }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const { Segments, Targetables } = useStore();
  const { targetings } = useContext(CampaignContext);

  function filterTargetings(trgt: Targeting): boolean {
    return trgt.get('kind') !== 'geo' && !trgt.get('is_archived') && !!trgt.get('target_data');
  }

  const filteredTargetings = targetings?.models.filter(filterTargetings) ?? [];
  const hasTargetings = filteredTargetings.length > 0;
  const targetingListItems = filteredTargetings.map((trgt) => {
    if (trgt.get('kind') !== 'segment') {
      return <li key={trgt.id}>{Targetables.get(trgt.get('target_data')!).get('name')}</li>;
    }
    return (
      <li key={trgt.id}>
        {Segments.get(trgt.get('target_data')!).get('name')} |{' '}
        {trgt.get('included') ? t('Included') : t('Excluded')}
      </li>
    );
  });

  return (
    <FormSummaryItem
      errors={validationErrors?.targets}
      label={t('Targets')}
      value={
        targetings?.isPending ? (
          <ol>
            <li>
              <Skeleton width={100} />
            </li>
          </ol>
        ) : hasTargetings ? (
          <ol>{targetingListItems}</ol>
        ) : (
          t('(None)')
        )
      }
    />
  );
});

TargetsSummary.displayName = 'TargetsSummary';

export default TargetsSummary;

import debounce from 'lodash.debounce';
import { autorun } from 'mobx';
import { Observer, observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type {
  EmailVerification,
  EmailVerifications as EmailVerificationsCollection,
  PinpointPartnerMessage,
  Templates,
} from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import { Button, Spinner, Step, Steps, toast, Wizard } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useStore } from '@feathr/extender/state';
import {
  DEFAULT_DEBOUNCE_WAIT,
  flattenErrors,
  logUserEvents,
  useReactionEffect,
} from '@feathr/hooks';

import EventPartnersMessageStepFive, { validateStepFive } from './EventPartnersMessageStepFive';
import EventPartnersMessageStepFour, { validateStepFour } from './EventPartnersMessageStepFour';
import EventPartnersMessageStepOne, { validateStepOne } from './EventPartnersMessageStepOne';
import EventPartnersMessageStepSeven from './EventPartnersMessageStepSeven';
import EventPartnersMessageStepSix, { validateStepSix } from './EventPartnersMessageStepSix';
import EventPartnersMessageStepThree from './EventPartnersMessageStepThree';
import EventPartnersMessageStepTwo, { validateStepTwo } from './EventPartnersMessageStepTwo';

interface IValidatePinpointPartnerMessageProps {
  message: PinpointPartnerMessage;
  emailVerification?: EmailVerification;
}

const getEmailVerifications = debounce(
  (message: PinpointPartnerMessage, EmailVerifications: EmailVerificationsCollection) => {
    if (message.isPending) {
      return EmailVerifications.newListResponse();
    }
    return message.getEmailVerifications(EmailVerifications);
  },
  DEFAULT_DEBOUNCE_WAIT,
  { leading: true },
);

const getCompletedStep = ({
  message,
  emailVerification,
}: IValidatePinpointPartnerMessageProps): number => {
  if (flattenErrors(validateStepOne(message)).length) {
    return 0;
  }

  if (flattenErrors(validateStepTwo(message, emailVerification)).length) {
    return 1;
  }

  if (flattenErrors(validateStepFour(message)).length) {
    return 3;
  }

  if (validateStepFive(message).length) {
    return 4;
  }

  if (flattenErrors(validateStepSix(message)).length) {
    return 5;
  }

  return 6;
};

function getHashStep(): number | undefined {
  const matchedHash = /\#step(\d{1})$/.exec(location.hash);
  return matchedHash ? +matchedHash[1] - 1 : undefined;
}

const useTemplateSubjectSync = (message: PinpointPartnerMessage, Templates: Templates) => {
  const isFirstRender = useRef(true);

  useEffect(() =>
    autorun(() => {
      const templateId = message.get('template_id');
      const subject = message.get('subject');
      if (templateId) {
        const template = Templates.get(templateId);
        const templateSubject = template.get('subject');
        if (isFirstRender.current && templateSubject && templateSubject !== subject) {
          message.set({ subject: templateSubject });
          isFirstRender.current = false;
        }
        template.set({ subject });
      }
    }),
  );
};

function EventPartnersMessagePage(): JSX.Element {
  const { Campaigns, EmailVerifications, Templates } = useStore();
  const { messageId } = useParams<{ messageId: string }>();
  const [currentStep, setCurrentStep] = useState(0);
  const [completeStep, setCompleteStep] = useState<number | undefined>(0);
  const { t } = useTranslation();

  const message = Campaigns.get(messageId) as PinpointPartnerMessage;
  const templateId = message.get('template_id');
  const template = templateId ? Templates.get(templateId) : undefined;

  const emailVerifications = getEmailVerifications(message, EmailVerifications);
  const hashStep = getHashStep();

  useTemplateSubjectSync(message, Templates);

  const doCompleteStep = useCallback(() => {
    if (!emailVerifications || message.isPending || emailVerifications.isPending) {
      return;
    }

    const emailVerification = message.getEmailVerification(emailVerifications);

    if (
      [CampaignState.Published, CampaignState.Publishing].includes(
        message.get('state', CampaignState.Draft),
      )
    ) {
      if (hashStep) {
        setCurrentStep(hashStep);
      } else {
        setCurrentStep(6);
      }
      setCompleteStep(6);
    } else {
      const step = getCompletedStep({
        message,
        emailVerification,
      });
      if (hashStep) {
        setCurrentStep(hashStep);
      } else {
        setCurrentStep(step);
      }
      setCompleteStep(step);
    }
  }, [emailVerifications, hashStep, message]);

  useReactionEffect(
    () => !!emailVerifications && !message.isPending && !emailVerifications.isPending,
    () => {
      if (!emailVerifications || message.isPending || emailVerifications.isPending) {
        return;
      }
      doCompleteStep();
    },
    { once: true },
  );

  useEffect(() => {
    if (!completeStep) {
      doCompleteStep();
    }
  }, [completeStep, doCompleteStep]);

  if (message.isPending) {
    return <Spinner />;
  }

  function handleNext(): void {
    const nextStep = Math.min(currentStep + 1, 6);
    setCurrentStep(nextStep);
    if (completeStep === undefined || completeStep < currentStep) {
      setCompleteStep(currentStep);
    }
  }

  function handlePrevious(): void {
    const previousStep = Math.max(currentStep - 1, 0);
    setCurrentStep(previousStep);
  }

  async function handleSend(state: CampaignState): Promise<void> {
    try {
      await message.patchDirty();
      if (template) {
        template.patchDirty();
      }
      if (state === CampaignState.Publishing) {
        await message.publish();
        if (template) {
          // template.read_only set to true on the backend
          template.reload();
        }
      }
      logUserEvents({ 'Partner message published': { message_id: message.id } });
      toast(
        state === CampaignState.Publishing ? t('Message scheduled to send') : t('Message saved'),
        {
          type: state === CampaignState.Publishing ? 'success' : 'info',
        },
      );
      // If err is instance of Error, it should be of type any.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      let error = e.toString();
      if (e.json) {
        error = e.json.message;
      }
      toast(t('Something went wrong while saving your message: {{- error}}', { error }));
    }
  }

  async function handleSaveDraft(): Promise<void> {
    await handleSend(CampaignState.Draft);
  }

  async function handleCancel(): Promise<void> {
    await message.stop();
    if (template) {
      // template.read_only set to true on the backend
      template.reload();
    }
    toast(t('Canceled sending message'), { type: 'info' });
  }

  function onSave(): void {
    handleSend(CampaignState.Publishing);
  }

  const isComplete = [
    CampaignState.Published,
    CampaignState.Publishing,
    CampaignState.Erroring,
  ].includes(message.get('state', CampaignState.Draft));

  const actions = [
    <Observer key={'draft'}>
      {(): JSX.Element => {
        const state: CampaignState = message.get('state', CampaignState.Draft);
        return (
          <>
            {state === CampaignState.Draft && (
              <Button
                disabled={template ? !(message.isDirty || template.isDirty) : !message.isDirty}
                onClick={handleSaveDraft}
              >
                {t('Save as draft')}
              </Button>
            )}
            {state === CampaignState.Published &&
              !(message as PinpointPartnerMessage).isPastStartDate && (
                <Button onClick={handleCancel}>{t('Cancel sending')}</Button>
              )}
          </>
        );
      }}
    </Observer>,
  ];

  const steps = (
    <Steps current={currentStep} initialCompleted={completeStep} onChange={setCurrentStep}>
      <Step key={1} stepIndex={0} title={t('Name')} />
      <Step key={2} stepIndex={1} title={t('Sender Info')} />
      <Step key={3} stepIndex={2} title={t('Campaign (optional)')} />
      <Step key={4} stepIndex={3} title={t('Audience')} />
      <Step key={5} stepIndex={4} title={t('Message details')} />
      <Step key={6} stepIndex={5} title={t('Schedule')} />
      <Step title={isComplete ? t('Overview') : t('Review')} />
    </Steps>
  );

  const isReadOnly = !message.isEditable;

  return (
    <Page title={t('Edit Partner Message')} width={'wide'}>
      <Wizard actions={actions} steps={steps}>
        {currentStep === 0 && (
          <EventPartnersMessageStepOne
            disabled={isReadOnly}
            message={message}
            onNext={handleNext}
          />
        )}
        {currentStep === 1 && (
          <EventPartnersMessageStepTwo
            disabled={isReadOnly}
            message={message}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        )}
        {currentStep === 2 && (
          <EventPartnersMessageStepThree
            disabled={isReadOnly}
            message={message}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        )}
        {currentStep === 3 && (
          <EventPartnersMessageStepFour
            disabled={isReadOnly}
            message={message}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        )}
        {currentStep === 4 && (
          <EventPartnersMessageStepFive
            disabled={isReadOnly}
            message={message}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        )}
        {currentStep === 5 && (
          <EventPartnersMessageStepSix
            disabled={isReadOnly}
            message={message}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        )}
        {currentStep === 6 && (
          <EventPartnersMessageStepSeven
            disabled={isReadOnly}
            message={message}
            onCancel={handleCancel}
            onPrevious={handlePrevious}
            onSave={onSave}
          />
        )}
      </Wizard>
    </Page>
  );
}

export default observer(EventPartnersMessagePage);

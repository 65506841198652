import { ObjectId } from 'bson';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { Moment } from 'moment';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { Campaign, IGeoFencingPlace, Targetable, Targeting } from '@feathr/blackbox';
import { CampaignClass, CampaignState, TargetableClass } from '@feathr/blackbox';
import {
  CardV2 as Card,
  ContextMenu,
  DatePicker,
  EmptyState,
  FileUpload,
  Input,
  Label,
  NumberInput,
  Select,
  toast,
} from '@feathr/components';
import { flattenError, getIconForAction, moment, momentToDate, TimeFormat } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import { useTargetable } from '../AdWizardTargetsStep.useTargetable';
import GeoFenceTargetingMap from './GeoFenceTargetingMap';

interface IProps {
  campaign: Campaign;
  targeting: Targeting;
  onRemove: (targeting: Targeting) => void;
}

interface IUnitsOption {
  id: 'kilometers' | 'meters';
  name: string;
}

interface IErrors extends TValidateGrouped {
  radius?: string[];
}

async function parseFile(csvFile: File, targetable: Targetable): Promise<void> {
  const Papa = await import(/* webpackChunkName: "papaparse" */ 'papaparse');
  Papa.parse<{ latitude: number; longitude: number; name: string }>(csvFile, {
    header: true,
    complete: (results) => {
      const uploadedPlaces: IGeoFencingPlace[] = results.data
        .filter((row) => {
          if (row.latitude > 90 || row.latitude < -90) {
            toast(`"${row.name}" has invalid latitude - must be between -90° and 90°.`, {
              type: 'error',
            });
            return false;
          }
          if (row.longitude > 180 || row.longitude < -180) {
            toast(`"${row.name}" has invalid longitude - must be between -180° and 180°.`, {
              type: 'error',
            });
            return false;
          }
          return !!row.latitude && !!row.longitude;
        })
        .map((row) => ({
          lat: row.latitude,
          lng: row.longitude,
          name: row.name,
          id: new ObjectId().toHexString(),
        }));
      runInAction(() => {
        targetable.set({
          places: targetable.get('places', []).concat(uploadedPlaces),
        });
      });
    },
  });
}

function getMaxDate(): Date {
  const now = moment.utc();
  return momentToDate(now.subtract(1, 'month').endOf('day'));
}

function getMinDate(): Date {
  const now = moment.utc();
  return momentToDate(now.subtract(1, 'year').endOf('day'));
}

function GeoFenceTargeting({ campaign, targeting, onRemove }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { targetable, onRemoveTargeting } = useTargetable({
    campaign,
    targeting,
    onRemove,
    overrides: {
      _cls: TargetableClass.factual,
      radius: 1000,
      units: 'meters',
      is_historical: campaign.get('_cls') === CampaignClass.MobileGeoFenceRetargeting,
      places: [],
    },
  });

  function handleRadiusChange(newValue?: number): void {
    const units = targetable.get('units');
    targetable.set({
      radius: units === 'meters' ? Number(newValue) : Number(newValue) * 1000,
    });
  }

  let endMoment: Moment | undefined;
  let endDate: Date | undefined;
  let startMoment: Moment | undefined;
  let startDate: Date | undefined;
  if (targetable.get('date_end')) {
    endMoment = moment.utc(targetable.get('date_end'));
    endDate = momentToDate(endMoment);
  }
  if (targetable.get('date_start')) {
    startMoment = moment.utc(targetable.get('date_start'));
    startDate = momentToDate(startMoment);
  }
  const minDate = getMinDate();
  const maxDate = getMaxDate();

  const validationErrors = targetable.validate<IErrors>(['radius'], false, 'grouped').errors;
  const hasPlaces = (targetable.get('places') ?? []).length > 0;

  const addPlaceButton = (
    <FileUpload
      attribute={'file_url'}
      disabled={campaign.get('state') === CampaignState.Published}
      label={'Upload CSV'}
      model={targetable}
      name={'upload_csv'}
      onUpload={(key, container, file, filename) => {
        const url = `https://s3.amazonaws.com/${container}/${encodeURIComponent(key!)}`;
        targetable.set({
          file_url: url,
          file_name: filename,
        });
        parseFile(file as File, targetable);
      }}
      pickerOptions={{
        accept: ['text/comma-separated-values', 'text/csv', 'application/csv', '.csv'],
        maxFiles: 1,
        exposeOriginalFile: true,
        storeTo: {
          location: 's3',
          container: 'feathr-import-data',
          access: 'public',
          region: 'us-east-1',
        },
      }}
    />
  );

  return (
    <Card>
      <Card.Header padding={'compact'} title={t('Target')}>
        <ContextMenu buttonType={'icon'}>
          <ContextMenu.Item
            onClick={onRemoveTargeting}
            prefix={getIconForAction('delete')}
            theme={'danger'}
          >
            {t('Remove')}
          </ContextMenu.Item>
        </ContextMenu>
      </Card.Header>
      <Card.Content addVerticalGap={true}>
        <GeoFenceTargetingMap campaign={campaign} targetable={targetable} />
        {!hasPlaces && (
          <EmptyState
            description={
              <Trans t={t}>
                Optional: Upload a <code>.csv</code> file of your target locations. Each location
                should be specified as latitude/longitude coordinates.{' '}
              </Trans>
            }
            label={t('No places added')}
            theme={'slate'}
          >
            <FileUpload
              attribute={'file_url'}
              disabled={campaign.get('state') === CampaignState.Published}
              label={'Upload CSV'}
              model={targetable}
              name={'upload_csv'}
              onUpload={(key, container, file, filename) => {
                const url = `https://s3.amazonaws.com/${container}/${encodeURIComponent(key!)}`;
                targetable.set({
                  file_url: url,
                  file_name: filename,
                });
                parseFile(file as File, targetable);
              }}
              pickerOptions={{
                accept: ['text/comma-separated-values', 'text/csv', 'application/csv', '.csv'],
                maxFiles: 1,
                exposeOriginalFile: true,
                storeTo: {
                  location: 's3',
                  container: 'feathr-import-data',
                  access: 'public',
                  region: 'us-east-1',
                },
              }}
            />
          </EmptyState>
        )}

        {targetable.get('file_url') ? (
          <div>
            <Label>File</Label>
            <div>{targetable.get('file_name')}</div>
          </div>
        ) : (
          hasPlaces && addPlaceButton
        )}
      </Card.Content>
      <Card.Content addVerticalGap={true}>
        <Input
          attribute={'name'}
          disabled={campaign.get('state') === CampaignState.Published}
          label={'Name'}
          model={targetable}
          name={'name'}
          required={true}
          type={'text'}
        />
        <NumberInput
          disabled={campaign.get('state') === CampaignState.Published}
          helpText={t(
            'The area around your target location to target ads to. Minimum: 1,000 meters. Maximum: 30,000 meters.',
          )}
          label={'Radius'}
          max={targetable.get('units') === 'meters' ? 30000 : 30}
          min={targetable.get('units') === 'meters' ? 1000 : 1}
          name={'radius'}
          onChange={handleRadiusChange}
          validationError={flattenError(validationErrors.radius)}
          value={
            targetable.get('units') === 'meters'
              ? targetable.get('radius', 0)
              : targetable.get('radius', 0) / 1000
          }
        />
        <Select<IUnitsOption>
          disabled={campaign.get('state') === CampaignState.Published}
          label={'Units'}
          name={'units'}
          onSelectSingle={(option) => targetable.set({ units: option.id })}
          options={[
            { id: 'kilometers', name: 'kilometers' },
            { id: 'meters', name: 'meters' },
          ]}
          value={{ id: targetable.get('units'), name: targetable.get('units') }}
        />
        {campaign.get('_cls') === CampaignClass.MobileGeoFenceRetargeting && (
          <>
            <DatePicker
              autoComplete={'off'}
              disabled={campaign.get('state') === CampaignState.Published}
              label={t('Start date')}
              maxDate={maxDate}
              minDate={minDate}
              name={'date_start'}
              onDateStrChange={(dateStr) => {
                const patch: { date_start: string; date_end?: string } = {
                  date_start: moment(dateStr).utc().format(TimeFormat.isoDate),
                };
                const newStartMoment = moment.utc(dateStr, moment.ISO_8601);
                if (endMoment && newStartMoment.isAfter(endMoment.clone().subtract(3, 'days'))) {
                  patch.date_end = newStartMoment
                    .startOf('day')
                    .add(2, 'days')
                    .format(TimeFormat.isoDate);
                }
                targetable.set(patch);
              }}
              selected={startDate}
            />
            <DatePicker
              autoComplete={'off'}
              disabled={campaign.get('state') === CampaignState.Published}
              label={t('End date')}
              maxDate={maxDate}
              minDate={startDate}
              name={'date_end'}
              onDateStrChange={(dateStr) => {
                targetable.set({ date_end: moment(dateStr).utc().format(TimeFormat.isoDate) });
              }}
              selected={endDate}
            />
          </>
        )}
      </Card.Content>
    </Card>
  );
}

export default observer(GeoFenceTargeting);

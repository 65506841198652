import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { ToastType } from 'react-toastify';

import type { Form } from '@feathr/blackbox';
import { EFormState } from '@feathr/blackbox';
import type { ISorted } from '@feathr/components';
import { Button, ConfirmModalV1, Fieldset, Input, Table, toast, Toolbar } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useAccount, useLocalUrl, useStore } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

import formColumns from './formsColumns';

const initialSort: ISorted[] = [{ id: 'date_last_modified', desc: true }];

function FormsPage(): JSX.Element {
  const account = useAccount();
  const [isModalVisible, toggleModalVisible] = useToggle(false);
  const { eventId } = useParams<{ eventId: string }>();
  const { Forms } = useStore();
  const history = useHistory();
  const localUrl = useLocalUrl();
  const [name, setName] = useState<string | undefined>();
  const { t } = useTranslation();

  async function handleAdd(): Promise<void> {
    toggleModalVisible();
    try {
      const form = Forms.create({
        account: account.id,
        event: eventId,
        name,
        state: EFormState.Draft,
      });
      const response = await Forms.add(form);
      if (response.isErrored) {
        throw response.error;
      }
      history.push(localUrl(response.getItemUrl('edit')));
    } catch (error) {
      toast(t('There was an error creating the form.'), { type: ToastType.ERROR });
    }
  }

  function handleNameChange(newValue?: string): void {
    setName(newValue);
  }

  const actions = (
    <Toolbar>
      <Button
        name={'create-form-button'}
        onClick={toggleModalVisible}
        prefix={<FontAwesomeIcon icon={faPlus} />}
        type={'primary'}
      >
        {t('Create form')}
      </Button>
    </Toolbar>
  );

  const filters = {
    event: eventId,
  };

  return (
    <Page actions={actions} title={t('Forms')}>
      <Table<Form>
        collection={Forms}
        columns={formColumns(t)}
        filters={filters}
        initialSort={initialSort}
        noDataText={t('No forms')}
      />
      {isModalVisible && (
        <ConfirmModalV1
          cancelButtonText={t('Cancel')}
          confirmButtonText={t('Create')}
          confirmDisabled={!name}
          onClose={toggleModalVisible}
          onConfirm={handleAdd}
          t={t}
          title={t('Create new form')}
        >
          <Fieldset>
            <Input
              label={t('Form name')}
              onChange={handleNameChange}
              placeholder={t('Enter a form name...')}
              type={'text'}
              value={name}
            />
          </Fieldset>
        </ConfirmModalV1>
      )}
    </Page>
  );
}

export default FormsPage;

import type { TFunction } from 'i18next';
import type { JSX } from 'react';
import React from 'react';

import type { Domain, IDomain } from '@feathr/blackbox';
import { Spinner } from '@feathr/components';
import type { ListResponse } from '@feathr/rachis';

import AuthorizedDomainSelect from './AuthorizedDomainSelect';
import NoDomainsAlert from './NoDomainsAlert';
import SingleDomain from './SingleDomain';

export interface IOption {
  label: IDomain['content_domain'];
  value: IDomain['redirect']['id'];
}

interface IFunctionArgs {
  domains: ListResponse<Domain>;
  selectedDomain: IDomain['redirect']['id'] | undefined;
  setSelectedDomain: (option: IOption) => void;
  showLinks?: boolean;
  t: TFunction;
}

export function getDomainComponent({
  domains,
  selectedDomain,
  setSelectedDomain,
  showLinks = true,
  t,
}: IFunctionArgs): JSX.Element {
  if (domains.isPending) {
    return <Spinner />;
  }

  const readyDomains = domains.models.filter((domain) => domain.isContentServingReady);

  if (readyDomains.length === 1) {
    const domain = readyDomains[0];
    return (
      <SingleDomain domain={domain} setSelectedDomain={setSelectedDomain} showLinks={showLinks} />
    );
  }

  if (readyDomains.length > 1) {
    return (
      <AuthorizedDomainSelect
        label={t('Content serving domain')}
        onSelectSingle={setSelectedDomain}
        selectedDomainId={selectedDomain}
      />
    );
  }

  return <NoDomainsAlert />;
}

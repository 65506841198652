import { when } from 'mobx';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AsyncSelect } from '@feathr/components';
import { useStore } from '@feathr/extender/state';

import type { IOption } from './domains.utils';

interface IProps {
  label?: string;
  onSelectSingle: (option: IOption) => void;
  selectedDomainId?: string;
}

function AuthorizedDomainSelect({
  label,
  onSelectSingle,
  selectedDomainId,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { Domains } = useStore();
  const [domainOptions, setDomainOptions] = useState<IOption[] | undefined>(undefined);

  async function loadOptions(inputValue: string): Promise<IOption[]> {
    const domains = Domains.list({ filters: { content_domain__icontains: inputValue } });
    await when(() => !domains.isPending);

    const readyDomainOptions = domains.models
      .filter((domain) => domain.isContentServingReady)
      .map((domain) => ({ label: domain.get('content_domain'), value: domain.get('redirect').id }));

    // Set domain options on the first render to retrieve current value.
    if (domainOptions === undefined) {
      setDomainOptions(readyDomainOptions);
    }

    return readyDomainOptions;
  }

  function getOptionLabel({ label }: IOption): string {
    return label;
  }

  function getOptionValue({ value }: IOption): string {
    return value;
  }

  return (
    <AsyncSelect
      defaultOptions={true}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      label={label ?? t('Content serving domain')}
      loadOptions={loadOptions}
      name={'authorized-domain-select'}
      onSelectSingle={onSelectSingle}
      value={domainOptions?.find((option) => option.value === selectedDomainId)}
    />
  );
}

export default AuthorizedDomainSelect;

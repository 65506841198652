import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { Campaign, Flavors } from '@feathr/blackbox';
import { Button, toast } from '@feathr/components';
import { errorMessage } from '@feathr/hooks';
import type { EReportFlavors } from '@feathr/report_components/state';
import { useRole, useStore } from '@feathr/report_components/state';

import { flavorToInteractionMap } from './CampaignEngagementCard';

interface IProps {
  campaign: Campaign;
  dateEnd?: string;
  dateStart?: string;
  flavor: EReportFlavors;
  localUrl: ((url: string) => string) | undefined;
}

function CreateGroupButton({
  campaign,
  dateEnd,
  dateStart,
  flavor,
  localUrl,
}: Readonly<IProps>): JSX.Element {
  const { Segments } = useStore();
  const { hasSegments, hasSegmentsSome } = useRole();
  const [hasAccess, setHasAccess] = useState(false);
  // Start with loading state to prevent flash of disabled button
  const [isCheckingAccess, setIsCheckingAccess] = useState(true);
  const { t } = useTranslation();

  // Update access when role updates
  useEffect(() => {
    setHasAccess(hasSegments);
  }, [hasSegments]);

  // Check access when limited access is detected
  useEffect(() => {
    const checkAccess = async (): Promise<void> => {
      setIsCheckingAccess(true);
      if (hasSegmentsSome) {
        const authorized = await Segments.authorize(campaign.id);
        setHasAccess(authorized);
      }
      setIsCheckingAccess(false);
    };
    checkAccess();
  }, [hasSegmentsSome, Segments, campaign.id]);

  async function handleCreateGroup(): Promise<void> {
    try {
      // We need to use a custom endpoint due to access limitations with engagement segments
      const newGroup = await Segments.limited({
        campaign_id: campaign.id,
        end: dateEnd,
        // TODO: Clean up Flavors type and all its derivatives
        flavor: flavor as keyof typeof Flavors,
        name: `${campaign.name} -- ${flavorToInteractionMap(t)[flavor]}`,
        start: dateStart,
      });
      if (newGroup.isErrored) {
        throw newGroup.error;
      }

      toast(t('Your group was successfully created.'), { type: ToastType.SUCCESS });
      window.open(localUrl!(newGroup.getItemUrl()), '_blank');
    } catch (error) {
      toast(
        t('There was an error creating your group: {{error}}', {
          error: errorMessage(error, t),
        }),
        {
          type: ToastType.ERROR,
        },
      );
    }
  }

  const tooltip = ((): string | undefined => {
    if (!hasAccess) {
      return t("You don't have access to create a group.");
    }
    return undefined;
  })();

  return (
    <Button
      disabled={!hasAccess}
      isLoading={isCheckingAccess}
      name={'create-group-button'}
      onClick={handleCreateGroup}
      target={'_blank'}
      tooltip={tooltip}
    >
      {t('Create group')}
    </Button>
  );
}

export default observer(CreateGroupButton);

import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Goal } from '@feathr/blackbox';
import { Radios } from '@feathr/components';

interface IProps {
  goal: Goal;
}

function ConversionModelRadios({ goal }: IProps): JSX.Element {
  const { t } = useTranslation();

  const multiConversionOptions = [
    { id: 'solo', name: t('Count only the first conversion'), value: false },
    { id: 'multi', name: t('Count multiple conversions'), value: true },
  ];

  const multiConversionName = `multi_conversion_${goal.id}`;

  function handleMultiConversionOptionChange(newValue?: string): void {
    /*
     * Typing with possiblity of newValue being undefined to satisfy
     * prop typing of onChange in Radios component, but newValue should
     * never be undefined
     */
    if (!newValue) {
      return;
    }

    goal.set({
      multi_conversions: multiConversionOptions.find(({ id }) => id === newValue)!.value,
    });
  }

  return (
    <Radios
      helpText={t('Choose what happens if the same person converts more than once.')}
      label={t('Multi conversion tracking')}
      layout={'block'}
      name={multiConversionName}
      onChange={handleMultiConversionOptionChange}
      options={multiConversionOptions}
      orientation={'horizontal'}
      value={multiConversionOptions[goal.get('multi_conversions', false) === false ? 0 : 1].id}
    />
  );
}

export default observer(ConversionModelRadios);

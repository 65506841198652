import type { UniqueIdentifier } from '@dnd-kit/core';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { Dispatch, JSX, SetStateAction } from 'react';
import React from 'react';

import type { IListRowItem } from '@feathr/blackbox';
import { FieldDataType, type IRowItem } from '@feathr/blackbox';
import { Button, Input, Toggle } from '@feathr/components';
import { getIconForAction } from '@feathr/hooks';

import SelectListOptions from './SelectListOptions';

import * as styles from './FieldProperties.css';

interface IProps {
  field: IRowItem;
  onFocusField: Dispatch<SetStateAction<IRowItem | undefined>>;
  updateFieldProperties: (key: UniqueIdentifier, value: unknown) => void;
  onDeleteField: () => void;
}

function FieldProperties({
  field,
  onFocusField,
  updateFieldProperties,
  onDeleteField,
}: Readonly<IProps>): JSX.Element {
  function handleToggleRequired(): void {
    updateFieldProperties('required', !field.required);
  }

  function handleChangeLabel(newValue?: string): void {
    updateFieldProperties('label', newValue ?? '');
  }

  function handleChangeHelpText(newValue?: string): void {
    updateFieldProperties('help_text', newValue ?? '');
  }

  function handleChangePlaceholder(newValue?: string): void {
    updateFieldProperties('placeholder', newValue ?? '');
  }

  function handleChangeOptions(newValue?: string[]): void {
    updateFieldProperties('options', newValue ?? []);
  }

  function handleClickBack(): void {
    onFocusField(undefined);
  }

  const { date, float, int, str, list } = FieldDataType;

  function isListField(field: IRowItem): field is IListRowItem {
    return field.type === list;
  }

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <Button
          onClick={handleClickBack}
          prefix={getIconForAction('back')}
          type={'icon-outlined'}
        />
        <span className={styles.title}>{t('Edit {{field}}', { field: field.label })}</span>
      </div>
      <div className={styles.inputs}>
        <Toggle
          label={t('Make required')}
          layout={'well'}
          onChange={handleToggleRequired}
          value={field.required}
        />
        <Input disabled={true} label={t('Field')} value={field.name} />
        <Input label={t('Label')} onChange={handleChangeLabel} value={field.label} />
        <Input
          label={t('Help text')}
          onChange={handleChangeHelpText}
          optional={true}
          value={field.help_text}
        />
        {[str, int, float, date].includes(field.type) && (
          <Input
            label={t('Placeholder text')}
            onChange={handleChangePlaceholder}
            optional={true}
            value={field.placeholder}
          />
        )}
        {isListField(field) && <SelectListOptions field={field} onChange={handleChangeOptions} />}
        <Button
          onClick={onDeleteField}
          prefix={getIconForAction('delete')}
          type={'dangerSecondary'}
        >
          {t('Delete field')}
        </Button>
      </div>
    </div>
  );
}

export default observer(FieldProperties);

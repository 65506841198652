import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, Targeting } from '@feathr/blackbox';
import { Categories, TargetableClass } from '@feathr/blackbox';
import { CardV2 as Card, ContextMenu, Select } from '@feathr/components';
import { getIconForAction } from '@feathr/hooks';

import { useTargetable } from '../AdWizardTargetsStep.useTargetable';
import AffinityTargetingDataSelect from './AffinityTargetingDataSelect/AffinityTargetingDataSelect';

interface IProps {
  campaign: Campaign;
  targeting: Targeting;
  onRemove: (targeting: Targeting) => void;
}

interface ICategoryOption {
  id: number;
  name: string;
}

interface ICategoryGroups {
  label: string;
  options: ICategoryOption[];
}

function AffinityTargeting({ campaign, targeting, onRemove }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { targetable, onRemoveTargeting } = useTargetable({
    campaign,
    targeting,
    onRemove,
    overrides: { _cls: TargetableClass.affinity, category_id: 468 },
  });

  const handleCategorySelect = useCallback(
    (option: ICategoryOption | ICategoryGroups) =>
      targetable.set({ category_id: (option as ICategoryOption).id }),
    [targetable],
  );

  const selectedGroup = Categories.find(
    (cat: ICategoryGroups) =>
      !!cat.options.find((opt: ICategoryOption) => opt.id === targetable.get('category_id')),
  );
  const selectedCategory =
    selectedGroup &&
    selectedGroup.options.find((cat: ICategoryOption) => cat.id === targetable.get('category_id'));

  return (
    <Card>
      <Card.Header padding={'compact'} title={t('Target')}>
        <ContextMenu buttonType={'icon'}>
          <ContextMenu.Item
            name={'remove_segment'}
            onClick={onRemoveTargeting}
            prefix={getIconForAction('delete')}
            theme={'danger'}
          >
            {t('Remove')}
          </ContextMenu.Item>
        </ContextMenu>
      </Card.Header>
      <Card.Content addVerticalGap={true}>
        <>
          <Select<ICategoryOption | ICategoryGroups>
            helpText={t('Choose an affinity category to narrow your search.')}
            label={t('Affinity category')}
            name={'category_select'}
            onSelectSingle={handleCategorySelect}
            options={Categories}
            required={true}
            value={selectedCategory}
          />
          <AffinityTargetingDataSelect targetable={targetable} />
        </>
      </Card.Content>
    </Card>
  );
}

export default observer(AffinityTargeting);

import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Domain } from '@feathr/blackbox';
import { Icon, Value } from '@feathr/components';
import { Button } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';

import type { IOption } from './domains.utils';

import * as styles from './SingleDomain.css';

interface IProps {
  domain: Domain;
  setSelectedDomain: (option: IOption) => void;
  showLinks?: boolean;
}

function SingleDomain({ domain, setSelectedDomain, showLinks = true }: IProps): JSX.Element {
  const { t } = useTranslation();
  const localUrl = useLocalUrl();

  /**
   * Set domain on first render.
   * This will not re-render because the account has only one authorized domain.
   */

  setSelectedDomain({
    label: domain.get('content_domain'),
    value: domain.get('redirect').id,
  });

  return (
    <div className={styles.root}>
      <Value label={t('Content serving domain')} value={domain.get('content_domain')} />
      {showLinks && (
        <Button
          className={styles.link}
          href={localUrl('/settings/account/domains')}
          suffix={<Icon icon={faExternalLink} />}
          target={'_blank'}
          type={'link'}
        >
          {t('View authorized domains')}
        </Button>
      )}
    </div>
  );
}

export default observer(SingleDomain);

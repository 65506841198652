import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { Campaign, Targetable, Targeting } from '@feathr/blackbox';
import { CampaignClass, CampaignState, TargetableClass } from '@feathr/blackbox';
import {
  AlertV2,
  CardV2 as Card,
  ContextMenu,
  EAlertV2Type,
  EmptyState,
  FileUpload,
  toast,
} from '@feathr/components';
import { useAccount } from '@feathr/extender/state';
import { getIconForAction } from '@feathr/hooks';

import { useTargetable } from '../AdWizardTargetsStep.useTargetable';

import * as styles from '../SegmentTargeting/SegmentTargeting.css';

interface IProps {
  campaign: Campaign;
  targeting: Targeting;
  onRemove: (targeting: Targeting) => void;
}

const uniqueEmailThresholds = {
  [CampaignClass.EmailListFacebook]: 2500,
  [CampaignClass.EmailList]: 2500,
};
const uniqueEmailRecommendation = 10000;

function EmailListTargeting({ campaign, targeting, onRemove }: Readonly<IProps>): JSX.Element {
  const account = useAccount();
  const { t } = useTranslation();
  const isFacebookEmailCampaign = campaign.get('_cls') === CampaignClass.EmailListFacebook;

  const { targetable, onRemoveTargeting } = useTargetable({
    campaign,
    targeting,
    onRemove,
    overrides: {
      _cls: TargetableClass.email_list,
    },
  });

  async function handleUpload(key, container, file, filename): Promise<any> {
    const Papa = await import('papaparse');
    const parseFile = (csvFile: File, csvTargetable: Targetable): Promise<unknown> => {
      return new Promise((resolve) => {
        function onComplete(results): void {
          resolve(results.data);
          runInAction(() => {
            const uniqueEmails = new Set(
              results.data.filter((row) => !!row.email).map((row) => row.email),
            );
            csvTargetable.set({ num_unique_emails: uniqueEmails.size });
          });
        }

        Papa.parse<Record<'email', string>>(csvFile, {
          header: true,
          complete: onComplete,
        });
      });
    };

    const parsedData: any = await parseFile(file as File, targetable);
    const threshold = uniqueEmailThresholds[campaign.get('_cls')];
    if (Object.keys(parsedData[0])[0] !== 'email') {
      toast('The uploaded CSV does not have a column with an "email" header', {
        type: 'error',
      });
    } else if (Object.keys(parsedData[0]).length > 1) {
      toast('The uploaded CSV cannot contain more than one column', {
        type: 'error',
      });
    } else if ((targetable.get('num_unique_emails') || 0) < threshold) {
      toast(
        `The uploaded CSV must have at least ${numeral(threshold).format(
          '0,0',
        )} unique email addresses.`,
        {
          type: 'error',
        },
      );
    } else {
      const url = `https://s3.amazonaws.com/${container}/${encodeURIComponent(key!)}`;
      const advertiserId = account.get('ttd')?.id ?? 'noAdvId';
      const fileExt = filename.split('.').pop();
      const targetId = targetable.get('id');
      const cleanFileName = `${advertiserId}-${targetId}.${fileExt}`;

      targetable.set({
        name: cleanFileName,
        list_file: url,
        list_file_name: filename,
      });
    }
  }

  const isAboveEmailMinimumThreshold =
    targetable.get('num_unique_emails', 0) >= uniqueEmailThresholds[campaign.get('_cls')];
  const isBelowEmailMinimumRecommendation =
    targetable.get('num_unique_emails', 0) < uniqueEmailRecommendation;
  const emailCountRecommendation = numeral(uniqueEmailRecommendation).format('0,0');
  const emailCountProvided = numeral(targetable.get('num_unique_emails', 0)).format('0,0');

  return (
    <Card>
      <Card.Header padding={'compact'} title={t('Target')}>
        <ContextMenu buttonType={'icon'}>
          <ContextMenu.Item
            name={'remove_targeting'}
            onClick={onRemoveTargeting}
            prefix={getIconForAction('delete')}
            theme={'danger'}
          >
            {t('Remove')}
          </ContextMenu.Item>
        </ContextMenu>
      </Card.Header>
      {targetable.get('list_file') && (
        <Card.Content
          addVerticalGap={true}
          description={targetable.get('list_file_name') || targetable.get('name')}
          title={t('File')}
        />
      )}
      {targetable.get('list_file') && targetable.get('num_unique_emails') ? (
        <Card.Content
          addVerticalGap={true}
          description={emailCountProvided}
          title={t('Unique email addresses')}
        >
          {targetable.validate([], false).errors.map((error, index) => (
            <p className={styles.error} key={index}>
              {error}
            </p>
          ))}
          {isAboveEmailMinimumThreshold &&
            isBelowEmailMinimumRecommendation &&
            isFacebookEmailCampaign && (
              <AlertV2
                // We really need to remove the margin bottom on our alerts
                className={styles.alert}
                description={t(
                  'Feathr recommends {{emailCountRecommendation}} unique email addresses to ensure good results from the email mapping process.',
                  { emailCountRecommendation },
                )}
                title={t(
                  'This campaign may not perform well with {{emailCountProvided}} unique email addresses.',
                  { emailCountProvided },
                )}
                type={EAlertV2Type.warning}
              />
            )}
        </Card.Content>
      ) : (
        <Card.Content>
          <EmptyState
            description={
              <Trans t={t}>
                Upload a <code>.csv</code> file of your target email addresses. The file should
                consist of a single column with one email address per row, and with header "email".{' '}
                <a
                  href={
                    'https://feathr-static-assets.s3.amazonaws.com/data/email_list_template.csv'
                  }
                >
                  Click here
                </a>{' '}
                to download an example file to use as a template.
              </Trans>
            }
            label={t('No email addresses uploaded')}
            theme={'slate'}
          >
            <FileUpload
              attribute={'list_file'}
              disabled={campaign.get('state') === CampaignState.Published}
              label={'Upload CSV'}
              model={targetable}
              name={'upload_csv'}
              onUpload={handleUpload}
              pickerOptions={{
                accept: ['text/comma-separated-values', 'text/csv', 'application/csv', '.csv'],
                maxFiles: 1,
                exposeOriginalFile: true,
                storeTo: {
                  location: 's3',
                  container: 'feathr-import-data',
                  access: 'public',
                  region: 'us-east-1',
                },
              }}
            />
          </EmptyState>
        </Card.Content>
      )}
    </Card>
  );
}

export default observer(EmailListTargeting);

import type { JSX } from 'react';
import React from 'react';

import type { Account } from '@feathr/blackbox';
import { Icon } from '@feathr/components';
import { packageLicenseColorMap, packageLicenseToIconMap } from '@feathr/extender/styles/license';

import * as styles from './LicensePackageBadge.css';

interface IProps {
  activePackage: Account['activePackage'];
}

function LicensePackageBadge({ activePackage }: IProps): JSX.Element {
  const licenseColor = packageLicenseColorMap(activePackage?.name);
  const licenseIcon = packageLicenseToIconMap(activePackage?.name);
  const dynamicBadgeStyles = {
    ['--l-icon-background-color' as string]: licenseColor,
    ['--l-icon-size' as string]: '36px',
  };

  return (
    <div className={styles.icon} style={dynamicBadgeStyles}>
      <Icon icon={licenseIcon} />
    </div>
  );
}

export default LicensePackageBadge;

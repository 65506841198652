import { runInAction, toJS, when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Option } from 'react-select/src/filters';
import { ToastType } from 'react-toastify';

import { EUserRoleIDs, type UserRole } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { AsyncSelect, toast } from '@feathr/components';
import { useAccount, useRole, useStore, useUser } from '@feathr/extender/state';
import { errorMessage } from '@feathr/hooks';
import type { ListResponse } from '@feathr/rachis';

interface IRoleOption extends ISelectOption {
  disabled?: boolean;
}

interface IRoleSelectProps {
  /** ID to disable from the options menu. */
  disableById?: string;
  /** ID to exclude from the options menu. */
  excludeById?: string;
  helpText?: ReactNode;
  label?: ReactNode;
  includeGlobalRoles?: boolean;
  menuPortalTarget?: HTMLElement;
  userId?: string;
  value?: string;
  onChange?: (id: string) => void;
}

function RoleSelect({
  disableById,
  helpText,
  excludeById,
  includeGlobalRoles = false,
  userId,
  value,
  onChange,
  ...additionalProps
}: Readonly<IRoleSelectProps>): JSX.Element {
  const { t } = useTranslation();
  const account = useAccount();
  const { UserRoles } = useStore();
  const [loading, setLoading] = useState<boolean>(false);
  const [roleOptions, setRoleOptions] = useState<IRoleOption[]>([]);
  const { id: currentUserId } = useUser();
  const userRoles = account.get('user_roles');
  const { isInternalOnboarding } = useRole();

  function convertModelToOptions(roles: ListResponse<UserRole>): IRoleOption[] {
    return roles.models.map(({ id, name }: UserRole): IRoleOption => ({ id: id, name: name }));
  }

  async function getRoleOptions(): Promise<IRoleOption[]> {
    setLoading(true);
    try {
      const roleOptions: IRoleOption[] = [];
      const roles = UserRoles.list();
      await when(() => !roles.isPending);
      roleOptions.push(...convertModelToOptions(roles));
      if (includeGlobalRoles && isInternalOnboarding) {
        roleOptions.push(
          // When on account users settings page, don't allow changing existing roles to these
          { id: EUserRoleIDs.Accountant, name: t('Accountant') },
          { id: EUserRoleIDs.CSM, name: t('CSM') },
          { id: EUserRoleIDs.Strategist, name: t('Strategist') },
        );
        // Sort roles that start with 000000000000000000000 in place alphabetically
        // by name; everything else stays in the same order
        roleOptions.sort((a: IRoleOption, b: IRoleOption): number => {
          // Sort roles that start with 000000000000000000000 first
          const aIsDefinedRole = a.id.startsWith('000000000000000000000');
          const bIsDefinedRole = b.id.startsWith('000000000000000000000');
          if (aIsDefinedRole && bIsDefinedRole) {
            return a.name.localeCompare(b.name);
          }
          if (aIsDefinedRole && !bIsDefinedRole) {
            return -1;
          }
          if (!aIsDefinedRole && bIsDefinedRole) {
            return 1;
          }
          // Do not change order
          return 0;
        });
      }
      setRoleOptions(roleOptions);
      return roleOptions;
    } catch (error) {
      toast(t('Failed to load roles: {{- error}}', { error: errorMessage(error, t) }), {
        type: ToastType.ERROR,
      });
      return [];
    } finally {
      setLoading(false);
    }
  }

  async function updateUserRole({ id: roleId }: IRoleOption): Promise<void> {
    if (userId) {
      runInAction((): void => {
        // TODO: FIX THIS!
        /*
        if this were a SYNC app, this wouldn't be an issue, but due to it being ASYNC 
        1. user a changes permissions for user c, d, e, and submits, after user b loads the same page
        2. user b changes permissions for users e, f, g
        3. permissions changes for users c, d are reverted, and changes for user e are overridden
        */
        account.set({
          user_roles: [
            ...userRoles.filter((i) => i.user !== userId),
            { user: userId, role: roleId },
          ],
        });
      });

      try {
        const updatedAccount = await account.patchDirty();
        if (updatedAccount.isErrored) {
          toast(
            t("Something went wrong while updating the user's role: {{- error}}", {
              error: errorMessage(updatedAccount.error, t),
            }),
            {
              type: ToastType.ERROR,
            },
          );
          return;
        }

        toast(
          t("User's role was reassigned to {{roleName}}.", {
            roleName: roleOptions.find((option) => option.id === roleId)?.name,
          }),
          { type: ToastType.SUCCESS },
        );
      } catch (error) {
        toast(
          t("Something went wrong while updating the user's role: {{- error}}", {
            error: errorMessage(error, t),
          }),
          {
            type: ToastType.ERROR,
          },
        );
      }
    }
  }

  async function handleRoleSelected(selectedRole: IRoleOption): Promise<void> {
    const { id: newRoleId } = selectedRole;
    if (userId) {
      const currentRoleId = userRoles.find((i) => i.user === userId)?.role;
      if (newRoleId !== currentRoleId) {
        await updateUserRole(selectedRole);
      }
    }
    onChange?.(newRoleId);
  }

  function getValue(): IRoleOption | undefined {
    if (userId) {
      const userRole = toJS(userRoles.find((i) => i.user === userId));
      const defaultOption = roleOptions.find((option) => option.name === 'User');
      return roleOptions.find((option) => userRole?.role === option.id) ?? defaultOption;
    }
    return roleOptions.find(({ id }: IRoleOption): boolean => id === value);
  }

  function filterOption({ label, value }: Option, rawInput: string): boolean {
    const input = rawInput.toLowerCase();
    return !(label.toLowerCase().includes(input) && value === excludeById);
  }

  function isOptionDisabled({ id, disabled = false }: IRoleOption): boolean {
    return (disableById && id === disableById) || disabled;
  }

  return (
    <AsyncSelect
      {...additionalProps}
      cacheOptions={true}
      defaultOptions={true}
      disabled={currentUserId === userId}
      filterOption={filterOption}
      isLoading={loading}
      isOptionDisabled={isOptionDisabled}
      loadOptions={getRoleOptions}
      name={'role-select'}
      onSelectSingle={handleRoleSelected}
      options={roleOptions}
      placeholder={t('Select a role...')}
      value={getValue()}
    />
  );
}

export default observer(RoleSelect);

import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { Campaign, Targetable, Targeting } from '@feathr/blackbox';
import { CampaignState, TargetableClass } from '@feathr/blackbox';
import { CardV2 as Card, ContextMenu, EmptyState, FileUpload } from '@feathr/components';
import { getIconForAction } from '@feathr/hooks';

import { useTargetable } from '../AdWizardTargetsStep.useTargetable';

import * as styles from '../SegmentTargeting/SegmentTargeting.css';

interface IProps {
  campaign: Campaign;
  targeting: Targeting;
  onRemove: (targeting: Targeting) => void;
}

async function parseFile(csvFile: File, targetable: Targetable): Promise<void> {
  const Papa = await import(/* webpackChunkName: "papaparse" */ 'papaparse');
  Papa.parse<Record<string, string>>(csvFile, {
    header: false,
    complete: (results) => {
      runInAction(() => {
        const uniqueTerms = new Set(results.data.filter((row) => !!row[0]).map((row) => row[0]));
        targetable.set({ num_keywords: uniqueTerms.size });
      });
    },
  });
}

function SearchKeywordTargeting({ campaign, targeting, onRemove }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { isDraft } = campaign;

  const { targetable, onRemoveTargeting } = useTargetable({
    campaign,
    targeting,
    onRemove,
    overrides: { _cls: TargetableClass.search },
  });

  async function handleOnUpload(key, container, file, filename): Promise<void> {
    const url = `https://s3.amazonaws.com/${container}/${encodeURIComponent(key!)}`;
    targetable.set({
      name: filename,
      src_search_keywords: url,
      src_filename: filename,
    });
    await parseFile(file as File, targetable);
  }

  return (
    <Card>
      <Card.Header padding={'compact'} title={t('Target')}>
        <ContextMenu buttonType={'icon'}>
          <ContextMenu.Item
            disabled={!isDraft}
            name={'remove_targeting'}
            onClick={onRemoveTargeting}
            prefix={getIconForAction('delete')}
            theme={'danger'}
            tooltip={!isDraft && t('Cannot remove keyword list from a published campaign.')}
          >
            {t('Remove')}
          </ContextMenu.Item>
        </ContextMenu>
      </Card.Header>
      {targetable.get('src_search_keywords') && (
        <Card.Content
          addVerticalGap={true}
          description={targetable.get('name')}
          title={t('File')}
        />
      )}
      {targetable.get('src_search_keywords') ? (
        <Card.Content
          description={numeral(targetable.get('num_keywords')).format('0,0')}
          title={t('Unique keywords')}
        >
          {!targetable.isValid([], false) && (
            <p className={styles.error}>{targetable.validate([], false).errors}</p>
          )}
        </Card.Content>
      ) : (
        <Card.Content>
          <EmptyState
            description={
              <Trans t={t}>
                Upload a <code>.csv</code> file of your target search keywords. The file should
                consist of a single column with one term or group of terms per row, with no header.{' '}
                <a
                  href={
                    'https://feathr-static-assets.s3.amazonaws.com/data/keyword_list_template.csv'
                  }
                >
                  Click here
                </a>{' '}
                to download an example file to use as a template.
              </Trans>
            }
            label={t('No search keywords uploaded')}
            theme={'slate'}
          >
            <FileUpload
              attribute={'src_search_keywords'}
              disabled={campaign.get('state') === CampaignState.Published}
              label={t('Upload CSV')}
              model={targetable}
              name={'upload_csv'}
              onUpload={handleOnUpload}
              pickerOptions={{
                storeTo: {
                  location: 's3',
                  container: 'feathr-import-data',
                  access: 'public',
                  region: 'us-east-1',
                },
                accept: ['text/comma-separated-values', 'text/csv', 'application/csv', '.csv'],
                maxFiles: 1,
                exposeOriginalFile: true,
              }}
            />
          </EmptyState>
        </Card.Content>
      )}
    </Card>
  );
}

export default observer(SearchKeywordTargeting);

import React from 'react';
import type { OptionProps, SingleValueProps } from 'react-select';
import { components } from 'react-select';

import type { ICreativeAttributes } from '@feathr/blackbox';
import CampaignChip from '@feathr/extender/components/CampaignChip';

import CreativeThumbnail from '../CreativeThumbnail';

import { optionGrid, optionGridCell } from './SelectOptions.css';

export function CreativeOption(props: OptionProps<ICreativeAttributes>) {
  return (
    <components.Option {...props}>
      <div className={optionGrid}>
        <div className={optionGridCell}>
          <CreativeThumbnail creative={props.data} height={100} width={100} />
        </div>
        <div className={optionGridCell}>
          <span>{props.data.name || '-'}</span>
        </div>
        <div className={optionGridCell}>
          <span>
            {props.data.width && props.data.height
              ? `${props.data.width}×${props.data.height}`
              : '-'}
          </span>
        </div>
        <div className={optionGridCell}>
          {!!props.data.parent && <CampaignChip id={props.data.parent} linkToItem={false} />}
        </div>
      </div>
    </components.Option>
  );
}

export function CreativeSingleValue(props: SingleValueProps<ICreativeAttributes>) {
  return (
    <components.SingleValue {...props}>
      <div className={optionGrid}>
        <div className={optionGridCell}>
          <span>{props.data.name || '-'}</span>
        </div>
        <div className={optionGridCell}>
          <span>
            {props.data.width && props.data.height
              ? `${props.data.width}×${props.data.height}`
              : '-'}
          </span>
        </div>
        <div className={optionGridCell}>
          {!!props.data.parent && <CampaignChip id={props.data.parent} linkToItem={false} />}
        </div>
      </div>
    </components.SingleValue>
  );
}

import { toJS } from 'mobx';
import { computedFn } from 'mobx-utils';
import numeral from 'numeral';

import type {
  Account,
  Billable,
  Campaign,
  Event,
  Segment,
  Targetable,
  User,
} from '@feathr/blackbox';
import {
  CAMPAIGN_MAX_BUDGET_INTERNAL,
  CampaignClass,
  CampaignState,
  getMaxTargetValue,
} from '@feathr/blackbox';
import { moment } from '@feathr/hooks';

import type { ICampaignValidationErrors } from '../../../CampaignSummary';

export const getAudienceSize: (
  campaign: Campaign,
  segments: Segment[],
  targetables: Targetable[],
) => number = computedFn((campaign: Campaign, segments: Segment[], targetables: Targetable[]) => {
  const cls: CampaignClass = campaign.get('_cls');

  // Retargeting or Meta Retargeting
  if ([CampaignClass.Segment, CampaignClass.Facebook].includes(cls)) {
    return segments.map((s) => s.reachable).reduce((acc, c) => acc + c, 0);
  }

  // Email Mapping or Meta Email Mapping
  if ([CampaignClass.EmailList, CampaignClass.EmailListFacebook].includes(cls)) {
    return targetables.reduce((acc, t) => {
      acc += t.get('num_unique_emails') || 0;
      return acc;
    }, 0);
  }

  // We use these numbers to scale the recommend budget by small, medium, and large expected audiences sizes
  switch (cls) {
    // Campaigns that target a specific audience are huge, so we limit the audience size to 100k to prevent an offensive recommended budget

    // Legacy Lookalike
    case CampaignClass.Lookalike:

    // Current Lookalike
    case CampaignClass.SeedSegment:

    case CampaignClass.Affinity: {
      return 100000;
    }

    // Search and Geo-like campaigns have smaller audiences on the order of 10s of thousands.

    // Search Keyword
    case CampaignClass.Search:

    // Historical Geofencing
    case CampaignClass.MobileGeoFencing:

    // Mobile Geofencing
    case CampaignClass.MobileGeoFenceRetargeting: {
      return 10000;
    }

    default: {
      // 1000 for default since first party groups tend to be pretty small
      return 1000;
    }
  }
});

// This calculation mirrors the incoming backend calculation for the number of days in a campaign
export function getDays(campaign: Campaign): number {
  const dateStart = campaign.get('date_start');
  const dateEnd = campaign.get('date_end');
  if (!(dateStart && dateEnd)) {
    return 0;
  }
  const startMoment = moment.utc(dateStart, moment.ISO_8601);
  const endMoment = moment.utc(dateEnd, moment.ISO_8601);

  const duration = moment.duration(endMoment.diff(startMoment));
  const hours = duration.asHours();
  const numDays = Math.ceil(hours / 24);

  return numDays;
}

export function getMinBudget(campaign: Campaign): number {
  const days = getDays(campaign);
  if (campaign.isMonetization) {
    return days * 4000;
  }

  return days * 5;
}

export const getRecommendedImpressions: (
  campaign: Campaign,
  segments: Segment[],
  targetables: Targetable[],
) => number = computedFn((campaign: Campaign, segments: Segment[], targetables: Targetable[]) => {
  // Impressions are just for monetization campaigns
  if (!campaign.isMonetization) {
    return 0;
  }
  const audienceSize = getAudienceSize(campaign, segments, targetables);
  const reach = 0.015;
  const freqCap = campaign.get('bidding_strategy').freq_cap;
  const days = getDays(campaign);
  return audienceSize * reach * freqCap * days;
});

export function getRecommendedBid(size: number): number {
  /*
   * This will be revisited at a later date. Intentionally leaving the multiple
   * if statements that evaluate to the same number.
   */
  if (size > 20000) {
    return 5;
  }
  if (size < 20000 && size > 5000) {
    return 5;
  }
  return 10;
}

export function validateStepBudget(
  campaign: Campaign,
  event: Event,
  billable: Billable | undefined,
  account: Account,
  user?: User,
  noMaxBudget?: boolean,
): ICampaignValidationErrors {
  const monetization = campaign.get('parent_kind') === 'partner';

  const attributes = ['date_start', 'date_end', 'state', '_cls'];
  if (monetization) {
    attributes.push('monetization_value');
  }

  const errors = {
    budget: [] as string[],
    date_start: [] as string[],
    date_end: [] as string[],
    monetization_value: [] as string[],
    /*
     * We have to pass along state and _cls so they show up in the attributes provided
     * to the date_start validator
     */
    ...(toJS(campaign.validate(attributes, false, 'grouped').errors) as Record<string, string[]>),
  };

  const facebook = [CampaignClass.Facebook, CampaignClass.EmailListFacebook].includes(
    campaign.get('_cls'),
  );
  const canEditBudget = !!campaign.get('date_start') && !!campaign.get('date_end');
  const endDate = campaign.get('date_end');
  const isDraft = campaign.get('state') === CampaignState.Draft;
  const budget = campaign.get('exposure_settings').target_value || 0;
  const billing = event.get('billing');
  const balance = billing?.balance ?? 0;
  const isComplete = moment.utc(endDate).isBefore(moment.utc());
  const hasPaymentMethod =
    billable && !billable.isPending && billable.get('stripe')
      ? !!billable.get('stripe').source
      : false;
  const minBudget = getMinBudget(campaign);
  const minRemainingBudget = getMinBudget(campaign) - budget;
  const spend: number = campaign.get('total_stats')?.spend || 0;
  if (!billable) {
    errors.budget.push(
      'You need to add a billing configuration to this project in order to publish a campaign that incurs media spend.',
    );
  }
  const exposureSettingsHaveChanged = campaign.isAttributeDirty('exposure_settings');
  /*
   *  Allow editing goals once spend has exceeded budget.
   *  https://github.com/Feathr/shrike/issues/1998
   *
   *  This previously did not take into account the state of the campaign,
   *  allowing the user to set a zero budget, save as draft, reload the
   *  page and publish with a zero budget.
   */
  const addErrorsAfterPublishedIfBudgetIsTouched = !isDraft && exposureSettingsHaveChanged;

  // Internal accounts are capped at $25 to prevent spending real money accidentally
  if (account.get('is_internal') && budget > CAMPAIGN_MAX_BUDGET_INTERNAL) {
    errors.budget.push(
      `Internal accounts can only create campaigns with budgets $${CAMPAIGN_MAX_BUDGET_INTERNAL} and under.`,
    );
  }

  // Prevent budgets less than the minimum budget.
  if (
    budget < minBudget &&
    !monetization &&
    canEditBudget &&
    (addErrorsAfterPublishedIfBudgetIsTouched || isDraft)
  ) {
    errors.budget.push(
      `Total budget must be at least ${numeral(minBudget).format('$0,0.00')}. Add ${numeral(
        minRemainingBudget,
      ).format('$0,0.00')} to fulfill this campaign for its duration.`,
    );
  }

  /*
   * Prevent user from setting a budget below campaign spend.
   * Do not include draft campaigns because a draft campaign
   * should never have spend associated with it.
   */
  if (budget < spend && !monetization && addErrorsAfterPublishedIfBudgetIsTouched) {
    errors.budget.push(
      `Budget must be at least $${numeral(spend).format(
        '0,0.00',
      )} to account for the amount already spent.`,
    );
  }

  /*
   *We can ignore max budget under the following circumstances:
   *- The noMaxBudget feature flag is set
   */
  if (!noMaxBudget) {
    const maxBudget = getMaxTargetValue(account.getSetting('campaigns_max_budget'), monetization);
    if (budget > maxBudget) {
      if (monetization) {
        errors.budget.push(
          `The number of impressions cannot exceed ${numeral(maxBudget).format('0,0')}.`,
        );
      } else {
        errors.budget.push(
          `The budget cannot exceed ${numeral(maxBudget).format('$0,0')} (account-wide setting).`,
        );
      }
    }
  }

  if (
    !isComplete &&
    !hasPaymentMethod &&
    budget - spend > balance &&
    account.get('media_validation') &&
    !monetization &&
    !facebook
  ) {
    const suffix =
      'Consider adding funds to your project or adding a payment method to your project billing configuration.';
    errors.budget.push(`
        Because you have no configured payment method, budget must be less than your project media credit balance
        and current campaign spend (${numeral(spend + balance).format('$0,0.00')}). ${suffix}`);
  } else if (
    !isComplete &&
    !hasPaymentMethod &&
    balance <= 0 &&
    account.get('media_validation') &&
    !!monetization
  ) {
    /*
     * TODO: Don't do this because negative balance isn't actually bad.
     * errors.budget.push(`
     *   You must configure a payment method or have a positive media credit balance in order to publish a
     *   monetization campaign.`);
     */
  }
  return errors;
}

import { faUsers } from '@fortawesome/pro-regular-svg-icons';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';

import { Segment } from '@feathr/blackbox';
import { Label } from '@feathr/components';

import SegmentDataChip from '../SegmentDataChip';

import * as styles from './SegmentOption.css';

export interface IMenuOption {
  id: string;
  name: string;
}

export type TOptionProps = OptionProps<Segment>;

export type TStat = 'num_cookies_total' | 'num_crumbs_total' | 'num_emails_total';

interface IDataProps extends OptionProps<Segment> {
  selectProps: {
    stat?: TStat;
  };
  data: Segment;
}

function SegmentOption(props: TOptionProps): JSX.Element {
  const { t } = useTranslation();
  const {
    data,
    selectProps: { stat },
  } = props as IDataProps;

  const addSegment = data.id === 'addSegment';

  return (
    <components.Option {...props}>
      {!addSegment && (
        <div className={styles.root}>
          <Label className={styles.label} weight={'normal'}>
            <Observer>{(): JSX.Element => <>{data.name}</>}</Observer>
          </Label>
          {data instanceof Segment && (
            <div className={styles.data}>
              <SegmentDataChip
                descriptor={t('people')}
                icon={faUsers}
                statType={stat}
                statValue={data.get('stats').num_users_total || 0}
                theme={'default'}
              />
              {!!stat && (
                <SegmentDataChip statType={stat} statValue={data.get('stats')[stat] || 0} />
              )}
            </div>
          )}
        </div>
      )}
    </components.Option>
  );
}

export default SegmentOption;

import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Account } from '@feathr/blackbox';
import { CardV2 as Card, Label, Time } from '@feathr/components';
import { TimeFormat } from '@feathr/hooks';

import { getMomentLicenseDate } from '../../../Settings.utils';
import LicensePackageBadge from '../LicensePackageBadge';
import LicenseEndTime from './LicenseEndTime';

import * as styles from './LicensePackageCard.css';

interface IProps {
  activePackage: Account['activePackage'];
}

function LicensePackageCard({ activePackage }: IProps): JSX.Element {
  const { t } = useTranslation();

  if (!activePackage) {
    return <>{t('No active license package.')}</>;
  }

  return (
    <Card width={'full'}>
      {!!activePackage?.name && (
        <Card.Header
          title={activePackage?.name}
          titlePrefix={<LicensePackageBadge activePackage={activePackage} />}
        />
      )}
      <Card.Content contentClassName={styles.content} padding={'relaxed'}>
        <div className={styles.licenseElement}>
          <Label>{t('Valid through')}</Label>
          <LicenseEndTime activePackage={activePackage} />
        </div>

        {!!activePackage?.grace_period?.start && (
          <div className={styles.licenseElement}>
            <Label tooltip={t('Including grace period')}>{t('Start date')}</Label>
            <Time
              format={TimeFormat.pickerDateTime}
              timestamp={getMomentLicenseDate(
                activePackage.period,
                activePackage.grace_period,
                'start',
              )}
            />
          </div>
        )}

        {!!activePackage?.grace_period?.end && (
          <div className={styles.licenseElement}>
            <Label tooltip={t('Including grace period')}>{t('End date')}</Label>{' '}
            <Time
              format={TimeFormat.pickerDateTime}
              timestamp={getMomentLicenseDate(
                activePackage.period,
                activePackage.grace_period,
                'end',
              )}
            />
          </div>
        )}

        <div className={styles.licenseElement}>
          <Label>{t('Price')}</Label>
          {numeral(activePackage?.price).format('$0,0.00')}
        </div>
      </Card.Content>
    </Card>
  );
}

export default observer(LicensePackageCard);

import moment from 'moment';

import type { IGracePeriod, IPeriod } from '@feathr/blackbox';

export function getMomentLicenseDate(
  period: IPeriod,
  gracePeriod: IGracePeriod,
  gracePeriodKey: keyof IGracePeriod,
): string {
  const { [gracePeriodKey]: days } = gracePeriod;
  const { [gracePeriodKey]: date } = period;

  return gracePeriodKey === 'start'
    ? moment(date).subtract(days, 'days').format()
    : moment(date).add(days, 'days').format();
}

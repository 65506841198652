import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, Segment, Targeting } from '@feathr/blackbox';
import { TargetableClass } from '@feathr/blackbox';
import { CardV2 as Card, ContextMenu } from '@feathr/components';
import SegmentSelectSimple from '@feathr/extender/components/SegmentSelectSimple';
import { useStore } from '@feathr/extender/state';
import { getIconForAction } from '@feathr/hooks';

import { useTargetable } from '../AdWizardTargetsStep.useTargetable';

interface IProps {
  campaign: Campaign;
  targeting: Targeting;
  onRemove: (targeting: Targeting) => void;
}

function SeedSegmentTargeting({ campaign, targeting, onRemove }: Readonly<IProps>): JSX.Element {
  const { Segments } = useStore();
  const { t } = useTranslation();
  const { targetable, onRemoveTargeting } = useTargetable({
    campaign,
    targeting,
    onRemove,
    overrides: { _cls: TargetableClass.seed_segment, category_id: 468 },
  });

  let segment: Segment | undefined;
  const segmentId = targetable.get('seed_segment');
  if (segmentId) {
    segment = Segments.get(segmentId);
  }

  const handleSegmentChange = useCallback(
    async ({ id }) => {
      if (id) {
        const newSegment = Segments.get(id);
        await when(() => !newSegment.isPending);
        targetable.set({ name: newSegment.get('name'), seed_segment: id });
      }
    },
    [targetable, Segments],
  );

  return (
    <Card>
      <Card.Header padding={'compact'} title={targetable.get('name')}>
        <ContextMenu buttonType={'icon'}>
          <ContextMenu.Item
            name={'remove_segment'}
            onClick={onRemoveTargeting}
            prefix={getIconForAction('delete')}
            theme={'danger'}
          >
            {t('Remove')}
          </ContextMenu.Item>
        </ContextMenu>
      </Card.Header>
      <Card.Content>
        <SegmentSelectSimple
          filters={{
            stats__num_cookies_total__gte: 30000,
            is_conversion_segment__ne: true,
          }}
          helpText={t(
            'Choose a group to use to derive your lookalike data for this target. Groups must have at least 30,000 reachable people to be eligible.',
          )}
          hideCreate={true}
          label={t('Group')}
          name={'segment_select'}
          onChange={handleSegmentChange}
          required={true}
          value={segment?.id}
        />
      </Card.Content>
    </Card>
  );
}

export default observer(SeedSegmentTargeting);

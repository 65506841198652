import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckCircle,
  faCircleXmark,
  faExclamationTriangle,
  faInfoCircle,
  faSparkles,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { JSX, ReactNode } from 'react';
import React, { useId } from 'react';

import * as styles from './AlertV2.css';

export enum EAlertType {
  danger = 'danger',
  warning = 'warning',
  info = 'info',
  success = 'success',
  recommendation = 'recommendation',
}

const typeToIconMap: Record<EAlertType, IconProp> = {
  [EAlertType.danger]: faCircleXmark,
  [EAlertType.info]: faInfoCircle,
  [EAlertType.warning]: faExclamationTriangle,
  [EAlertType.success]: faCheckCircle,
  [EAlertType.recommendation]: faSparkles,
};

export interface IAlertProps {
  children?: ReactNode;
  /**
   * Description of the alert. Can be a string, an array of strings, or a ReactNode. If an array of strings is provided, they will be rendered as a list with bullet points.
   */
  description?: ReactNode;
  name?: string;
  title: ReactNode;
  type: EAlertType;
  className?: string;
}

function Alert({
  type,
  title,
  description,
  children,
  className,
  name,
}: Readonly<IAlertProps>): JSX.Element {
  let descriptionContent: ReactNode = null;
  const titleId = useId();

  if (description) {
    if (Array.isArray(description)) {
      descriptionContent = (
        <ul className={styles.descriptionList}>
          {description.map((value) => (
            <li key={value}>{value}</li>
          ))}
        </ul>
      );
    } else {
      descriptionContent = <div className={styles.description}>{description}</div>;
    }
  }

  return (
    <div
      aria-labelledby={titleId}
      className={classNames(styles.root, styles[type], className)}
      data-name={name}
      role={'alert'}
    >
      <FontAwesomeIcon className={styles.icon} icon={typeToIconMap[type]} />
      <div className={styles.content}>
        <div className={styles.title} id={titleId}>
          {title}
        </div>
        {description && descriptionContent}
        {children && <div className={styles.actions}>{children}</div>}
      </div>
    </div>
  );
}

export default Alert;

import { faCheck, faShareNodes } from '@fortawesome/pro-regular-svg-icons';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useParams } from 'react-router';
import { ToastType } from 'react-toastify';

import {
  ActionBar,
  AlertV2 as Alert,
  Button,
  EAlertV2Type,
  Icon,
  Label,
  SaveButton,
  Tab,
  Time,
  toast,
} from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { errorMessage, TimeFormat, useToggle } from '@feathr/hooks';

import Page from '../../Page';
import { FormEditor } from './FormEditor';
import FormPublishModal from './FormPublishModal';
import FormShareModal from './FormShareModal';
import Settings from './Settings';

import * as styles from './FormEditPage.css';

function FormEditPage(): JSX.Element {
  const { formId } = useParams<{ formId?: string }>();
  const { Forms } = useStore();
  const [isPublishModalOpen, togglePublishModal] = useToggle(false);
  const [isShareModalOpen, toggleShareModal] = useToggle(false);

  if (!formId) {
    throw new Error('Form missing ID');
  }

  const { t } = useTranslation();
  const form = Forms.get(formId);
  const {
    date_last_published: lastPublished,
    date_last_modified: lastSaved,
    version,
    last_published_version: lastPublishedVersion,
    state,
  } = form.get([
    'date_last_published',
    'date_last_modified',
    'version',
    'last_published_version',
    'state',
  ]);
  const isPublished = state === 'published';
  const hasVersionDifference = isPublished && version !== lastPublishedVersion;
  const [activeTab, setActiveTab] = useState<'edit' | 'settings'>('edit');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Reset changed model if they weren't saved so
    // the user doesn't see the changes if they navigate away
    // and back to this page.
    return () => {
      if (form.isDirty) {
        form.discardDirty();
      }
    };
  }, [form]);

  function handleClickEditTab(): void {
    setActiveTab('edit');
  }

  function handleClickSettingsTab(): void {
    setActiveTab('settings');
  }

  const tabs = [
    <Tab
      active={activeTab === 'edit'}
      key={'edit'}
      onClick={handleClickEditTab}
      title={t('Edit')}
    />,
    <Tab
      active={activeTab === 'settings'}
      key={'settings'}
      onClick={handleClickSettingsTab}
      title={t('Settings')}
    />,
  ];

  const actions = [
    <Button
      key={'share'}
      onClick={toggleShareModal}
      prefix={<Icon icon={faShareNodes} />}
      type={'secondary'}
    >
      {t('Share')}
    </Button>,
  ];

  async function handleRepublish(): Promise<void> {
    setIsLoading(true);
    try {
      const {
        message: { detail },
      } = await form.upsync();
      toast(t('Form updated:\n{{- message}}', { message: detail }), {
        type: ToastType.SUCCESS,
      });
      await form.reload();
      setIsLoading(false);
    } catch (error) {
      toast(t('Error upsyncing form:\n{{- error}}', { error: errorMessage(error, t) }), {
        type: ToastType.ERROR,
      });
      setIsLoading(false);
    }
  }

  const isPublishDisabled = hasVersionDifference ? false : form.isDirty ? false : true;

  return (
    <Page
      actions={form.get('state') === 'published' ? actions : undefined}
      legacyScrollClassName={styles.legacyScroll}
      tabs={tabs}
      title={t('Edit {{- name}}', { name: form.name })}
    >
      <ActionBar
        left={
          <span className={styles.leftActions}>
            <Label weight={'semibold'}>
              {isPublished ? t('Last published:') : t('Last saved:')}
            </Label>
            <Time
              className={styles.lastPublished}
              format={TimeFormat.longDateTime}
              formatLocal={true}
              showTimezone={true}
              timestamp={isPublished ? lastPublished : lastSaved}
            />
          </span>
        }
        right={
          <>
            <SaveButton
              disabled={!form.isDirty}
              key={'save'}
              method={'patch'}
              model={form}
              type={'secondary'}
            />
            <Button
              // TODO: Update this with the correct validation in #4533.
              // disabled={hasVersionDifference ? form.isValid() : !form.isDirty && !form.isValid}
              disabled={isPublishDisabled}
              key={'publish'}
              onClick={isPublished || hasVersionDifference ? handleRepublish : togglePublishModal}
              prefix={<Icon icon={faCheck} />}
              type={'success'}
            >
              {isPublished ? t('Republish') : t('Publish')}
            </Button>
          </>
        }
        usePortal={true}
      />
      <Prompt
        message={t('You have unsaved changes. Are you sure you want to leave this page?')}
        when={form.isDirty}
      />
      {!isLoading && hasVersionDifference && (
        <Alert
          title={t(
            'This version is newer than the published form. Republish to apply your changes.',
          )}
          type={EAlertV2Type.info}
        />
      )}
      {activeTab === 'edit' && <FormEditor form={form} />}
      {activeTab === 'settings' && <Settings form={form} />}
      {isPublishModalOpen && (
        <FormPublishModal form={form} isOpen={isPublishModalOpen} toggle={togglePublishModal} />
      )}
      {isShareModalOpen && (
        <FormShareModal form={form} isOpen={isShareModalOpen} toggle={toggleShareModal} />
      )}
    </Page>
  );
}

export default observer(FormEditPage);

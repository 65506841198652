import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, Segment, Targeting } from '@feathr/blackbox';
import { Categories, TargetableClass } from '@feathr/blackbox';
import { Button, Card, Fieldset, Radios, Select, Tooltip } from '@feathr/components';
import type { ISegmentSelectSimpleChangeProps } from '@feathr/extender/components/SegmentSelectSimple';
import SegmentSelectSimple from '@feathr/extender/components/SegmentSelectSimple';
import { useStore } from '@feathr/extender/state';

import { useTargetable } from '../AdWizardTargetsStep.useTargetable';

import * as styles from './LookalikeTargeting.css';

interface IProps {
  campaign: Campaign;
  targeting: Targeting;
  onRemove: (targeting: Targeting) => void;
}

interface ICategoryOption {
  id: number;
  name: string;
}

interface ICategoryGroups {
  label: string;
  options: ICategoryOption[];
}

// TODO: remove all old lookalike code - it's no longer being used
function LookalikeTargeting({ campaign, targeting, onRemove }: Readonly<IProps>): JSX.Element {
  const { Segments } = useStore();
  const { t } = useTranslation();

  const { targetable, onRemoveTargeting } = useTargetable({
    campaign,
    targeting,
    onRemove,
    overrides: { _cls: TargetableClass.lookalike, category_id: 468, mode: 'auto' },
  });

  let segment: Segment | undefined;
  const segmentId = targetable.get('seed_segment');
  if (segmentId) {
    segment = Segments.get(segmentId);
  }

  async function handleSegmentChange({ id }: ISegmentSelectSimpleChangeProps): Promise<void> {
    if (id) {
      const newSegment = Segments.get(id);
      await when(() => !newSegment.isPending);
      targetable.set({ name: newSegment.get('name'), seed_segment: id });
    }
  }

  function handleCategorySelect(option: ICategoryOption | ICategoryGroups): void {
    targetable.set({ category_id: (option as ICategoryOption).id });
  }

  const selectedGroup = Categories.find(
    (cat) => !!cat.options.find((opt) => opt.id === targetable.get('category_id')),
  );
  const selectedCategory = selectedGroup?.options.find(
    (cat) => cat.id === targetable.get('category_id'),
  );

  return (
    <Card
      actions={[
        <Tooltip key={'remove'} title={t('Remove')}>
          <Button
            className={styles.cardButton}
            name={'remove_segment'}
            onClick={onRemoveTargeting}
            type={'naked'}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Tooltip>,
      ]}
    >
      <Fieldset>
        <Radios
          attribute={'mode'}
          className={styles.radios}
          label={t('Mode')}
          model={targetable}
          name={'targetable_mode'}
          options={[
            { id: 'auto', name: t('Auto') },
            { id: 'manual', name: t('Custom') },
          ]}
        />
        {targetable.get('mode') === 'auto' ? (
          <SegmentSelectSimple
            filters={{
              stats__num_cookies_total__gte: 30000,
              is_conversion_segment__ne: true,
            }}
            helpText={t(
              'Choose a group to use to derive your Lookalike data for this target. Groups must have at least 30,000 reachable People to be eligible.',
            )}
            hideCreate={true}
            label={t('Group')}
            name={'segment_select'}
            onChange={handleSegmentChange}
            required={true}
            value={segment?.id}
          />
        ) : (
          <>
            <Select<ICategoryOption | ICategoryGroups>
              helpText={t('Choose a category to narrow your search')}
              label={t('Category')}
              name={'category_select'}
              onSelectSingle={handleCategorySelect}
              optional={true}
              options={Categories}
              value={selectedCategory}
            />
          </>
        )}
      </Fieldset>
    </Card>
  );
}

export default observer(LookalikeTargeting);

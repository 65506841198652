import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';

import type { FacebookCampaign, FacebookDisplayCreative } from '@feathr/blackbox';
import { Select, Tooltip } from '@feathr/components';

import type { IMetaPlatform } from '../FacebookAdPreview';
import { EMetaPlatform } from '../FacebookAdPreview';

import * as styles from './SelectAdPreviewPlatform.css';

interface ISelectPlatformAdPreviewProps {
  campaign: FacebookCampaign;
  creative: FacebookDisplayCreative;
  metaPlatforms: IMetaPlatform[];
  setIsLoading: (isLoading: boolean) => void;
  setPlatform: (platform: IMetaPlatform) => void;
  setPreviewHtml: (html: string) => void;
  platform: IMetaPlatform;
}

function MetaPlatformOption(props: OptionProps<IMetaPlatform>): JSX.Element {
  const { data } = props;
  const option = (
    <components.Option {...props}>
      <Fragment key={data.id}>
        {data.name}
        <div
          className={classNames(styles.description, {
            [styles.disabled]: data.isDisabled,
          })}
        >
          <p>{data.description}</p>
        </div>
      </Fragment>
    </components.Option>
  );
  return data.tooltip ? <Tooltip title={data.tooltip}>{option}</Tooltip> : option;
}

function SelectAdPreviewPlatform({
  campaign,
  creative,
  metaPlatforms,
  platform,
  setIsLoading,
  setPlatform,
  setPreviewHtml,
}: Readonly<ISelectPlatformAdPreviewProps>): JSX.Element {
  const { t } = useTranslation();

  async function getAdPreview({ adFormat, id }: IMetaPlatform): Promise<void> {
    setPlatform(metaPlatforms.find((p) => p.id === id)!);
    if (id === EMetaPlatform.Facebook) {
      try {
        const response = await creative.getFacebookAdPreview(adFormat, campaign);
        setPreviewHtml(response);
      } catch (error) {
        setPreviewHtml('Could not load preview');
      }
    }
    setIsLoading(false);
  }

  async function handlePlatformSelect(metaPlatform: IMetaPlatform): Promise<void> {
    setIsLoading(true);
    await getAdPreview(metaPlatform);
  }

  useEffect(() => {
    async function callGetAdPreview(): Promise<void> {
      await getAdPreview(metaPlatforms.find((p) => p.id === EMetaPlatform.Facebook)!);
    }

    callGetAdPreview().catch(() => {
      // GitHub issue to add error handling: https://github.com/Feathr/shrike/issues/1608
    });
  }, []);

  return (
    <Select
      className={styles.select}
      components={{ Option: MetaPlatformOption }}
      label={t('Choose platform')}
      name={'platform_select'}
      onSelectSingle={handlePlatformSelect}
      options={metaPlatforms}
      value={platform}
    />
  );
}

export default observer(SelectAdPreviewPlatform);

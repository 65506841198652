import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { IConfig } from '@feathr/blackbox';
import { reportModuleLabels } from '@feathr/blackbox';
import { Alert, AlertType, Checkbox, Fieldset, Form, ModalV1, Tooltip } from '@feathr/components';

import AttributionModel from '../AttributionModel';

import * as styles from './ConfigModal.css';

interface IProps<T extends IConfig> {
  config: T;
  isMonetization?: boolean;
  hasGoals?: boolean;
  isDripCampaign?: boolean;
  isEmailCampaign?: boolean;
  isGoogleCampaign?: boolean;
  onChange: (key: keyof T, value: any) => void;
  onClose: () => void;
}

const labelMap = reportModuleLabels;

export function ConfigForm<T extends IConfig>({
  config,
  hasGoals = false,
  isDripCampaign = false,
  isEmailCampaign = false,
  isGoogleCampaign = false,
  onChange,
}: Omit<IProps<T>, 'onClose' | 'isMonetization'>): JSX.Element {
  const { t } = useTranslation();
  return (
    <Form label={t('Configure Report')}>
      <Fieldset label={t('Choose report modules to include')}>
        {/* Create a checkbox for every config object key where the key starts with "include" and
        exists in the labelMap defined above. Some exceptions outlined below. */}
        {Object.entries(config)
          .filter((entry) => entry[0].startsWith('include') && labelMap[entry[0]])
          .map(([key, value]) => {
            const labelValue = labelMap[key];

            // Don't create a checkbox for the following config keys for email campaigns.
            if (
              isEmailCampaign &&
              [
                'includeActivityBreakdown',
                'includeAdActivity',
                'includeAdPerformance',
                'includeCreativesTable',
                'includeDailyStatsTable',
                'includeLeadsSummary',
                'includeFormSubmissionsTable',
                'includeMonetizationROI',
                'includePageActivity',
                'includeSpendPerformance',
                'includeTrafficBreakdown',
              ].includes(key)
            ) {
              return null;
            }

            if (isDripCampaign && ['includeCampaignEngagement'].includes(key)) {
              return null;
            }

            // Don't create a checkbox for the following config keys for google ads campaigns.
            if (
              isGoogleCampaign &&
              [
                'includeActivityBreakdown',
                'includeAdActivity',
                'includeAdPerformance',
                'includeConversionsTable',
                'includeCreativesTable',
                'includeDailyStatsTable',
                'includeEmailActivity',
                'includeHeatmap',
                'includeLeadsSummary',
                'includeLinkClicksTable',
                'includeFormSubmissionsTable',
                'includeMonetizationROI',
                'includePageActivity',
                'includeROI',
                'includeTrafficBreakdown',
              ].includes(key)
            ) {
              return null;
            }

            // Create but disable the following checkboxes if the campaign doesn't have a goal.
            if (!hasGoals && ['includeROI', 'includeConversionsTable'].includes(key)) {
              return (
                <Tooltip
                  className={styles.checkbox}
                  key={key}
                  position={'top-start'}
                  title={'Add goals to report on Conversions / Conversion Value'}
                >
                  <Checkbox
                    className={styles.disabled}
                    disabled={true}
                    label={labelValue}
                    onChange={() => {
                      onChange(key as keyof T, !value);
                    }}
                    value={!!value}
                  />
                </Tooltip>
              );
            }

            return (
              <Checkbox
                key={key}
                label={labelValue}
                onChange={() => {
                  onChange(key as keyof T, !value);
                }}
                value={!!value}
              />
            );
          })}
      </Fieldset>
      {!isGoogleCampaign && Object.keys(config).includes('attributionModel') && (
        <Fieldset label={'Configure report settings'}>
          <AttributionModel
            onChange={(newAttributionModel) => {
              onChange('attributionModel', newAttributionModel);
            }}
            value={config.attributionModel}
          />
        </Fieldset>
      )}
    </Form>
  );
}

function ConfigModal<T extends IConfig>({
  onClose,
  isMonetization,
  hasGoals = false,
  ...props
}: IProps<T>): JSX.Element {
  const configProps = {
    ...props,
    hasGoals,
  };

  const { t } = useTranslation();

  return (
    <ModalV1
      cancelButtonText={t('Ok')}
      controlled={true}
      ephemeral={true}
      onClose={onClose}
      size={'sm'}
      t={t}
      title={t('Customize Report')}
    >
      {isMonetization && (
        <Alert type={AlertType.info}>
          Because this is a Monetization Campaign, all spend data will be automatically hidden from
          the shareable report, although it is visible in the in-app report here for your use.
        </Alert>
      )}
      <ConfigForm {...configProps} />
    </ModalV1>
  );
}

export default ConfigModal;

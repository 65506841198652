import { Flex } from '@mantine/core';
import classNames from 'classnames';
import type { IObservableArray } from 'mobx';
import { autorun, runInAction, set } from 'mobx';
import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX, ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  Account,
  Billable,
  DisplayCampaign,
  Event,
  Targeting,
  TTDCampaign,
} from '@feathr/blackbox';
import { getMinDuration, getMinStartDate } from '@feathr/blackbox';
import {
  AlertV2 as Alert,
  Button,
  ButtonValid,
  CardV2 as Card,
  DatePicker,
  EAlertV2Type as EAlertType,
  EmptyState,
  Form,
  NumberInput,
} from '@feathr/components';
import { useFlags, useStore, useUser } from '@feathr/extender/state';
import {
  cssVar,
  flattenErrors,
  moment,
  momentToDate,
  TimeFormat,
  timezoneAbbr,
} from '@feathr/hooks';

import { getTargetables, getTargetSegments } from '../AdWizardTargetsStep';
import {
  getMinBudget,
  getRecommendedImpressions,
  validateStepBudget,
} from './AdWizardBudgetStep.utils';
import PaymentDetails from './PaymentDetails';
import RecommendedBudget from './RecommendedBudget';

import * as wizardStyles from '../AdWizard.css';
import * as styles from './AdWizardBudgetStep.css';

interface IProps {
  onNext: () => void;
  onPrev: () => void;
  account: Account;
  event: Event;
  billable?: Billable;
  campaign: DisplayCampaign;
  targetings: IObservableArray<Targeting>;
}

const NextStepButton = observer(
  ({ campaign, event, billable, account, onNext }: Omit<IProps, 'onPrev' | 'targetings'>) => {
    const flags = useFlags();
    const user = useUser();

    const validationErrors = validateStepBudget(
      campaign,
      event,
      billable,
      account,
      user,
      flags.noMaxBudget,
    );
    return (
      <ButtonValid errors={flattenErrors(validationErrors)} name={'next_step'} onClick={onNext}>
        Next
      </ButtonValid>
    );
  },
);

function AdWizardBudgetStep({
  account,
  campaign,
  event,
  billable,
  onNext,
  onPrev,
  targetings,
}: Readonly<IProps>): JSX.Element {
  const { Segments, Targetables } = useStore();
  const flags = useFlags();
  const user = useUser();
  const { t } = useTranslation();
  const { isFacebook, isMonetization, isDraft, isTTDCampaign } = campaign;

  const segments = getTargetSegments(targetings, Segments);
  const targetables = getTargetables(targetings, Targetables);
  const validationErrors = validateStepBudget(
    campaign,
    event,
    billable,
    account,
    user,
    flags.noMaxBudget,
  );

  const dateStart = moment.utc(campaign.get('date_start'), TimeFormat.isoDateTime);
  const dateEnd = moment.utc(campaign.get('date_end'), TimeFormat.isoDateTime);
  const canEditBudget = !!campaign.get('date_start') && !!campaign.get('date_end');
  const now = moment.utc();
  const [recBudget, setRecBudget] = useState<number>(0);
  const [minBudget, setMinBudget] = useState<number>(getMinBudget(campaign));

  const isComplete = dateEnd.isBefore(now);
  const isStarted = dateStart.isBefore(now);

  const target = getRecommendedImpressions(campaign, segments, targetables);
  const formattedTarget = numeral(target).format('0,0');
  // Default the recommended budget to the min budget if it's less than the min budget
  const recommendationRaw = Math.max(recBudget, minBudget);
  const formattedBudget = numeral(recommendationRaw).format('$0,0.00');
  const minBudgetFormatted = numeral(minBudget).format('$0,0');

  useEffect(() => {
    return autorun(() => {
      if (!isMonetization) {
        const exposureSettings = campaign.get('exposure_settings');
        const minimum = getMinBudget(campaign);

        if (!exposureSettings.custom_target && isDraft) {
          runInAction((): void => {
            set(exposureSettings, { target_value: minimum });
          });
          campaign.setAttributeDirty('exposure_settings');
        }

        setMinBudget(minimum);
      }
    });
  }, [campaign, isDraft, isMonetization, segments, targetables, canEditBudget]);

  function handleTargetValueChange(newValue?: number): void {
    const exposureSettings = campaign.get('exposure_settings');
    if (exposureSettings) {
      runInAction((): void => {
        const budget = newValue ?? 0;
        set(exposureSettings, {
          target_value: budget,
          /*
           * Once the user sets a custom target, we no longer modify the values in the
           * budget input based on date selection.
           */
          custom_target: true,
          recommended_target_percent: budget / recommendationRaw,
        });
      });
      campaign.setAttributeDirty('exposure_settings');
    }
  }

  function handleSetRecommendedBudget(): void {
    handleTargetValueChange(recommendationRaw);
  }

  function handleMonetizationValueChange(newValue?: number): void {
    campaign.set({ monetization_value: newValue ?? 0 });
  }

  let description: ReactNode;
  if (isMonetization) {
    description = t(
      'Set the target impressions for your campaign. Feathr tries to bid enough each day to meet your campaign impression target by the end of the campaign, but sometimes this is not possible due to limited audience availability.',
    );
  } else {
    description = t(
      'Feathr will suggest a budget based on the duration, audience size, and campaign type. Feathr aims to meet your campaign budget by the end, but limited audience availability may prevent this.',
    );
  }

  // Time stamps
  const startTimeStamp = campaign.get('date_start');
  const endTimeStamp = campaign.get('date_end');

  // Moment objects
  const startMoment = moment.utc(startTimeStamp).local();
  const endMoment = moment.utc(endTimeStamp).local();

  // ISO formatted timestamps
  const isoStartTimestamp =
    startTimeStamp && moment.utc(startTimeStamp).format(TimeFormat.isoDateTime);
  const isoEndTimestamp = endTimeStamp && moment.utc(endTimeStamp).format(TimeFormat.isoDateTime);

  function handleOnChangeSendStart(newTimestamp?: string): void {
    campaign.set({
      date_start: newTimestamp,
    });
  }

  function handleOnChangeDateEnd(newTimestamp?: string): void {
    campaign.set({
      date_end: newTimestamp,
    });
  }

  const overspendText = isMonetization
    ? t(
        'Due to standard bidding dynamics, campaigns may under or overreach their impression target by ~5%',
      )
    : t('Due to standard bidding dynamics, campaigns may overspend by ~5%');

  /*
   * Only show the recommended target for monetization campaigns.
   * Do not show it if it's 0 (generally due to audience selection)
   */
  const targetRecommendation =
    isMonetization && target > 0
      ? t('Recommended target: {{target}}', { target: formattedTarget })
      : undefined;

  const alertMap = {
    [EAlertType.warning]: {
      title: t("The budget you've set will limit the performance of this campaign. "),
      description: t(
        'We highly recommend a budget of at least {{formattedBudget}} for the best results.',
        { formattedBudget },
      ),
      type: EAlertType.warning,
      name: 'alert_recommended_budget',
    },
    [EAlertType.danger]: {
      title: t('This campaign cannot be published with the set budget. '),
      description: t('The minimum budget required to publish this campaign is {{min}}.', {
        min: minBudgetFormatted,
      }),
      type: EAlertType.danger,
      name: 'alert_min_budget',
    },
  };

  const noDatesTarget = isMonetization ? t('impressions and sponsor package value') : t('budget');
  const targetValue = campaign.get('exposure_settings').target_value ?? 0;

  let alertProps: null | { title: string; description: string; type: EAlertType; name: string } =
    null;
  if (targetValue < minBudget) {
    alertProps = alertMap[EAlertType.danger];
  } else if (targetValue < recommendationRaw && !isFacebook) {
    alertProps = alertMap[EAlertType.warning];
  }

  function handleClearDate(type: 'end' | 'start'): () => void {
    return function () {
      campaign.set({ [`date_${type}`]: undefined });
    };
  }

  return (
    <Form
      actions={
        // TODO: Remove this when we horizontal wizard facebook campaigns
        !isTTDCampaign && [
          <Button key={'prev'} name={'previous_step'} onClick={onPrev}>
            {t('Previous')}
          </Button>,
          <NextStepButton
            account={account}
            billable={billable}
            campaign={campaign}
            event={event}
            key={'next'}
            onNext={onNext}
          />,
        ]
      }
      className={classNames({ [wizardStyles.formRoot]: isTTDCampaign }, styles.root)}
      label={'Edit Campaign: Budget & Duration'}
    >
      <Card width={'narrow'}>
        <Card.Header title={'Duration'} />
        <Card.Content addVerticalGap={true}>
          <Flex gap={cssVar('--spacing-4')} justify={'space-between'}>
            <DatePicker
              autoComplete={'off'}
              dateFormat={'MMM d, yyyy h:mm aa'}
              disabled={!isDraft && isStarted}
              isClearable={true}
              isFullWidth={true}
              label={t('Start date')}
              minDate={momentToDate(getMinStartDate(campaign.get('_cls')))}
              name={'date_start'}
              onClear={handleClearDate('start')}
              onDateStrChange={handleOnChangeSendStart}
              showTimeSelect={true}
              suffix={timezoneAbbr(startMoment.toDate())}
              timeIntervals={5}
              validationError={validationErrors.date_start}
              value={isoStartTimestamp}
            />
            <DatePicker
              autoComplete={'off'}
              dateFormat={'MMM d, yyyy h:mm aa'}
              disabled={!isDraft && isComplete}
              isClearable={true}
              isFullWidth={true}
              label={t('End date')}
              minDate={startMoment ? momentToDate(startMoment.add(getMinDuration())) : undefined}
              name={'date_end'}
              onClear={handleClearDate('end')}
              onDateStrChange={handleOnChangeDateEnd}
              showTimeSelect={true}
              suffix={timezoneAbbr(endMoment.toDate())}
              timeIntervals={5}
              validationError={validationErrors.date_end}
              value={isoEndTimestamp}
            />
          </Flex>
        </Card.Content>
      </Card>
      <Card width={'narrow'}>
        <Card.Header description={description} title={'Budget'} />
        <Card.Content addVerticalGap={true}>
          <Flex align={'center'} gap={cssVar('--spacing-4')} justify={'space-between'}>
            {!canEditBudget && (
              <EmptyState
                description={t('Select a start and end date to access {{target}} selection.', {
                  target: noDatesTarget,
                })}
                label={'No dates selected'}
                name={'budget_empty_dates'}
                theme={'slate'}
                width={'full'}
              />
            )}
            {!isMonetization && canEditBudget && !isFacebook && (
              <RecommendedBudget
                budget={formattedBudget || minBudgetFormatted}
                campaign={campaign as TTDCampaign}
                onClick={handleSetRecommendedBudget}
                setRecBudget={setRecBudget}
              />
            )}
            {canEditBudget && (
              <NumberInput
                additionalContent={targetRecommendation}
                className={classNames({ [styles.budgetInput]: !isMonetization })}
                data-name={isMonetization ? 'impressions_target_value' : 'budget_target_value'}
                disabled={!canEditBudget}
                helpPlacement={'top'}
                helpText={overspendText}
                label={isMonetization ? t('Impressions') : t('Budget')}
                min={isMonetization ? 10 : minBudget}
                name={'budget'}
                onChange={handleTargetValueChange}
                prefix={isMonetization ? null : '$'}
                validationError={validationErrors.budget}
                value={campaign.get('exposure_settings').target_value}
              />
            )}
          </Flex>

          {alertProps && !isMonetization && canEditBudget && (
            <Alert
              className={styles.marginBuster}
              description={alertProps.description}
              name={alertProps.name}
              title={alertProps.title}
              type={alertProps.type}
            />
          )}

          {isMonetization && canEditBudget && (
            <NumberInput
              data-name={'monetization_value'}
              helpText={t(
                'How much is this campaign worth to you? Usually this is how much your partner paid to you to run the campaign. This value is only used to provide reporting context.',
              )}
              label={t('Sponsor package value')}
              min={0}
              name={'monetization_value'}
              onChange={handleMonetizationValueChange}
              prefix={'$'}
              required={true}
              validationError={validationErrors.monetization_value}
              value={campaign.get('monetization_value')}
            />
          )}
        </Card.Content>
      </Card>

      {!isFacebook && <PaymentDetails billable={billable} campaign={campaign} event={event} />}
    </Form>
  );
}

export default observer(AdWizardBudgetStep);

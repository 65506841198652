import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Account } from '@feathr/blackbox';
import { Time } from '@feathr/components';
import { TimeFormat } from '@feathr/hooks';

interface IProps {
  activePackage?: Account['activePackage'];
}

function LicenseEndTime({ activePackage }: IProps): JSX.Element | string {
  const { t } = useTranslation();
  if (!activePackage) {
    return t('Expired');
  }
  const licenseEnd = activePackage.period.end;

  if (licenseEnd) {
    return <Time format={TimeFormat.longDate} timestamp={licenseEnd} />;
  } else {
    return t('Unknown');
  }
}

export default observer(LicenseEndTime);

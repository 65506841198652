import type { IObservableArray } from 'mobx';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { OptionProps, SingleValueProps } from 'react-select';
import { components } from 'react-select';

import type {
  Campaign,
  GeoFilter as GeoFilterModel,
  IGeoFilter,
  Targetable,
  Targeting,
} from '@feathr/blackbox';
import { TargetableClass } from '@feathr/blackbox';
import { AsyncSelect, CardV2 as Card, Chip, ContextMenu, Radios } from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { getIconForAction } from '@feathr/hooks';

import * as styles from './GeoFilter.css';

interface IProps {
  campaign: Campaign;
  targeting: Targeting;
  targetings: IObservableArray<Targeting>;
  onRemove: (targeting: Targeting) => void;
}

interface IFilters {
  is_archived__ne: boolean;
  name__icontains?: string;
}

function renderOptionOrSingleValue(
  props: SingleValueProps<IGeoFilter> | OptionProps<IGeoFilter>,
): JSX.Element {
  const { data } = props;

  return (
    <div className={styles.option}>
      <span className={styles.label}>{data.name}</span>
      <dl className={styles.data}>
        {['City'].includes(data.kind) && (
          <>
            <dt className={'visuallyhidden'}>Region</dt>
            <dd>{data.address.region},</dd>
          </>
        )}
        {['Region', 'City'].includes(data.kind) && (
          <>
            <dt className={'visuallyhidden'}>Country</dt>
            <dd>{data.address.country}</dd>
          </>
        )}
        <dt className={'visuallyhidden'}>Kind</dt>
        <dd>
          <Chip>{data.kind}</Chip>
        </dd>
      </dl>
    </div>
  );
}

function Option(props: OptionProps<IGeoFilter>): JSX.Element {
  return (
    <components.Option {...props} className={styles.wrapper}>
      {renderOptionOrSingleValue(props)}
    </components.Option>
  );
}

function SingleValue(props: SingleValueProps<IGeoFilter>): JSX.Element {
  return (
    <components.SingleValue {...props} className={styles.wrapper}>
      {renderOptionOrSingleValue(props)}
    </components.SingleValue>
  );
}

function GeoFilter({ campaign, targeting, targetings, onRemove }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { GeoFilters, Targetables } = useStore();
  let targetable: Targetable | undefined;
  const targetableId = targeting.get('target_data');
  if (targetableId) {
    targetable = Targetables.get(targetableId);
  }
  const [, setFilterQuery] = React.useState('');
  let geofilter: GeoFilterModel | undefined;
  if (targetable && targetable.get('geo_filter')) {
    geofilter = GeoFilters.get(targetable.get('geo_filter')!);
  }
  const [isLoading, setIsLoading] = useState(false);

  function handleIncludedChange(newValue?: string): void {
    targeting.set({ included: newValue === 'included' });
  }

  async function loadOptions(inputValue: string): Promise<IGeoFilter[]> {
    setIsLoading(true);
    const filters: IFilters = {
      is_archived__ne: true,
    };

    if (inputValue) {
      filters.name__icontains = inputValue;
    }
    const geoFilters = GeoFilters.list({
      filters,
      pagination: {
        page: 0,
        page_size: 50,
      },
    });
    await when(() => !geoFilters.isPending);
    setIsLoading(false);
    return [
      ...geoFilters.models
        .filter(
          (gf) =>
            !targetings.find((t) => {
              const data = t.get('target_data');
              if (data) {
                return data === gf.id;
              }
              return false;
            }),
        )
        .map((gf) => gf.toJS()),
    ];
  }

  function handleRemove(): void {
    onRemove(targeting);
  }

  function handleInputChange(value: string): void {
    setFilterQuery(value);
  }

  function handleSelectSingle(option: IGeoFilter): void {
    const newGeoTargetable = Targetables.create({
      _cls: TargetableClass.geo,
      name: option.name,
      geo_filter: option.id,
      partner: campaign.get('parent_kind') === 'partner' ? campaign.get('parent') : undefined,
    });
    targeting.set({
      name: option.name,
      target_data: newGeoTargetable.get('id'),
    });
  }

  return (
    <Card>
      <Card.Header padding={'compact'} title={t('Location')}>
        <ContextMenu buttonType={'icon'}>
          <ContextMenu.Item
            key={'remove_button'}
            onClick={handleRemove}
            prefix={getIconForAction('delete')}
            theme={'danger'}
          >
            {t('Remove')}
          </ContextMenu.Item>
        </ContextMenu>
      </Card.Header>
      <Card.Content addVerticalGap>
        <AsyncSelect<IGeoFilter>
          cacheOptions={true}
          className={styles.wrapper}
          components={{ Option, SingleValue }}
          defaultOptions={true}
          isLoading={isLoading}
          loadOptions={loadOptions}
          name={'geo-filter'}
          onInputChange={handleInputChange}
          onSelectSingle={handleSelectSingle}
          placeholder={'Start typing a location...'}
          value={geofilter ? (!geofilter.isPending ? geofilter.toJS() : undefined) : undefined}
        />
        <Radios
          layout={'block'}
          onChange={handleIncludedChange}
          options={[
            { id: 'included', name: 'Included' },
            { id: 'excluded', name: 'Excluded' },
          ]}
          value={targeting.get('included') ? 'included' : 'excluded'}
        />
      </Card.Content>
    </Card>
  );
}

export default observer(GeoFilter);

import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type { Creative, DisplayCreative } from '@feathr/blackbox';
import { CreativeClass } from '@feathr/blackbox';
import { Card, Fieldset, Form, Input, SaveButtonValid, Toolbar } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';
import { useRedirect } from '@feathr/hooks';

import * as styles from './BannersnackMetadata.css';

interface IProps {
  creative: DisplayCreative;
  destinationUrlPlaceholder?: string;
}

function BannersnackMetadata({
  creative,
  destinationUrlPlaceholder,
}: Readonly<IProps>): JSX.Element {
  const localUrl = useLocalUrl();
  const { t } = useTranslation();
  const [redirect] = useRedirect();
  const { eventId } = useParams<{
    eventId: string;
  }>();
  const type = creative.get('_cls');

  function handleSave(): void {
    redirect(localUrl(`/projects/${eventId}/creatives`));
  }

  const validationFields: string[] = ['alt_text', 'destination_url'];
  if (type === CreativeClass.DisplayBannersnack) {
    validationFields.push('banner_hash');
  }

  const validationErrors = creative.validate(validationFields, false);

  return (
    <Card>
      <Form className={styles.root} label={t('Edit metadata')}>
        <Fieldset>
          <Input
            attribute={'alt_text'}
            helpText={t(
              'Please provide advertisement text with a clear call-to-action to display in the event the image cannot be loaded and for visually-impaired individuals who may use a screen reader. (Text provided here will override alternate text provided at the campaign level.)',
            )}
            label={t('Alternate text')}
            model={creative}
            optional={true}
            placeholder={t(
              "Advertisement: Don't bee too late to register for Beecon 2023! Click here to register now!",
            )}
            type={'text'}
          />
          <Input
            attribute={'destination_url'}
            disabled={creative.get('use_original_destination')}
            helpText={t('Specify a destination that is distinct for this creative.')}
            label={t('Destination URL')}
            model={creative}
            optional={true}
            placeholder={destinationUrlPlaceholder}
            type={'url'}
          />
        </Fieldset>
        <Toolbar align={'left'} className={styles.toolbar}>
          <SaveButtonValid<Creative>
            errors={toJS(validationErrors.errors)}
            method={'patch'}
            model={creative}
            onSave={handleSave}
            type={'success'}
          />
        </Toolbar>
      </Form>
    </Card>
  );
}

export default observer(BannersnackMetadata);

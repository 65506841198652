import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AlertV2 as Alert, Button, Icon } from '@feathr/components';
import { EAlertV2Type } from '@feathr/components/src/AlertV2';
import { useLocalUrl } from '@feathr/extender/state';

import * as styles from './NoDomainsAlert.css';

function NoDomainsAlert(): JSX.Element {
  const { t } = useTranslation();
  const localUrl = useLocalUrl();

  return (
    <Alert title={t('Domain setup required')} type={EAlertV2Type.warning}>
      <div className={styles.root}>
        {t('Authorize a domain to publish your form. This may take up to 24 hours.')}
        <Button
          className={styles.link}
          href={localUrl('/settings/account/domains')}
          suffix={<Icon icon={faExternalLink} />}
          target={'_blank'}
          type={'link'}
        >
          {t('Add domain')}
        </Button>
      </div>
    </Alert>
  );
}

export default NoDomainsAlert;

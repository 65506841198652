import type { IObservableArray } from 'mobx';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type {
  Campaign,
  Targetable,
  Targetables as TargetablesCollection,
  Targeting,
} from '@feathr/blackbox';
import { Button, CardV2 as Card, EmptyState, Form } from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { getIconForAction } from '@feathr/hooks';

import GeoFilter from './GeoFilter';

import * as styles from '../AdWizard.css';

interface IProps {
  campaign: Campaign;
  targetings: IObservableArray<Targeting>;
}

export function validateStepFilters(targetings: IObservableArray<Targeting>): string[] {
  const geofilters = targetings.filter(
    (t) => t.get('kind') === 'geo' && t.get('is_archived') !== true,
  );
  if (geofilters.length === 0) {
    return [];
  }
  if (geofilters.filter((t) => t.get('included')).length < 1) {
    return ['At least one Filter must be "included" rather than "excluded".'];
  }
  return targetings
    .map((t) => {
      const errors = t.validate(['target_data', 'kind'], false).errors;
      if (errors.length) {
        return errors[0];
      }
      return undefined;
    })
    .filter((msg) => !!msg) as string[];
}

export function getGeoFilters(
  targetings: IObservableArray<Targeting>,
  Targetables: TargetablesCollection,
): Targetable[] {
  return targetings
    .filter((t) => t.get('kind') === 'geo' && !!t.get('target_data') && !t.get('is_archived'))
    .map((t) => Targetables.get(t.get('target_data')!))
    .filter((f) => f.isEphemeral);
}

function AdWizardFiltersStep({ campaign, targetings }: Readonly<IProps>): JSX.Element {
  const { Targetings } = useStore();
  const { t } = useTranslation();

  function removeTargeting(targeting: Targeting): void {
    if (!targeting.isEphemeral) {
      targeting.set({ is_archived: true });
    } else {
      targetings.remove(targeting);
      Targetings.remove(targeting.id);
    }
  }

  function handleAddFilter(): void {
    const model = Targetings.create({
      parent: campaign.get('id'),
      kind: 'geo',
      included: true,
    });
    runInAction(() => {
      targetings.push(model);
    });
  }

  const filteredTargetings = targetings.filter(
    (t) => !t.get('is_archived') && t.get('kind') === 'geo',
  );

  const addFilterButton = (
    <Button id={'addFilter'} key={'add'} onClick={handleAddFilter} prefix={getIconForAction('add')}>
      {t('Add filter')}
    </Button>
  );

  return (
    <Form className={styles.formRoot} label={'Edit Campaign: Filters'}>
      {/* TODO: fix model is invalid error with clicking save as draft  */}
      <Card>
        <Card.Header
          description={t(
            'Use Filters to include or exclude locations. Ads will only show to people in included areas and will not show to people in excluded areas. Filtering is optional but recommended.',
          )}
          title={t('Filters')}
        />
        <Card.Content addVerticalGap={true}>
          {filteredTargetings.length === 0 ? (
            <EmptyState
              description={t('Add a filter to get started')}
              label={t('No filters added')}
              theme={'slate'}
            >
              {addFilterButton}
            </EmptyState>
          ) : (
            filteredTargetings.map((targeting, index) => {
              return (
                <GeoFilter
                  campaign={campaign}
                  key={targeting.id || index}
                  onRemove={removeTargeting}
                  targeting={targeting}
                  targetings={targetings}
                />
              );
            })
          )}
          {filteredTargetings.length > 0 && addFilterButton}
        </Card.Content>
      </Card>
    </Form>
  );
}

export default observer(AdWizardFiltersStep);

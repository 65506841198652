import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import {
  faBadgeCheck,
  faBullseyePointer,
  faEnvelopeOpenText,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { TTheme } from '@feathr/components';
import { Chip } from '@feathr/components';

import type { TStat } from '../SegmentOption';

interface IProps {
  /** A descriptor to display alongside the statistic. Overrides the default descriptor of the component. */
  descriptor?: string;
  /** The icon to display alongside the statistic. Overrides the icons provided by the component. */
  icon?: IconDefinition;
  statType?: TStat;
  statValue: number;
  theme?: string;
}

function SegmentDataChip({
  descriptor,
  icon,
  statType = 'num_cookies_total',
  statValue,
  theme,
}: IProps): JSX.Element {
  const { t } = useTranslation();

  const statisticToLabel: Record<TStat, string> = {
    num_cookies_total: t('reachable people'),
    num_crumbs_total: t('breadcrumbs'),
    num_emails_total: t('unique email addresses'),
  };

  const statisticToIcon: Record<TStat, IconDefinition> = {
    num_cookies_total: faBadgeCheck,
    num_crumbs_total: faBullseyePointer,
    num_emails_total: faEnvelopeOpenText,
  };

  const statisticToTheme: Record<TStat, TTheme> = {
    num_cookies_total: 'green',
    num_crumbs_total: 'sky',
    num_emails_total: 'yellow',
  };

  const formattedValue = numeral(statValue).format('0,0');
  const tooltip = `${formattedValue} ${descriptor ?? statisticToLabel[statType]}`;

  return (
    <Observer>
      {(): JSX.Element => (
        <Chip
          prefix={<FontAwesomeIcon icon={icon ?? statisticToIcon[statType]} />}
          theme={theme ?? statisticToTheme[statType]}
          tooltip={tooltip}
        >
          {formattedValue}
        </Chip>
      )}
    </Observer>
  );
}

export default SegmentDataChip;

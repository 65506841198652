import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, ITargetable, Targetable, Targeting } from '@feathr/blackbox';
import { CampaignClass, EIntegrationTypes } from '@feathr/blackbox';
import { useStore } from '@feathr/extender/state';

interface IUseTargetableProps {
  campaign: Campaign;
  targeting: Targeting;
  onRemove: (targeting: Targeting) => void;
  // Force _cls to be required
  overrides: Partial<ITargetable> & Pick<ITargetable, '_cls'>;
}

interface IUseTargetableReturn {
  targetable: Targetable;
  onRemoveTargeting: () => void;
}

export function useTargetable({
  campaign,
  targeting,
  onRemove,
  overrides,
}: IUseTargetableProps): IUseTargetableReturn {
  const { Targetables } = useStore();
  const { t } = useTranslation();

  let targetable: Targetable;
  const targetableId = targeting.get('target_data');
  if (targetableId) {
    targetable = Targetables.get(targetableId);
  } else {
    targetable = Targetables.create({
      name: t('Target'),
      partner: campaign.get('parent_kind') === 'partner' ? campaign.get('parent') : undefined,
      ...overrides,
    });
    targeting.set({ target_data: targetable.get('id') });
    if (campaign.get('_cls') === CampaignClass.EmailListFacebook) {
      targeting.set({ integrations: [EIntegrationTypes.Facebook] });
      targetable.set({ integrations: [EIntegrationTypes.Facebook] });
    }
  }

  const handleRemoveTargeting = useCallback(() => onRemove(targeting), [onRemove, targeting]);

  return { targetable, onRemoveTargeting: handleRemoveTargeting };
}

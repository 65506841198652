import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { IMergeField, ITemplate, PinpointPartnerMessage } from '@feathr/blackbox';
import { TemplateClass } from '@feathr/blackbox';
import { Button, ButtonValid, Card, Fieldset, Form, Input, Toolbar } from '@feathr/components';
import MergetagSelect from '@feathr/extender/components/MergetagSelect';
import TemplateSelect from '@feathr/extender/components/TemplateSelect';
import TemplatesTable from '@feathr/extender/components/TemplatesTable';
import TemplateColumnName from '@feathr/extender/components/TemplatesTable/TemplateColumnName';
import TemplateColumnThumbnail from '@feathr/extender/components/TemplatesTable/TemplateColumnThumbnail';
import { useStore } from '@feathr/extender/state';
import { flattenErrors, useToggle } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import templateColumnMessageOptions from './templateColumnMessageOptions';

import * as styles from './EventPartnersMessageStepFive.css';

const description = (t: TFunction): JSX.Element => (
  <Trans t={t}>
    <p>
      To help with the deliverability and visibility of your emails to partners, here are some tips
      you can implement in your partner message template:
    </p>
    <ul className={styles.list}>
      <li>
        Avoid adding too many external links to your partner message template. Try to keep it to a
        maximum of two external links (the partner dashboard link should definitely be one of
        those).
      </li>
      <li>
        Avoid adding too many images to your partner message template, as some email providers may
        distort the layout of your email. Try to keep the design simple with a single header image.
      </li>
    </ul>
  </Trans>
);

interface IButtonProps {
  message: PinpointPartnerMessage;
  onNext: () => void;
}

interface IProps extends IButtonProps {
  disabled?: boolean;
  onPrevious: () => void;
}

export function validateStepFive(message: PinpointPartnerMessage): TValidateGrouped {
  return message.validate(['template_id', 'subject'], false, 'grouped').errors;
}

const NextStepButton = observer(({ message, onNext }: IButtonProps) => {
  const { t } = useTranslation();
  const errors = validateStepFive(message);
  return (
    <ButtonValid errors={flattenErrors(errors)} name={'next_step'} onClick={onNext}>
      {t('Next')}
    </ButtonValid>
  );
});

function EventPartnersMessageStepFive({
  disabled = false,
  message,
  onNext,
  onPrevious,
}: IProps): JSX.Element {
  const { t } = useTranslation();
  const { Templates } = useStore();
  const [isTemplateSelectVisible, toggleTemplateSelectVisible] = useToggle(false);
  const [newTemplate, setNewTemplate] = useState<ITemplate | undefined>();
  const [mergetagTemplate] = useState(
    Templates.create({ _cls: TemplateClass.PinpointEmail, account: message.get('account') }),
  );

  const handleCancel = useCallback(() => {
    setNewTemplate(undefined);
    toggleTemplateSelectVisible();
  }, [toggleTemplateSelectVisible]);

  const handleAdd = useCallback(async () => {
    // Button is disabled if newTemplate is not set.
    if (!newTemplate || !newTemplate.id) {
      return;
    }
    await message.patchDirty();
    const duplicatedTemplate = await Templates.clone(newTemplate.id, {
      campaign_id: message.id,
      event_id: message.get('event')!,
      _cls: TemplateClass.PinpointEmail,
    });
    message.addTemplate(duplicatedTemplate);
    handleCancel();
  }, [Templates, message, handleCancel, newTemplate]);

  const handleSelectOption = useCallback(
    (option: IMergeField) => {
      const subject = message.get('subject', '');
      message.set({ subject: subject.concat(option.value) });
    },
    [message],
  );

  return (
    <Form
      actions={[
        <Button key={'previous'} name={'previous_step'} onClick={onPrevious}>
          {t('Previous')}
        </Button>,
        <NextStepButton key={'next'} message={message} onNext={onNext} />,
      ]}
      description={description(t)}
      label={t('Edit Message: Message details')}
    >
      <Fieldset>
        <Input
          attribute={'subject'}
          disabled={disabled}
          label={t('Subject')}
          model={message}
          name={'email_subject'}
          type={'text'}
        />
        <MergetagSelect
          disabled={disabled}
          isPartnerMessageCampaign={true}
          name={'merge_tags'}
          onChange={handleSelectOption}
          template={mergetagTemplate}
          tooltip={
            <Trans t={t}>
              <p>
                You can use merge tags to personalize the subject line of your message. Choose the
                data you want to merge in from this dropdown to insert it at the end of your current
                subject line.
              </p>
            </Trans>
          }
        />
      </Fieldset>
      <Fieldset label={t('Template')}>
        <TemplatesTable
          columns={[
            TemplateColumnThumbnail,
            TemplateColumnName,
            templateColumnMessageOptions({
              t,
              editMetadata: false,
              editTemplate: false,
              removeTemplate: disabled,
            }),
          ]}
          isPaginated={false}
          model={message}
          noDataText={t('Choose a template to get started.')}
        />
        {!message.get('template_id') && (
          <>
            {!isTemplateSelectVisible ? (
              <Toolbar align={'left'}>
                <Button
                  key={'add'}
                  name={'add_template'}
                  onClick={toggleTemplateSelectVisible}
                  prefix={<FontAwesomeIcon icon={faPlus} />}
                  type={'primary'}
                >
                  {t('Add template')}
                </Button>
              </Toolbar>
            ) : (
              <Card>
                <TemplateSelect
                  onChange={setNewTemplate}
                  templateClass={TemplateClass.PinpointEmail}
                  value={newTemplate}
                />
                <Toolbar>
                  <Button key={'cancel'} name={'cancel'} onClick={handleCancel}>
                    {t('Cancel')}
                  </Button>
                  <Button
                    disabled={!newTemplate}
                    key={'select'}
                    name={'save_template'}
                    onClick={handleAdd}
                  >
                    {t('Add template')}
                  </Button>
                </Toolbar>
              </Card>
            )}
          </>
        )}
      </Fieldset>
    </Form>
  );
}

export default observer(EventPartnersMessageStepFive);

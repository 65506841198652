import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { IGracePeriod, IPeriod } from '@feathr/blackbox';
import { CardV2 as Card, Time, Value } from '@feathr/components';
import { TimeFormat } from '@feathr/hooks';

import { getMomentLicenseDate } from '../../Settings.utils';

import * as styles from './UpdatedGracePeriodCard.css';

interface IUpdatedLicenseDateProps {
  gracePeriod: IGracePeriod;
  gracePeriodKey: keyof IGracePeriod;
  period: IPeriod;
}

function UpdatedGracePeriodCard({
  gracePeriod,
  period,
  gracePeriodKey,
}: IUpdatedLicenseDateProps): JSX.Element | undefined {
  const { t } = useTranslation();
  if (gracePeriod[gracePeriodKey] === 0) {
    return;
  }

  return (
    <Card>
      <Card.Content>
        <Value
          className={styles.license}
          label={t('New {{gracePeriodKey}} date', { gracePeriodKey: gracePeriodKey })}
          value={
            <Time
              format={TimeFormat.pickerDateTime}
              timestamp={getMomentLicenseDate(period, gracePeriod, gracePeriodKey)}
            />
          }
        />
      </Card.Content>
    </Card>
  );
}

export default UpdatedGracePeriodCard;

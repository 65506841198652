import { computed, makeObservable, toJS } from 'mobx';

import { concatPath } from '@feathr/hooks';
import type { Attributes, TConstraints } from '@feathr/rachis';
import { Collection, isWretchError, wretch } from '@feathr/rachis';

import type { IEmailPreferences } from './accounts';
import type { IBillable } from './billables';
import type { CampaignClass, CampaignState } from './campaigns';
import type { EIntegrationTypes } from './integrations';
import type { IReportAttributes } from './model/report';
import { ReportModel } from './model/report';
import type { IRedirectDomain } from './redirect_domains';
import { RedirectDomain } from './redirect_domains';
import type { IStats, TAttributionModel } from './stats';

export enum EDepartment {
  Sales = 'D_002',
  Marketing = 'D_003',
  CustomerSuccess = 'D_005',
  GeneralAndAdministrative = 'D_006',
  Product = 'D_007',
  Support = 'D_009',
  BusinessDevelopment = 'D_010',
  Services = 'D_011',
  Enterprise = 'D_020',
  EnterpriseSales = 'D_021',
  EnterpriseCSM = 'D_023',
}

export interface IAddBillingAdjustmentArgs {
  amount: number;
  department: EDepartment;
  reason: string;
  sync_to_intacct: boolean;
}

export interface ICampaignSummary {
  id: string;
  _cls: CampaignClass;
  state: CampaignState;
}

export interface IEventBilling {
  current_bill_id: string;
  balance: number;
  billable?: IBillable;
  /** Use to set media billable config. */
  billable_id?: string;
  billed_spend: number;
  budget: number;
  has_billable_source: boolean;
  max_transfer: number;
  media_credit_balance: number;
  period: {
    end: string;
    start: string;
  };
  reference_id?: string;
  total_transactions: number;
}

export interface ILegSummary {
  name: string;
  campaigns: ICampaignSummary[];
}

export interface IFlightsSummary {
  name: string;
  legs: ILegSummary[];
}

export interface IPartnerDashboardConfig {
  banner_cta?: string;
  banner_description: string;
  banner_text: string;
  custom_logo: string;
  // FSUPPORT-2728. Delete this when informaUSA is using the new dashboard?
  hide_promote_yourself_text: boolean;
  info_description: string;
  info_text: string;
  invite_description: string;
  invite_text: string;
  lead_description: string;
  lead_text: string;
  logo_height: number;
  logo_padding_top: number;
  logo_width: number;
  monetization_description: string;
  monetization_text: string;
  new_dashboard: boolean;
  page_description: string;
  page_text: string;
  primary_color: string;
  button_color: string;
  button_text_color: string;
  show_banner_module: boolean;
  show_email_module: boolean;
  show_info_module: boolean;
  show_monetization_module: boolean;
  show_page_module: boolean;
  show_partner_leads: boolean;
  show_partner_stats: boolean;
  show_template_titles: boolean;
  welcome_text: string;
}

export interface IEvent extends IReportAttributes {
  attribution_model: TAttributionModel;
  /** @deprecated */
  aud_full?: string;
  /** @deprecated */
  aud_reg?: string;
  billing?: IEventBilling;
  date_created: string;
  date_end: string;
  date_start: string;
  /**
   * Toggles calculation between legacy bloomfilter and new live stats.
   * Defaults to false.
   */
  enable_live_reach: boolean;
  flights_summary: IFlightsSummary[];
  fpath: string;
  integrations: EIntegrationTypes[];
  logo?: string;
  mktg_page_rdr_domain: IRedirectDomain;
  name?: string;
  partner_dashboard_config: IPartnerDashboardConfig;
  ref_page_rdr_domain: IRedirectDomain;
  total_stats: IStats;
  tag_ids: string[];
  subadvertiser?: string;
  email_preferences?: IEmailPreferences;
}

export class Event extends ReportModel<IEvent> {
  public readonly className = 'Event';
  public override collection: Events<this> | null = null;

  public reportKey = 'e' as const;

  public marketingPageRedirectDomainModel: RedirectDomain | null = null;

  public refPageRedirectDomainModel: RedirectDomain | null = null;

  public get constraints(): TConstraints<IEvent> {
    return {
      name: {
        presence: {
          allowEmpty: false,
        },
      },
      site_url: {
        url: true,
      },
      registration_url: {
        url: true,
      },
    };
  }

  constructor(attributes: Partial<IEvent> = {}) {
    super(attributes);
    makeObservable(this);
  }

  public async addBillingAdjustment(data: IAddBillingAdjustmentArgs): Promise<Event> {
    this.assertCollection(this.collection);
    return this.collection.addBillingAdjustment(this.id, data);
  }

  public get mktg_page_rdr_domain(): RedirectDomain {
    if (!this.marketingPageRedirectDomainModel) {
      this.marketingPageRedirectDomainModel = new RedirectDomain(
        toJS(this.get('mktg_page_rdr_domain')),
      );
    }

    return this.marketingPageRedirectDomainModel;
  }

  public get ref_page_rdr_domain(): RedirectDomain {
    if (!this.refPageRedirectDomainModel) {
      this.refPageRedirectDomainModel = new RedirectDomain(toJS(this.get('ref_page_rdr_domain')));
    }

    return this.refPageRedirectDomainModel;
  }

  public getItemUrl(pathSuffix?: string): string {
    return concatPath(`/projects/${this.id}`, pathSuffix);
  }

  @computed
  public get name(): string {
    return this.get('name', '').trim() || 'Unnamed Project';
  }
}

export class Events<Model extends Event = Event> extends Collection<Model> {
  public getModel(attributes: Partial<Attributes<Model>>): Model {
    return new Event(attributes) as Model;
  }

  public getClassName(): string {
    return 'events';
  }

  public async addBillingAdjustment(id: string, data: IAddBillingAdjustmentArgs): Promise<Model> {
    const model = this.modelsById.get(id);
    if (!model) {
      throw new Error(`Model with id ${id} does not exist in collection`);
    }

    const body = data ? this.patchToString(data) : undefined;
    model.isUpdating = true;
    const response = await wretch<IEvent>(this.url('billing-adjustment', id), {
      body,
      method: 'POST',
      headers: this.getHeaders(),
    });
    if (isWretchError(response)) {
      this.processErrorResponse(model, response.error);
      return model;
    }
    return this.processJSONResponse(response);
  }

  public override url(variant: 'export' | 'billing-adjustment', value: string): string;
  public override url(variant: 'transfer-funds'): string;
  public override url(): string;
  public override url(
    variant?: 'billing-adjustment' | 'export' | 'transfer-funds',
    value?: string | string[],
  ): string {
    const root = super.url(variant, value);
    switch (variant) {
      case 'billing-adjustment':
        return `${root}${value}/billing-adjustment`;

      case 'export':
        return `${root}${value}/export`;

      case 'transfer-funds':
        return `${root}${value}/transfer-funds`;

      default:
        return super.url(variant, value);
    }
  }
}

import { Flex, Stack } from '@mantine/core';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast, ToastType } from 'react-toastify';

import type { Billable, Campaign, Event, IStripe, TSource } from '@feathr/blackbox';
import {
  AlertV2 as Alert,
  Button,
  CardV2 as Card,
  EAlertV2Type as EAlertType,
  EmptyState,
  Label,
  Well,
} from '@feathr/components';
import PaymentMethodForm from '@feathr/extender/App/Settings/Billing/BillablePage/PaymentMethodForm';
import BillingSource from '@feathr/extender/components/BillingSource';
import { useLocalUrl } from '@feathr/extender/state';
import { currencyFormatter } from '@feathr/extender/utils';
import { cssVar, errorMessage, useToggle } from '@feathr/hooks';

import * as styles from './PaymentDetails.css';

interface IProps {
  billable?: Billable;
  event: Event;
  campaign: Campaign;
}

function PaymentDetails({ billable, event, campaign }: Readonly<IProps>): JSX.Element {
  const localUrl = useLocalUrl();
  const [adding, toggleAdding] = useToggle(false);
  const { t } = useTranslation();

  const billing = event.get('billing');
  const balance = billing?.balance || 0;
  const budget = campaign.get('exposure_settings').target_value || 0;
  const spend = campaign.get('total_stats')?.spend || 0;

  function setSource(billable: Billable, source: TSource): void {
    const stripe = billable.get('stripe');
    billable.set({ stripe: { ...stripe, source, _token: source.id } });
  }

  return (
    <Card>
      <Card.Header title={'Payment Details'} />
      {!adding ? (
        <Card.Content addVerticalGap={true}>
          <Flex align={'center'} gap={cssVar('--spacing-4')} justify={'space-between'}>
            {!billable ? (
              <Alert
                className={styles.marginBuster}
                title={
                  <Trans t={t}>
                    You will not be able to publish this campaign until you&nbsp;
                    <Link target={'_blank'} to={localUrl('/settings/billing/configurations')}>
                      set up a billing configuration
                    </Link>
                    &nbsp;and&nbsp;
                    <Link
                      target={'_blank'}
                      to={localUrl(event.getItemUrl('/settings/billing/edit'))}
                    >
                      attach it to this project
                    </Link>
                    .
                  </Trans>
                }
                type={EAlertType.danger}
              />
            ) : !billable.isPending && billable.get('stripe', {} as IStripe).source ? (
              <div>
                <Label>{t('Payment method')}</Label>
                <BillingSource
                  billable={billable}
                  key={billable.get('stripe').source!.id}
                  source={billable.get('stripe').source!}
                />
              </div>
            ) : (
              budget - spend > balance && (
                <Stack>
                  <EmptyState label={t('Payment method has not been set')} theme={'slate'}>
                    <Button onClick={toggleAdding}>{t('Add payment method')}</Button>
                  </EmptyState>
                  <Alert
                    className={styles.marginBuster}
                    title={t(
                      'Changing the payment method above will affect the Billing Configuration for the whole Project.',
                    )}
                    type={EAlertType.info}
                  />
                </Stack>
              )
            )}
            {balance > 0 && (
              <Well className={styles.mediaCreditBalance} layout={'vertical'}>
                <Label
                  className={styles.marginBuster}
                  tooltip={t(
                    'This number represents the amount of money you have already funded on this project (in USD).',
                  )}
                >
                  {t('Media Credit Balance')}
                </Label>
                <span className={styles.bigNumber} data-name={'media_credit_balance'}>
                  {currencyFormatter.format(Math.max(balance, 0))}
                </span>
              </Well>
            )}
          </Flex>
        </Card.Content>
      ) : (
        !!billable &&
        !billable.isPending && (
          <PaymentMethodForm>
            {(onSave, element): JSX.Element => {
              async function handleSave(): Promise<void> {
                if (!billable || billable.isPending) {
                  return;
                }

                try {
                  const updatedSource = await onSave();
                  setSource(billable, updatedSource);
                  await billable.save();
                  toggleAdding();
                } catch (error) {
                  const message = errorMessage(
                    error,
                    t('An error occurred while trying to add a payment method.'),
                  );
                  toast(message, { type: ToastType.ERROR });
                }
              }

              return (
                <>
                  <Card.Content>{element}</Card.Content>
                  <Card.Actions>
                    <Button onClick={toggleAdding} type={'naked'}>
                      {t('Cancel')}
                    </Button>
                    <Button onClick={handleSave}>{t('Add')}</Button>
                  </Card.Actions>
                </>
              );
            }}
          </PaymentMethodForm>
        )
      )}
    </Card>
  );
}

export default PaymentDetails;

import type { TFunction } from 'i18next';

import type { Flavors } from '@feathr/blackbox';

export const breadcrumbFlavorDescriptionMap = (t: TFunction, flavor: keyof Flavors): string => {
  const map = {
    ad_click: t('clicked on an ad.'),
    ad_view: t('saw an ad.'),
    contact_list_import: t('imported a contact list.'),
    conversion: t('was marked as a conversion.'),
    custom: t('performed a custom activity.'),
    dashboard_create: t('created their dashboard.'),
    dashboard_import: t('uploaded a contact list.'),
    dashboard_send_complete: t('sent email invites.'),
    dashboard_send_request: t('scheduled an email invite.'),
    dashboard_submit: t('updated their profile.'),
    dashboard_view: t('viewed their dashboard.'),
    email_preferences_update: t('updated their email preferences.'),
    partner_message_link_click: t('clicked a link in a partner message email.'),
    partner_message_send: t('received a partner message email.'),
    partner_message_view: t('opened a partner message email.'),
    created_by_import: t('was created by an import:'),
    updated_by_import: t('was updated by import:'),
    page_link_click: t('clicked a link.'),
    page_view: t('viewed a page.'),
    report_view: t('viewed a campaign report.'),
    update: t('updated their profile.'),
    form_submission: t('submitted a Feathr form.'),
    form_view: t('viewed a Feathr form.'),
    convert: t('was marked as an advanced conversion.'),
    pinpoint_tracked_email_hardbounce: t('hard bounced an email.'),
    pinpoint_tracked_email_softbounce: t('soft bounced an email.'),
    pinpoint_tracked_email_suppression: t(
      'had an email cancelled to protect your sending reputation.',
    ),
    pinpoint_tracked_email_open: t('opened an email.'),
    pinpoint_tracked_email_click: t('clicked a link in an email.'),
    pinpoint_tracked_email_complaint: t('reported an email as spam.'),
    pinpoint_tracked_email_send: t('was sent an email.'),
    pinpoint_tracked_email_delivered: t('received an email.'),
    pinpoint_tracked_email_rendering_failure: t('had an email template rendering failure.'),
    raisers_edge_constituent_created: t("was created by Raiser's Edge NXT."),
    raisers_edge_constituent_updated: t("was updated by Raiser's Edge NXT."),
    raisers_edge_constituent_summary: t("had their gift summary updated by Raiser's Edge NXT."),
    raisers_edge_gift_added: t("had a gift added by Raiser's Edge NXT."),
    zapier_update: t('was updated by imported data from Zapier.'),
    zapier_import: t('was created by imported data from Zapier.'),
    user_create: t('was created by a member of your organization.'),
    user_update: t('was updated by a member of your organization.'),
    imis_create: t('was created by iMIS.'),
    imis_update: t('was updated by iMIS.'),
  };

  return map[flavor];
};

export const payloadLabelMap = (t: TFunction, flavor: keyof typeof Flavors): string => {
  const map = {
    imis_create: t('Data Imported'),
    zapier_import: t('Data Imported'),
    imis_update: t('Data Updated'),
    zapier_update: t('Data Updated'),
    created_by_import: t('Details'),
    updated_by_import: t('Details'),
  };

  return map[flavor];
};

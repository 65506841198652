import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Event } from '@feathr/blackbox';
import { Spinner, TimeRange } from '@feathr/components';
import BillTable from '@feathr/extender/components/BillTable';
import { useStore } from '@feathr/extender/state';
import { TimeFormat } from '@feathr/hooks';

import * as styles from './BillingPeriodTable.css';

interface IProps {
  event: Event;
}

function BillingPeriodTable({ event }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();

  const { Bills } = useStore();
  const billing = event.get('billing');
  const bill = billing?.current_bill_id ? Bills.get(billing.current_bill_id) : undefined;
  const showsMediaCredit = bill?.attributes.line_items?.some(
    (li) => li.description === 'Media Credit',
  );

  if (!billing || !bill) {
    return <Spinner />;
  }

  return (
    <>
      <div className={styles.header}>
        <strong>{t('Billing period:')}</strong>{' '}
        <TimeRange
          end={billing.period.end}
          format={TimeFormat.shortDate}
          start={billing.period.start}
        />
      </div>
      <BillTable bill={bill} />
      {showsMediaCredit && (
        <em>
          {t(
            'The Media Credit line item shown above will be withdrawn from your pre-paid funds on the fifth of the month following the billing period.',
          )}
        </em>
      )}
    </>
  );
}

export default observer(BillingPeriodTable);

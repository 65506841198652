import { faPencil, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type {
  FacebookCampaign,
  FacebookDisplayCreative,
  ICallToActionType,
  IFacebookPage,
} from '@feathr/blackbox';
import { CallToActionTypes, FacebookVideoCreative } from '@feathr/blackbox';
import {
  Alert,
  AlertType,
  Button,
  Card,
  Chip,
  Form,
  Input,
  ModalV1,
  Select,
  Textarea,
  toast,
  Tooltip,
} from '@feathr/components';
import CreativePreview from '@feathr/extender/components/Creative/CreativePreview';
import CreativeMetadata from '@feathr/extender/components/CreativeMetadata';
import CreativeThumbnail from '@feathr/extender/components/CreativeThumbnail';
import { useLocalUrl } from '@feathr/extender/state';
import { moment, useRedirect, useToggle } from '@feathr/hooks';

import FacebookVideoThumbnail from './FacebookVideoThumbnail';

import * as styles from '@feathr/extender/components/Creative/Creative.css';

const statusColorMap = new Map<string, string>([
  ['ARCHIVED', 'violet'],
  ['PAUSED', 'yellow'],
  ['IN_PROCESS', 'yellow'],
  ['PENDING_REVIEW', 'yellow'],
  ['ACTIVE', 'green'],
  ['WITH_ISSUES', 'orange'],
  ['DISAPPROVED', 'red'],
]);

const statusLabelMap = new Map<string, string>([
  ['PENDING_REVIEW', 'Pending review'],
  ['IN_PROCESS', 'Pending review'],
  ['ACTIVE', 'Approved'],
  ['DISAPPROVED', 'Rejected'],
  ['WITH_ISSUES', 'Has issues'],
  ['ARCHIVED', 'Archived'],
  ['PAUSED', 'Paused'],
]);

interface IProps {
  campaign: FacebookCampaign;
  creative: FacebookDisplayCreative;
  creatives: IObservableArray<FacebookDisplayCreative>;
  onRemove: (creative: FacebookDisplayCreative) => void;
  page?: IFacebookPage;
}

function FacebookCreative({ campaign, creative, onRemove, page }: IProps): JSX.Element {
  const [reviewRequested, setReviewRequested] = useState(creative.get('has_requested_review'));
  const [showPreviewModal, togglePreviewModal] = useToggle(false);
  const localUrl = useLocalUrl();
  const [, setRedirect] = useRedirect();
  const { t } = useTranslation();

  const creativeSpec = creative.getSpec();
  const hasDestinationURL =
    campaign.isValid(['destination_url'], false) ||
    (creative.get('destination_url') && creative.isValid(['destination_url'], false));
  const hasFacebookPage = campaign.isValid(['facebook_page_id'], false);
  const status = creative.get('ad_status') || 'PENDING_REVIEW';
  const adReviewFeedback = creative.get('ad_review_feedback', {});
  // The only pertinent information is in the first element
  const adReviewText = adReviewFeedback.facebook
    ? Object.values(adReviewFeedback.facebook)[0]
    : adReviewFeedback.feedback
      ? Object.values(adReviewFeedback.feedback)[0]
      : null;
  const previewButton: JSX.Element = (
    <Button
      disabled={!(hasDestinationURL || hasFacebookPage)}
      name={'preview_creative'}
      onClick={togglePreviewModal}
    >
      {t('Preview')}
    </Button>
  );
  const campaignIsComplete = moment.utc(campaign.get('date_end')).isBefore(moment.utc());

  async function handleRequestedReview(): Promise<void> {
    try {
      await creative.submitReviewRequest();
      setReviewRequested(true);
      toast(t('Your ad has been successfully submitted for review.'), {
        type: ToastType.SUCCESS,
      });
    } catch {
      toast(t('There was a problem submitting your ad for review. Please refresh and try again.'), {
        type: ToastType.ERROR,
      });
    }
  }

  function handleCallToAction({ id }: ICallToActionType): void {
    creative.set({ call_to_action_type: id });
  }

  function handleRemove(): void {
    onRemove(creative);
  }

  function getAdReviewText(): ReactNode {
    if (!adReviewText) {
      return <Trans t={t}>Feedback currently unavailable. Please contact support.</Trans>;
    } else {
      if (
        // Facebook rejects ads that mention politicians or are about sensitive social issues
        adReviewText.includes(
          'mentions politicians or is about sensitive social issues that could influence public opinion',
        )
      ) {
        return (
          <Trans t={t}>
            This ad was rejected because it mentions politicians, is about sensitive social issues
            or is meant to influence an election or legislative ruling. If you believe that Meta has
            incorrectly flagged and rejected this creative, please{' '}
            <a href={'https://help.feathr.co/hc/en-us/articles/12396998196119'} target={'_blank'}>
              follow these guidelines to review and update your ad{' '}
            </a>{' '}
            and submit it for a new review.
          </Trans>
        );
      } else {
        return t('{{adReviewText}}', { adReviewText });
      }
    }
  }

  return (
    <>
      <Card
        actions={[
          <Tooltip key={'edit'} title={t('Edit')}>
            <Button
              link={setRedirect(localUrl(creative.getItemUrl('edit/design')), 'step4')}
              type={'naked'}
            >
              <FontAwesomeIcon icon={faPencil} />
            </Button>
          </Tooltip>,
          <Tooltip key={'remove'} title={t('Remove')}>
            <Button name={'remove_creative'} onClick={handleRemove} type={'naked'}>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Tooltip>,
        ]}
        className={styles.creativeCard}
      >
        <div className={styles.creativeInfo}>
          <div className={styles.thumbnailPreview}>
            {creative instanceof FacebookVideoCreative ? (
              <FacebookVideoThumbnail creative={creative} previewButton={previewButton} />
            ) : (
              <CreativeThumbnail
                creative={creative.toJS()}
                previewButton={previewButton}
                size={200}
              />
            )}
            <div className={styles.submitReview}>
              {!campaignIsComplete && ['DISAPPROVED', 'WITH_ISSUES'].includes(status) && (
                <Tooltip
                  title={
                    reviewRequested
                      ? t('Your ad has been submitted for review.')
                      : t('Request manual re-review from Meta.')
                  }
                >
                  <Button
                    disabled={reviewRequested}
                    name={'submit_review'}
                    onClick={handleRequestedReview}
                  >
                    {t('Submit for review')}
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
          <dl>
            <dt>{t('Dimensions')}</dt>
            <dd>
              {creative.get('width')}px x {creative.get('height')}px
            </dd>
            <dt>{t('Spec')}</dt>
            <dd>{!!creativeSpec && creativeSpec.name}</dd>
            <dt>{t('Approval status')}</dt>
            <dd>
              <Chip isLoading={creative.isPending} theme={statusColorMap.get(status)}>
                {statusLabelMap.get(status)}
              </Chip>
              {['DISAPPROVED', 'WITH_ISSUES'].includes(status) && (
                <Alert className={styles.alert} type={AlertType.danger}>
                  {getAdReviewText()}
                </Alert>
              )}
            </dd>
          </dl>
        </div>
        <CreativeMetadata
          creative={creative}
          destinationUrlPlaceholder={campaign?.get('destination_url')}
          showStatus={true}
        />
        <br />
        <Form label={t('Edit Creative')}>
          <Textarea
            attribute={'message'}
            helpText={t(
              '1000 character limit. Meta recommends 125 characters or less. Longer text may be truncated.',
            )}
            label={t('Primary text')}
            maxLength={1000}
            model={creative}
            name={'primary_text'}
            placeholder={t('Primary text in the ad post')}
            required={false}
          />
          <Input
            attribute={'caption'}
            helpText={t(
              'The display link should go to the same domain as your destination URL. Must be 25 characters or less.',
            )}
            label={t('Display link')}
            maxLength={25}
            model={creative}
            name={'display_link'}
            placeholder={t('Show a shortened link instead of your full destination URL')}
            required={false}
            type={'url'}
          />
          <Input
            attribute={'description'}
            helpText={t('Meta recommends 27 characters or less.')}
            label={t('Description')}
            maxLength={27}
            model={creative}
            name={'description'}
            placeholder={t('Description of the link in the post')}
            required={false}
            type={'text'}
          />
          <Select
            helpText={t(
              'Show a button or link on your ad that represents the action you want people to take.',
            )}
            label={t('Call to action')}
            name={'call_to_action'}
            onSelectSingle={handleCallToAction}
            options={CallToActionTypes}
            value={CallToActionTypes.find(
              (option) => option.id === creative.get('call_to_action_type'),
            )}
          />
        </Form>
      </Card>
      {showPreviewModal && (
        <ModalV1
          controlled={true}
          onClose={togglePreviewModal}
          size={'xl'}
          t={t}
          title={t('Creative Preview')}
        >
          <CreativePreview campaign={campaign} creative={creative} page={page} />
        </ModalV1>
      )}
    </>
  );
}

export default observer(FacebookCreative);

import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { Campaign } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import {
  AlertV2 as Alert,
  ButtonValid,
  CardV2 as Card,
  EAlertV2Type as EAlertType,
  Form,
  Input,
  Select,
} from '@feathr/components';
import { useAccount, useLocalUrl, useUser } from '@feathr/extender/state';

import * as styles from '../AdWizard/AdWizard.css';

interface IProps {
  onNext: () => void;
  campaign: Campaign;
}

export function validateStepGeneral(campaign: Campaign): string[] {
  return campaign.validate(['name'], false).errors;
}

const NextStepButton = observer(({ campaign, onNext }: IProps) => {
  const validationErrors = validateStepGeneral(campaign);
  return (
    <ButtonValid errors={validationErrors} name={'next_step'} onClick={onNext}>
      Next
    </ButtonValid>
  );
});

function WizardGeneralStep({ campaign, onNext }: Readonly<IProps>): JSX.Element {
  const account = useAccount();
  const user = useUser();
  const localUrl = useLocalUrl();
  const { t } = useTranslation();
  const { isDraft, isMonetization, isTTDCampaign } = campaign;

  const advOptions = (account.get('ttd')?.subadvertisers || []).map((advertiser) => ({
    id: advertiser.subadv_id,
    name: advertiser.name || '',
  }));

  const showAdvertiserSelect =
    !isMonetization && isTTDCampaign && (account.get('is_agency') || user.isSudoer);

  function handleSelectAdvertiser(option: ISelectOption): void {
    campaign.set({ subadvertiser: option.id });
  }

  return (
    <Form
      actions={
        // TODO: Remove this when we horizontal wizard the campaign types that also utilize this step
        !isTTDCampaign && [<NextStepButton campaign={campaign} key={'next'} onNext={onNext} />]
      }
      className={classNames({ [styles.formRoot]: isTTDCampaign })}
      label={'Edit Campaign: Name'}
    >
      {showAdvertiserSelect && (
        <Card>
          <Card.Header
            description={
              <Trans t={t}>
                Add organizations to this list on the{' '}
                <Link to={localUrl('settings/account/advertisers')}>advertisers page</Link> in your
                account settings.{' '}
                <a
                  href={'https://help.feathr.co/hc/en-us/articles/4410253428375'}
                  target={'_blank'}
                >
                  Learn about advertisers
                </a>
              </Trans>
            }
            title={t('Advertiser')}
          />
          <Card.Content>
            <Alert
              description={t('This setting cannot be changed after the campaign is published.')}
              title={t(
                'If this campaign is to advertise on behalf of another organization, please choose that organization here.',
              )}
              type={EAlertType.warning}
            />

            <Select
              disabled={!isDraft}
              label={'Advertiser'}
              name={'advertiser'}
              onSelectSingle={handleSelectAdvertiser}
              options={advOptions}
              value={advOptions.find(
                (advertiser: ISelectOption) => advertiser.id === campaign.get('subadvertiser'),
              )}
            />
          </Card.Content>
        </Card>
      )}

      <Card>
        <Card.Header
          description={t('Provide a descriptive name for your campaign so you can find it later.')}
          title={t('Name')}
        />
        <Card.Content>
          <Input
            attribute={'name'}
            label={'Campaign name'}
            model={campaign}
            required={true}
            type={'text'}
          />
        </Card.Content>
      </Card>
    </Form>
  );
}

export default observer(WizardGeneralStep);

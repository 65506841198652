import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';

import type { Goal as GoalModel } from '@feathr/blackbox';
import { Select } from '@feathr/components';
import { useStore } from '@feathr/extender/state';

import * as styles from '@feathr/extender/App/EventsPage/CampaignEditPage/AdWizard/AdWizardTargetsStep/SegmentTargeting/SegmentTargeting.css';

interface IProps {
  goal: GoalModel;
}

interface IMenuOption {
  description: string;
  id: string;
  name: string;
}

type TOptionProps = OptionProps<IMenuOption>;

interface IDataProps extends TOptionProps {
  data: IMenuOption;
}

function PresetOption(props: TOptionProps) {
  const { data } = props as IDataProps;
  return (
    <components.Option {...props}>
      <div>
        <span>{data.name}</span>
        <div className={styles.description}>{data.description}</div>
      </div>
    </components.Option>
  );
}

function GoalSegmentPresetSelect({ goal }: IProps) {
  const { Segments } = useStore();
  const { t } = useTranslation();

  const presetOptions = [
    {
      name: t('Reach a specific URL'),
      id: 'page_view',
      description: t(
        'If your goal can be tracked based on the URL a user visits, this is the preset for you. For example, the goal might be to reach a "Thank You" or confirmation page after completing a registration.',
      ),
    },
    {
      name: t('Submit a Form'),
      id: 'form_submission',
      description: t(
        'If your goal can be tracked based on submitting a form from a Feathr Landing Page or you have a custom pixel implementation for tracking a form submission, choose this preset.',
      ),
    },
  ];

  const segmentId = goal.get('segment');
  const segment = segmentId ? Segments.get(segmentId) : undefined;

  function onSelectSinglePreset(preset: IMenuOption) {
    switch (preset.id) {
      case 'page_view':
        segment!.set({
          mode: 'match_all',
          lookback_mode: 'unbounded',
          lookback_value: undefined,
          predicates: [
            {
              group: [
                {
                  kind: 'activity',
                  attr_against: 'loc_url',
                  attr_type: 'string',
                  comparison: 'eq',
                  value: '',
                },
                {
                  kind: 'activity',
                  attr_against: 'flvr',
                  attr_type: 'string',
                  comparison: 'eq',
                  value: 'page_view',
                },
              ],
            },
          ],
        });
        goal.set({ conv_type: 'page_view' });
        break;

      case 'form_submission':
        segment!.set({
          mode: 'match_all',
          lookback_mode: 'unbounded',
          lookback_value: undefined,
          predicates: [
            {
              group: [
                {
                  kind: 'activity',
                  attr_against: 'loc_url',
                  attr_type: 'string',
                  comparison: 'eq',
                  value: '',
                },
                {
                  kind: 'activity',
                  attr_against: 'flvr',
                  attr_type: 'string',
                  comparison: 'eq',
                  value: 'form_submission',
                },
              ],
            },
          ],
        });
        goal.set({ conv_type: 'form_submission' });
        break;

      default:
        goal.set({ conv_type: 'custom' });
    }
  }

  return (
    <Select
      components={{ Option: PresetOption }}
      label={t('Goal Group Preset')}
      name={'goal-segment-preset'}
      onSelectSingle={onSelectSinglePreset}
      optional={true}
      options={presetOptions}
      required={false}
      value={presetOptions.find((option) => option.id === goal.get('conv_type'))}
    />
  );
}

export default observer(GoalSegmentPresetSelect);

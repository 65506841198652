import classNames from 'classnames';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type { BannersnackCreative, Creative, DisplayCreative } from '@feathr/blackbox';
import { CreativeClass } from '@feathr/blackbox';
import {
  Button,
  Card,
  ContextMenu,
  Form,
  SaveButtonValid,
  Tab,
  Tabs,
  Toolbar,
} from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import BannersnackMetadata from '@feathr/extender/components/BannersnackMetadata';
import CreativeMetadata from '@feathr/extender/components/CreativeMetadata';
import CreativeThumbnail from '@feathr/extender/components/CreativeThumbnail';
import { useLocalUrl, useStore } from '@feathr/extender/state';
import { useRedirect } from '@feathr/hooks';

import DownloadBannersnackFileMenuItem from '../../DownloadBannersnackFileMenuItem';
import CreativeEditor from './CreativeEditor';

import * as styles from './EventCreativeEditPage.css';

interface IDownloadMenuProps {
  creative: BannersnackCreative;
}

function DownloadMenu({ creative }: IDownloadMenuProps): JSX.Element {
  const animated = CreativeClass.DisplayBannersnack ? creative.get('animated') : false;

  return (
    <ContextMenu iconName={'ellipsis'}>
      <DownloadBannersnackFileMenuItem creative={creative} type={'jpg'} />
      <DownloadBannersnackFileMenuItem creative={creative} type={'png'} />
      <DownloadBannersnackFileMenuItem creative={creative} type={'pdf'} />
      {animated && (
        <>
          <DownloadBannersnackFileMenuItem creative={creative} type={'animated-gif'} />
          <DownloadBannersnackFileMenuItem creative={creative} type={'mp4'} />
        </>
      )}
    </ContextMenu>
  );
}

function EventCreativeEditPage(): JSX.Element {
  const { t } = useTranslation();
  const { Creatives } = useStore();
  const localUrl = useLocalUrl();
  const [redirect, setRedirect, hasRedirect] = useRedirect();
  const { creativeId, eventId, tab } = useParams<{
    creativeId: string;
    eventId: string;
    tab: string;
  }>();

  const creative = Creatives.get(creativeId) as DisplayCreative;
  const type = creative.get('_cls');
  const showBackButton = hasRedirect;

  const orientation: 'horizontal' | 'vertical' =
    creative.get('width') <= creative.get('height') ? 'vertical' : 'horizontal';

  function handleSave(): void {
    redirect(localUrl(`/projects/${eventId}/creatives`));
  }

  const backButton = <Button onClick={handleSave}>{t('Return to campaign')}</Button>;
  const actions =
    showBackButton || type === CreativeClass.DisplayBannersnack ? (
      <Toolbar>
        {showBackButton && backButton}
        {type === CreativeClass.DisplayBannersnack && (
          <DownloadMenu creative={creative as BannersnackCreative} />
        )}
      </Toolbar>
    ) : undefined;

  const validationFields: string[] = ['alt_text', 'destination_url', 'name'];
  if (type === CreativeClass.DisplayAdTag) {
    validationFields.push('adtag', 'spec');
  }
  if (type === CreativeClass.DisplayBannersnack) {
    validationFields.push('banner_hash');
  }

  const validationErrors = creative.validate(validationFields, false);

  return (
    <Page
      actions={actions}
      loading={creative.isPending}
      title={t('Edit {{name}}', { name: creative.name })}
    >
      {type !== CreativeClass.DisplayBannersnack && (
        <CreativeThumbnail
          className={classNames(styles[orientation as keyof typeof styles])}
          creative={creative.toJS()}
          height={orientation === 'horizontal' ? 400 : undefined}
          width={orientation === 'vertical' ? 200 : 'auto'}
        />
      )}
      {type !== CreativeClass.DisplayBannersnack && (
        <Card>
          <Form className={styles.root} label={t('Edit metadata')}>
            <CreativeMetadata
              creative={creative}
              showInlineEditor={true}
              showName={false}
              showReplaceButton={true}
            />
          </Form>
        </Card>
      )}
      {type === CreativeClass.DisplayBannersnack && (
        <>
          <Tabs>
            <Tab
              key={'design'}
              // Keep including redirect in url, but only if it's already set.
              link={hasRedirect ? setRedirect('design', undefined, true) : 'design'}
              title={t('Design')}
            />
            <Tab
              key={'metadata'}
              // Keep including redirect in url, but only if it's already set.
              link={hasRedirect ? setRedirect('metadata', undefined, true) : 'metadata'}
              title={t('Metadata')}
            />
          </Tabs>
          <section className={styles.content}>
            {!tab || (tab === 'design' && <CreativeEditor creative={creative} />)}
            {tab === 'metadata' && <BannersnackMetadata creative={creative} />}
          </section>
        </>
      )}
      <Toolbar align={'left'} className={styles.toolbar}>
        {type !== CreativeClass.DisplayBannersnack && (
          <SaveButtonValid<Creative>
            errors={toJS(validationErrors.errors)}
            method={'patch'}
            model={creative}
            onSave={handleSave}
            type={'success'}
          />
        )}
        {showBackButton && backButton}
      </Toolbar>
    </Page>
  );
}

export default observer(EventCreativeEditPage);

import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { Billable, IStripe, TSource } from '@feathr/blackbox';
import { Button, CardV2 as Card, toast } from '@feathr/components';
import BillingSource from '@feathr/extender/components/BillingSource';
import { errorMessage } from '@feathr/hooks';

import PaymentMethodForm from '../../PaymentMethodForm';

import * as styles from './PaymentMethodCard.css';

interface IProps {
  billable: Billable;
}

function PaymentMethodCard({ billable }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();

  const { source } = billable.get('stripe', {} as IStripe);

  function setSource(newSource: TSource): void {
    const stripe = billable.get('stripe');
    billable.set({ stripe: { ...stripe, source: newSource, _token: newSource.id } });
  }

  function unsetSource(): void {
    const stripe = billable.get('stripe');
    billable.set({ stripe: { ...stripe, source: undefined, _token: null } });
  }

  return (
    <Card>
      <Card.Header title={t('Payment method')} />

      {source ? (
        <Card.Content>
          <BillingSource
            billable={billable}
            deleteIcon={faArrowsRotate}
            deleteLabel={t('Replace')}
            key={source.id}
            onDelete={unsetSource}
            source={source}
          />
        </Card.Content>
      ) : (
        <PaymentMethodForm>
          {(onSave, element): JSX.Element => {
            async function handleSave(): Promise<void> {
              try {
                const updatedSource = await onSave();
                setSource(updatedSource);
                toast(t('Payment method added'), { type: ToastType.SUCCESS });
              } catch (error) {
                toast(
                  errorMessage(error, t('An error occurred while trying to add a payment method.')),
                  { type: ToastType.ERROR },
                );
              }
            }

            return (
              <>
                <Card.Content contentClassName={styles.content}>{element}</Card.Content>
                <Card.Actions>
                  <Button onClick={handleSave}>{t('Confirm')}</Button>
                </Card.Actions>
              </>
            );
          }}
        </PaymentMethodForm>
      )}
    </Card>
  );
}

export default observer(PaymentMethodCard);

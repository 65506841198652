import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { Form, IRedirectDomain } from '@feathr/blackbox';
import { Button, Icon, Modal, toast } from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { errorMessage } from '@feathr/hooks';

import type { IOption } from './domains.utils';
import { getDomainComponent } from './domains.utils';

import * as styles from './FormPublishModal.css';

interface IProps {
  form: Form;
  isOpen: boolean;
  toggle: () => void;
}

function FormPublishModal({ isOpen, form, toggle }: Readonly<IProps>): JSX.Element {
  const { Domains } = useStore();
  const { t } = useTranslation();
  const [selectedDomain, setSelectedDomain] = useState<IRedirectDomain['id'] | undefined>(
    form.get('redirect_domain')?.id || undefined,
  );

  const domains = Domains.list();

  function handleSelectDomain({ value }: IOption): void {
    setSelectedDomain(value);
  }

  function handleCancel(): void {
    setSelectedDomain(undefined);
    toggle();
  }

  async function handlePublish(): Promise<void> {
    if (!selectedDomain) {
      return;
    }

    try {
      if (form.isDirty) {
        await form.patchDirty();
      }
      await form.publish(selectedDomain);
      toast(t('Form published successfully'), { type: ToastType.SUCCESS });
      toggle();
    } catch (error) {
      toast(t('Failed to publish form: \n{{- error}}', { error: errorMessage(error, t) }), {
        type: ToastType.ERROR,
      });
    }
  }

  const actions = [
    <Button key={'cancel'} onClick={handleCancel}>
      {t('Cancel')}
    </Button>,
    <Button
      disabled={!selectedDomain}
      key={'publish'}
      onClick={handlePublish}
      prefix={<Icon icon={faCheck} />}
      type={'success'}
    >
      {t('Publish')}
    </Button>,
  ];

  return (
    <Modal
      allowOverflow={true}
      bodyClassName={styles.root}
      description={t(
        "Forms can only be embedded on pages from domains that you've authorized Feathr to serve content from.",
      )}
      onClose={toggle}
      opened={isOpen}
      rightActions={actions}
      title={t('Publish your form')}
    >
      {getDomainComponent({ domains, selectedDomain, setSelectedDomain: handleSelectDomain, t })}
    </Modal>
  );
}

export default observer(FormPublishModal);

import debounce from 'debounce-promise';
import { autorun, toJS, when } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { CustomField } from '@feathr/blackbox';
import type { FieldCollection, FieldDataType } from '@feathr/blackbox';
import { Fieldset, Form, Input } from '@feathr/components';
import DataRequestPersonAttributes from '@feathr/extender/components/CustomFieldSelect/DataRequestPersonAttributes';
import DataTypeSelect from '@feathr/extender/components/DataTypeSelect';
import { useStore } from '@feathr/extender/state';
import { DEFAULT_DEBOUNCE_WAIT, flattenErrors } from '@feathr/hooks';

import CustomFieldsCollectionSelect from './CustomFieldsCollectionSelect';
import NextStepButton from './NextStepButton';

interface IProps {
  field: CustomField;
  onNext: () => void;
}

function DataFieldEditStepOne({ field, onNext }: IProps) {
  const { CustomFields } = useStore();
  const { t } = useTranslation();
  const errorStore = useLocalObservable(
    () =>
      ({
        u_key: [],
      }) as Record<string, string[]>,
  );
  const isReadOnly = field.get('is_read_only');

  function handleChangeCollection(value: FieldCollection) {
    field.set({ collection: value });
  }

  function handleChangeDataType(newValue?: FieldDataType) {
    field.set({ data_type: newValue });
  }

  const normalizeString = (str: string) => str.toLowerCase();

  const getDuplicateFields = useCallback(
    debounce(async () => {
      const normalizedKey = normalizeString(field.get('u_key', ''));
      const fields = CustomFields.list();

      await when(() => !fields.isPending);

      const duplicateCustomField = fields.models.find((model) => {
        const modelKey = normalizeString(model.get('u_key', ''));
        return model.get('collection') !== 'Partner' && modelKey === normalizedKey;
      });

      const isDuplicateInPersonAttributes = DataRequestPersonAttributes.some((attribute) => {
        const attributeKey = normalizeString(attribute.u_key);
        return attributeKey === normalizedKey;
      });

      if (duplicateCustomField && !errorStore.u_key.length) {
        const collection = duplicateCustomField.get('collection');
        const errorMessage =
          collection === 'Breadcrumb'
            ? t('You already have an Activity custom field with this name.')
            : t('You already have a Person custom field with this name.');
        errorStore.u_key = [errorMessage];
      } else if (isDuplicateInPersonAttributes && !errorStore.u_key.length) {
        errorStore.u_key = [t('This name is already in use as default Person Attribute field.')];
      } else if (
        !duplicateCustomField &&
        !isDuplicateInPersonAttributes &&
        errorStore.u_key.length
      ) {
        errorStore.u_key = [];
      }
    }, DEFAULT_DEBOUNCE_WAIT),
    [field],
  );

  useEffect(() => autorun(getDuplicateFields));

  return (
    <Form
      actions={[
        <NextStepButton errors={toJS(errorStore)} field={field} key={'nextStep'} onNext={onNext} />,
      ]}
      label={field.isEphemeral ? t('Create Custom Field: Info') : t('Edit Custom Field: Info')}
    >
      <Fieldset>
        <CustomFieldsCollectionSelect
          disabled={!field.isEphemeral || isReadOnly}
          helpPlacement={'bottom'}
          helpText={t('The type of data this field can be attached to.')}
          label={t('Collection')}
          onChange={handleChangeCollection}
          value={field.get('collection')}
        />
        <Input
          attribute={'u_key'}
          disabled={isReadOnly}
          label={t('Name')}
          model={field}
          type={'text'}
          validationError={flattenErrors(errorStore)}
        />
        <DataTypeSelect
          disabled={!field.isEphemeral || isReadOnly}
          label={t('Data type')}
          onChange={handleChangeDataType}
          value={field.get('data_type')}
        />
        <Input
          attribute={'description'}
          disabled={isReadOnly}
          label={t('Description')}
          model={field}
          optional={true}
          type={'text'}
        />
      </Fieldset>
    </Form>
  );
}

export default observer(DataFieldEditStepOne);

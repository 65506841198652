import { computed, makeObservable } from 'mobx';

import { concatPath } from '@feathr/hooks';
import type { IBaseAttributes, IRachisMessage, TConstraints } from '@feathr/rachis';
import { Collection, DisplayModel, isWretchError, wretch } from '@feathr/rachis';

import type { FieldDataType } from './custom_fields';
import type { IRedirectDomain } from './redirect_domains';

interface IFormStats {
  num_crumbs: number;
  num_views: number;
  num_submissions: number;
  submission_rate: number;
}

export enum EFormState {
  Draft = 'draft',
  Published = 'published',
  Archived = 'archived',
}

export interface IRowItem {
  /** The u_key of the related custom field */
  readonly id: string;
  /** Read only name of the field */
  readonly name: string;
  readonly type: FieldDataType;
  help_text?: string;
  /** User configurable name of the field */
  label: string;
  placeholder?: string;
  required?: boolean;
}

export interface IListRowItem extends IRowItem {
  options: string[];
  type: FieldDataType.list;
}

export interface IFormConfig {
  settings: {
    submit: { label: string; color: string };
  };
  rows: Array<{ fields: IRowItem[] }>;
}

export type JSONString<T> = string & { __type__: T };

export interface IForm extends IBaseAttributes {
  account: string;
  name: string;
  state: EFormState;
  /** Form JSON */
  content_json: JSONString<IFormConfig>;
  /** Project ID */
  event: string;
  /** Thank you message JSON */
  post_submit_html: string;
  /** Content serving domain */
  redirect_domain: IRedirectDomain;
  stats: IFormStats;
  version: number;
  date_last_modified: string;
  date_last_published: string;
  last_published_version: number;
}

export class Form extends DisplayModel<IForm> {
  public readonly className = 'Form';

  public get constraints(): TConstraints<IForm> {
    return {
      name: {
        presence: {
          allowEmpty: false,
        },
      },
      // TODO: Implement validation on individual form fields in https://github.com/Feathr/shrike/issues/4503.
      /* 
      content_json: (): IConstraint | undefined => {
        const value = this.get('content_json');
        // Check for validity of JSON
        try {
          JSON.parse(value);
        } catch (e) {
          return { message: '^Form content is not valid JSON' };
        }

        const content: IFormConfig = JSON.parse(value);
        if (content.rows.length === 0) {
          return {
            presence: { allowEmpty: false, message: '^Form must have at least one field' },
          };
        }

        for (const row of content.rows) {
          for (const field of row.fields) {
            if (field.label === '' || field.label === undefined) {
              return {
                foo: { message: '^Form field labels cannot be empty' },
              };
            }

            function isListField(rowItem: IRowItem): rowItem is IListRowItem {
              return rowItem.type === FieldDataType.list;
            }

            if (isListField(field) && field.options.length === 0) {
              return {
                foo: {
                  message: '^Form must have at least one option for each list field',
                },
              };
            }
          }
        }

        return undefined;
      },
      */
    };
  }

  public override getDefaults(): Partial<IForm> {
    return {
      content_json:
        '{ "settings": {"submitLabel": "Submit" }, "rows": [] }' as JSONString<IFormConfig>,
      post_submit_html: '<p>Thank you for your submission!</p>',
    };
  }

  constructor(attributes: Partial<IForm> = {}) {
    super(attributes);

    makeObservable(this);
  }

  public getItemUrl(pathSuffix?: string): string {
    return concatPath(`/projects/${this.get('event')}/content/forms/${this.id}`, pathSuffix);
  }

  @computed
  public get name(): string {
    return this.get('name', '').trim() || 'Unnamed Form';
  }

  /**
   * Returns the content_json as an IFormConfig object for the Form Editor.
   */
  @computed
  public get formConfig(): IFormConfig {
    const content = this.get('content_json');
    return JSON.parse(content);
  }

  @computed
  public get usedFields(): IRowItem[] {
    return this.formConfig.rows.flatMap((row) => row.fields);
  }

  public setConfig(config: IFormConfig): void {
    this.set({ content_json: JSON.stringify(config) as JSONString<IFormConfig> });
  }

  public async publish(redirectDomainId: IRedirectDomain['id']): Promise<IForm> {
    this.assertCollection(this.collection);

    const url = `${this.collection.url()}${this.id}/publish`;
    const response = await wretch<IForm>(url, {
      method: 'POST',
      headers: this.collection.getHeaders(),
      body: JSON.stringify({ redirect_domain_id: redirectDomainId }),
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }

  public async getShortCode(redirectDomainId: IRedirectDomain['id']): Promise<string> {
    this.assertCollection(this.collection);

    const url = `${this.collection.url()}${this.id}/publish/short_code?redirect_domain_id=${redirectDomainId}`;
    const response = await wretch<{ short_code: string }>(url, {
      method: 'GET',
      headers: this.collection.getHeaders(),
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data.short_code;
  }

  public async upsync(): Promise<IRachisMessage> {
    this.assertCollection(this.collection);

    await this.patchDirty();

    const url = `${this.collection.url()}${this.id}/upsync`;
    const response = await wretch<IRachisMessage>(url, {
      method: 'POST',
      headers: this.collection.getHeaders(),
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }
}

export class Forms extends Collection<Form> {
  public getClassName(): string {
    return 'forms';
  }

  public getModel(attributes: Partial<IForm>): Form {
    return new Form(attributes);
  }
}

import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { ToastType } from 'react-toastify';

import type { ITemplate } from '@feathr/blackbox';
import type { Template } from '@feathr/blackbox';
import {
  ArchiveModalV1,
  ConfirmModalV1,
  ContextMenu,
  EAlertV2Type,
  toast,
} from '@feathr/components';
import TemplateSummary from '@feathr/extender/components/TemplateSummary';
import { useLocalUrl, useStore, useUser } from '@feathr/extender/state';
import { getIconForAction, useToggle } from '@feathr/hooks';

import { toastMessage } from '../templateUtils';

interface IProps {
  template: Template;
}

export function sanitizeFileName(name: string): string {
  return (
    name
      // Remove whitespace from the beginning and end of the string
      .trim()
      // Replace any character that is not a lowercase letter, a digit, an underscore, or a hyphen with an underscore
      .replace(/[^a-z0-9_-]/gi, '_')
      // Replace one or more consecutive underscores with a single underscore
      .replace(/_+/g, '_')
      // Remove any leading or trailing underscores
      .replace(/^(_+)|(_+)$/g, '')
      .toLowerCase()
      // Limit to 255 characters
      .slice(0, 255)
  );
}

function EventTemplatesGlobalOptions({ template }: IProps): JSX.Element {
  const { Templates } = useStore();
  const localUrl = useLocalUrl();
  const { eventId } = useParams<{ eventId: string }>();
  const [isArchiveModalOpen, toggleArchiveModalOpen] = useToggle(false);
  const [isDuplicateModalOpen, toggleDuplicateModalOpen] = useToggle(false);
  const [isDefaultModalOpen, toggleDefaultModalOpen] = useToggle(false);
  const [isAccountModalOpen, toggleAccountModalOpen] = useToggle(false);
  const { t } = useTranslation();
  const user = useUser();

  const parentKind = template.get('parent_kind');
  const isDefault = !!template.get('default');
  const isReadOnly = !!template.isReadOnly;
  const isAccount = !isDefault && parentKind === 'account';
  const isEvent = !isDefault && parentKind === 'event';
  const isCampaign = !isDefault && parentKind === 'campaign';

  const isFeathrSuperuser = !!user?.isSuperuser;

  async function handleDuplicate(): Promise<void> {
    const patch = { default: false } as Partial<ITemplate>;

    if (isDefault || isAccount) {
      /*
       * Default and account templates should duplicate as project templates.
       * If default or account template, do not trust event parent data!
       */
      patch.event_id = eventId;
    } else {
      if (isEvent) {
        // template.event is not set for event-level templates.
        patch.event_id = template.get('parent');
      } else if (isCampaign) {
        patch.event_id = template.get('event');
      }
      if (isCampaign) {
        patch.campaign_id = template.get('parent');
      }
    }

    const updatedTemplate = await template.clone(patch);
    Templates.refreshApiCache();

    toastMessage({
      updatedTemplate,
      successMessage: t('Your template has been duplicated.'),
      errorMessage: t('An error occurred while trying to duplicate your template:\n{{error}}', {
        error: updatedTemplate.error?.message || 'Unexpected error',
      }),
    });

    toggleDuplicateModalOpen();
  }

  async function handleMakeDefault(): Promise<void> {
    const patch = {
      default: true,
      parent: undefined,
      parent_kind: undefined,
    } as Partial<ITemplate>;

    const updatedTemplate = await template.patch(patch);
    toastMessage({
      updatedTemplate,
      successMessage: t('Your template has been made a default template.'),
      errorMessage: t(
        'An error occurred while trying to make your template a default template:\n{{error}}',
        {
          error: updatedTemplate.error?.message || 'Unexpected error',
        },
      ),
    });
    toggleDefaultModalOpen();
  }

  async function handleMakeAccount(): Promise<void> {
    const patch = {
      parent: template.get('account'),
      parent_kind: 'account',
    } as Partial<ITemplate>;

    if (template.get('event')) {
      patch.event = null;
    }

    const updatedTemplate = await template.patch(patch);
    toastMessage({
      updatedTemplate,
      successMessage: t('Your template has been made an account template.'),
      errorMessage: t(
        'An error occurred while trying to make your template and account template:\n{{error}}',
        {
          error: updatedTemplate.error?.message || 'Unexpected error',
        },
      ),
    });
    toggleAccountModalOpen();
  }

  function save(filename: string, content: string): void {
    const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  function exportHTML(): void {
    const content = template.get('content')['_html'];
    const contentString = typeof content === 'string' ? content : JSON.stringify(content);
    const { name: templateName } = template;
    const sanitizedFileName = sanitizeFileName(templateName) + '.html';
    try {
      save(sanitizedFileName, contentString);
      toast(
        t('"{{templateName}}" template successfully exported as HTML.', {
          templateName,
        }),
        {
          type: ToastType.SUCCESS,
        },
      );
    } catch (error) {
      toast(
        t(
          'There was an issue exporting the "{{templateName}}" template as HTML. Please try again later. \n{{- error}}',
          {
            templateName,
            error,
          },
        ),
        {
          type: ToastType.ERROR,
        },
      );
    }
  }

  return (
    <>
      <ContextMenu buttonType={'icon'} position={'left-start'}>
        <ContextMenu.Item
          disabled={template.isUpdating}
          link={localUrl(template.getItemUrl())}
          prefix={getIconForAction('view')}
        >
          {t('View')}
        </ContextMenu.Item>
        <ContextMenu.Item
          disabled={template.isUpdating || isDefault || isReadOnly}
          link={localUrl(template.getItemUrl('edit'))}
          prefix={getIconForAction('edit')}
        >
          {t('Edit')}
        </ContextMenu.Item>
        <ContextMenu.Item
          disabled={template.isUpdating || isReadOnly}
          onClick={exportHTML}
          prefix={getIconForAction('export')}
        >
          {t('Export as HTML')}
        </ContextMenu.Item>
        <ContextMenu.Divider />
        <ContextMenu.Item
          disabled={template.isUpdating}
          onClick={toggleDuplicateModalOpen}
          prefix={getIconForAction('duplicate')}
        >
          {t('Duplicate')}
        </ContextMenu.Item>
        {(isEvent || isAccount || isFeathrSuperuser) && (
          <>
            {isEvent && (
              <ContextMenu.Item
                disabled={template.isUpdating}
                onClick={toggleAccountModalOpen}
                prefix={getIconForAction('template')}
              >
                {t('Make account template')}
              </ContextMenu.Item>
            )}
            {!isDefault && isFeathrSuperuser && (
              <ContextMenu.Item
                disabled={template.isUpdating}
                onClick={toggleDefaultModalOpen}
                prefix={getIconForAction('template')}
              >
                {t('Make default template')}
              </ContextMenu.Item>
            )}
          </>
        )}
        <ContextMenu.Divider />
        <ContextMenu.Item
          disabled={template.isUpdating || isDefault}
          onClick={toggleArchiveModalOpen}
          prefix={getIconForAction('archive')}
          theme={'danger'}
        >
          {t('Archive')}
        </ContextMenu.Item>
      </ContextMenu>
      {isDuplicateModalOpen && (
        <ConfirmModalV1
          message={t('Are you sure you want to duplicate this template?')}
          onClose={toggleDuplicateModalOpen}
          onConfirm={handleDuplicate}
          t={t}
          title={t('Duplicate')}
        >
          <TemplateSummary template={template} />
        </ConfirmModalV1>
      )}
      {isDefaultModalOpen && (
        <ConfirmModalV1
          message={t(
            'Are you sure you want to make this a default template? This will make it available to all accounts!',
          )}
          onClose={toggleDefaultModalOpen}
          onConfirm={handleMakeDefault}
          t={t}
          title={t('Make Default Template')}
          type={EAlertV2Type.warning}
        >
          <TemplateSummary template={template} />
        </ConfirmModalV1>
      )}
      {isAccountModalOpen && (
        <ConfirmModalV1
          message={t(
            'Are you sure you want to make this an account template? This will make it available to all projects in your account.',
          )}
          onClose={toggleAccountModalOpen}
          onConfirm={handleMakeAccount}
          t={t}
          title={t('Make Account Template')}
        >
          <TemplateSummary template={template} />
        </ConfirmModalV1>
      )}
      {isArchiveModalOpen && (
        <ArchiveModalV1
          errorMessage={t('There was an issue archiving the template. Please try again later.')}
          model={template}
          onClose={toggleArchiveModalOpen}
          successMessage={t('Your template {{name}} has been archived.', { name: template.name })}
          t={t}
        >
          <TemplateSummary template={template} />
        </ArchiveModalV1>
      )}
    </>
  );
}

export default observer(EventTemplatesGlobalOptions);

import capitalize from 'lodash.capitalize';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, DisplayCreative } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import { CardV2 as Card, Chip, ContextMenu, ModalV1 } from '@feathr/components';
import CreativeMetadata from '@feathr/extender/components/CreativeMetadata';
import CreativeThumbnail from '@feathr/extender/components/CreativeThumbnail';
import { useLocalUrl } from '@feathr/extender/state';
import { getIconForAction, moment, useRedirect, useToggle } from '@feathr/hooks';

import CreativePreview from './CreativePreview';

import * as styles from './Creative.css';

const statusColorMap: Record<string, string> = {
  ['not audited']: 'default',
  ['pending']: 'yellow',
  ['mixed']: 'violet',
  ['approved']: 'green',
  ['rejected']: 'red',
  ['unauditable']: 'orange',
};

const statusLabelMap: Record<string, string> = {
  ['not audited']: 'Not reviewed',
  ['pending']: 'Pending review',
  ['approved']: 'Approved',
  ['rejected']: 'Rejected',
  ['unauditable']: 'Not reviewable',
  ['mixed']: 'Mixed',
};

interface IProps {
  campaign: Campaign;
  creative: DisplayCreative;
  onRemove: (creative: DisplayCreative) => void;
}

function Creative({ campaign, creative, onRemove }: IProps): JSX.Element {
  const localUrl = useLocalUrl();
  const [, setRedirect] = useRedirect();
  const [showPreviewModal, togglePreviewModal] = useToggle(false);
  const { t } = useTranslation();

  const creativeSpec = creative.getSpec();

  const hasDestinationURL =
    campaign.isValid(['destination_url'], false) ||
    (creative.get('destination_url') && creative.isValid(['destination_url'], false));

  let statusLabel;
  const statuses = creative.get('audit_statuses', []);
  const parsedStatuses = [...new Set(statuses.map((status) => status.status))].filter(
    (status) => !!status,
  ) as string[];
  if (parsedStatuses.length > 1) {
    statusLabel = 'mixed';
  } else {
    statusLabel = parsedStatuses[0];
  }

  function getReason(reason: string | undefined): string {
    if (reason) {
      return `(${reason})`;
    } else {
      return '';
    }
  }

  const statusInfo = statuses.length ? (
    <ul className={styles.statuses}>
      {statuses.map(
        (status) =>
          status.status && (
            <li key={status.auditor}>{`${status.auditor}: ${capitalize(status.status)} ${getReason(
              status.reason,
            )}`}</li>
          ),
      )}
    </ul>
  ) : (
    <></>
  );

  const progress = campaign.get('sync_progress');
  const synced = progress ? progress.synced_campaign : false;
  const date_end = moment.utc(campaign.get('date_end'));

  function handleRemove(): void {
    onRemove(creative);
  }

  const ApprovalStatus = (
    <>
      <dt>{t('Approval Status')}</dt>
      <dd>
        {synced && statuses.length ? (
          <Chip
            isLoading={creative.isPending}
            theme={statusColorMap[statusLabel]}
            tooltip={statusInfo}
          >
            {statusLabelMap[statusLabel]}
          </Chip>
        ) : campaign.get('state') === CampaignState.Published ? (
          <Chip theme={'yellow'}>{t('Pending')}</Chip>
        ) : (
          <Chip>{t('N/A')}</Chip>
        )}
      </dd>
    </>
  );

  return (
    <>
      <Card>
        <Card.Header padding={'compact'} title={t('Creative')}>
          <ContextMenu buttonType={'icon'}>
            <ContextMenu.Item
              link={setRedirect(localUrl(creative.getItemUrl('edit/design')), 'step4')}
              prefix={getIconForAction('edit')}
            >
              Edit
            </ContextMenu.Item>
            <ContextMenu.Item
              disabled={!hasDestinationURL}
              onClick={togglePreviewModal}
              prefix={getIconForAction('preview')}
              tooltip={hasDestinationURL ? t('Open preview') : t('Add destination URL')}
            >
              Preview
            </ContextMenu.Item>
            <ContextMenu.Item
              onClick={handleRemove}
              prefix={getIconForAction('delete')}
              theme={'danger'}
            >
              {t('Remove')}
            </ContextMenu.Item>
          </ContextMenu>
        </Card.Header>
        <Card.Content>
          <CreativeThumbnail creative={creative.toJS()} size={200} />
          <dl>
            <dt>{t('Dimensions')}</dt>
            <dd>
              {creative.get('width')}px x {creative.get('height')}px
            </dd>
            <dt>{t('Spec')}</dt>
            <dd>{creativeSpec?.name}</dd>
            {moment.utc().isBefore(date_end) && ApprovalStatus}
          </dl>
          <CreativeMetadata
            creative={creative}
            destinationUrlPlaceholder={campaign?.get('destination_url')}
            showStatus={true}
          />
        </Card.Content>
      </Card>
      {showPreviewModal && (
        <ModalV1
          controlled={true}
          onClose={togglePreviewModal}
          size={'xl'}
          t={t}
          title={t('Creative Preview')}
        >
          <CreativePreview campaign={campaign} creative={creative} />
        </ModalV1>
      )}
    </>
  );
}

export default observer(Creative);

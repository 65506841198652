import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { Targeting } from '@feathr/blackbox';
import { FormSummaryItem, Skeleton } from '@feathr/components';
import { useStore } from '@feathr/extender/state';

import { CampaignContext } from '../CampaignEditPage/CampaignEditPage.context';

interface IProps {
  validationErrors?: {
    filters?: string[];
  };
}

const GeoFiltersSummary = observer(({ validationErrors }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const { GeoFilters, Targetables } = useStore();
  const { targetings } = useContext(CampaignContext);

  function filterTargetings(trgt: Targeting): boolean {
    /*
     * Many types of targetings can belong to a campaign, but we only want geofilter targetings
     * that point to a group (target data).
     */
    return trgt.get('kind') === 'geo' && !trgt.get('is_archived') && !!trgt.get('target_data');
  }

  const filteredTargetings = targetings?.models.filter(filterTargetings) ?? [];
  const hasTargetings = filteredTargetings.length > 0;
  const targetingListItems = filteredTargetings.map((gf) => {
    const trgt = Targetables.get(gf.get('target_data')!);
    if (trgt.get('geo_filter')) {
      const geofilter = GeoFilters.get(trgt.get('geo_filter')!);
      return (
        <li key={gf.id}>
          {(function (): string {
            const address = geofilter.get('address');
            const kind = geofilter.get('kind');
            const name = trgt.get('name');

            if (['Region', 'City'].includes(kind)) {
              return t('{{name}} | {{kind}} | Country: {{country}} | Region: {{region}}', {
                country: address.country,
                kind,
                name,
                region: address.region,
              });
            } else if (['City'].includes(kind)) {
              return t('{{name}} | {{kind}} | Country: {{country}}', {
                country: address.country,
                kind,
                name,
              });
            }
            return t('{{name}} | {{kind}}', { kind, name });
          })()}
        </li>
      );
    } else {
      return null;
    }
  });

  return (
    <FormSummaryItem
      errors={validationErrors?.filters}
      label={t('Filters')}
      value={
        targetings?.isPending ? (
          <ol>
            <li>
              <Skeleton width={100} />
            </li>
          </ol>
        ) : hasTargetings ? (
          <ol>{targetingListItems}</ol>
        ) : (
          t('(None)')
        )
      }
    />
  );
});

GeoFiltersSummary.displayName = 'GeoFiltersSummary';

export default GeoFiltersSummary;

import debounce from 'debounce-promise';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { Person, TPersonCustomDataValue } from '@feathr/blackbox';
import { AsyncCreatableSelectElement, toast } from '@feathr/components';
import { DEFAULT_DEBOUNCE_WAIT, errorMessage } from '@feathr/hooks';

export interface IPersonCompaniesSelectOption {
  label: string;
  value: string;
}

interface IProps {
  defaultOptions: IPersonCompaniesSelectOption[];
  setCompanies: (companies: IPersonCompaniesSelectOption[]) => void;
  person: Person;
}

function PersonCompaniesSelect({
  defaultOptions,
  person,
  setCompanies,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();

  const getCompanies = debounce(async (query: string = '') => {
    try {
      const companies = await person.getCompanies(query);
      return companies.map(({ value }) => ({ label: value, value }));
    } catch (error) {
      toast(
        t("There was an error fetching the person's companies: {{message}}", {
          message: errorMessage(error, t),
        }),
      );
      return;
    }
  }, DEFAULT_DEBOUNCE_WAIT);

  useEffect(() => {
    getCompanies()
      .then((companies: IPersonCompaniesSelectOption[] | undefined) => {
        if (companies) {
          setCompanies(companies);
        }
      })
      .catch((error) => {
        // TODO: properly handle errors.
        throw error;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getOptionLabel({ label }: IPersonCompaniesSelectOption): string {
    return label;
  }

  function getOptionValue({ value }: IPersonCompaniesSelectOption): TPersonCustomDataValue {
    return value;
  }

  function handleCreateLabel(inputValue: string): string {
    return t('Add company: {{companyName}}', { companyName: inputValue });
  }

  function handleCreateAndGetOption(inputValue: string): IPersonCompaniesSelectOption {
    return { label: inputValue, value: inputValue };
  }

  function handleChangeCompanies(options: IPersonCompaniesSelectOption[]): void {
    person.set({ companies: options.map(({ value }) => value) });
  }

  function onCreateOption(inputValue: string): void {
    person.set({ companies: [...person.get('companies'), inputValue] });
  }

  return (
    <AsyncCreatableSelectElement<IPersonCompaniesSelectOption>
      createOption={handleCreateAndGetOption}
      defaultOptions={defaultOptions}
      formatCreateLabel={handleCreateLabel}
      getNewOptionData={handleCreateAndGetOption}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      isMulti={true}
      key={'companies'}
      label={t('Companies')}
      loadOptions={getCompanies}
      name={'person-companies'}
      onChange={handleChangeCompanies}
      onCreateOption={onCreateOption}
      value={person.get('companies', []).map((company) => ({ label: company, value: company }))}
    />
  );
}

export default observer(PersonCompaniesSelect);

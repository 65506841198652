import { useDisclosure } from '@mantine/hooks';
import type { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { Importer } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { ArchiveModal, ContextMenu, TableColumnHeader, toast } from '@feathr/components';
import { useLocalUrl, useStore } from '@feathr/extender/state';
import { getIconForAction } from '@feathr/hooks';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

interface IRow {
  original: Importer;
}

const getImporterState = (state, errorsKey) => ({
  isComplete: state === 'complete',
  isDraft: state === 'draft',
  archivable: ['complete', 'failed'].includes(state),
  hasErrors: Boolean(errorsKey),
});

const getModalProps = (type: 'delete' | 'archive', t: TFunction, name: string) => {
  if (type === 'delete') {
    return {
      alertDescription: t(
        'Deleting this import will remove it from the list of imports. This action cannot be undone.',
      ),
      alertTitle: t('Are you sure you want to delete this import?'),
      confirmationPhrase: t('DELETE'),
      confirmButtonText: t('Delete'),
      title: t('Delete "{{- name}}"', { name }),
    };
  }
  return {
    alertDescription: t(
      'Archiving this import will remove it from the list of imports and cannot be undone. Persons created or updated by this import will not be affected.',
    ),
    alertTitle: t('Are you sure you want to archive this import?'),
    confirmationPhrase: t('ARCHIVE'),
    confirmButtonText: t('Archive'),
    title: t('Archive "{{- name}}"', { name }),
  };
};

const OptionsCell = observer(({ original }: IRow) => {
  const { t } = useTranslation();
  const localUrl = useLocalUrl();
  const { Importers } = useStore();

  const [showArchiveModal, { open: openModal, close: closeModal }] = useDisclosure(false);

  const { isComplete, isDraft, archivable, hasErrors } = getImporterState(
    original.get('state'),
    original.get('errors_key'),
  );

  const modalType = isDraft ? 'delete' : 'archive';
  const modalProps = getModalProps(modalType, t, original.name);

  async function handleAction(action): Promise<void> {
    try {
      await action();

      if (modalType === 'delete') {
        Importers.remove(original.id);
      }

      const successMessage =
        modalType === 'delete'
          ? t('Import deleted successfully.')
          : t('Import archived successfully.');
      toast(successMessage, { type: ToastType.SUCCESS });
    } catch (error) {
      const errorMessage =
        modalType === 'delete'
          ? t(`Something went wrong while trying to delete this import: {{- error}}`, { error })
          : t(`Something went wrong while trying to archive this import: {{- error}}`, { error });
      toast(errorMessage, { type: ToastType.ERROR });
    } finally {
      closeModal();
    }
  }

  async function handleConfirm(): Promise<void> {
    const action = isDraft
      ? original.deleteImport.bind(original, original.id)
      : original.archive.bind(original);
    handleAction(action);
  }

  return (
    <>
      <ContextMenu buttonType={'icon'} position={'left-start'}>
        <ContextMenu.Item
          disabled={!isComplete}
          link={localUrl(`/data/segments/${original.get('segment')}/people`)}
          type={'link'}
        >
          {t('View Contacts')}
        </ContextMenu.Item>
        <ContextMenu.Item disabled={isDraft} href={original.downloadUrl} type={'link'}>
          {t('Download File')}
        </ContextMenu.Item>
        {hasErrors && (
          <ContextMenu.Item href={original.downloadErrorUrl} type={'link'}>
            {t('Download Errors')}
          </ContextMenu.Item>
        )}
        {isDraft && (
          <ContextMenu.Item
            onClick={openModal}
            prefix={getIconForAction('delete')}
            theme={'danger'}
          >
            {t('Delete')}
          </ContextMenu.Item>
        )}
        {archivable && (
          <ContextMenu.Item
            onClick={openModal}
            prefix={getIconForAction('archive')}
            theme={'danger'}
          >
            {t('Archive')}
          </ContextMenu.Item>
        )}
      </ContextMenu>
      <ArchiveModal
        {...modalProps}
        cancelButtonText={t('Cancel')}
        confirmButtonType={'danger'}
        onClose={closeModal}
        onConfirm={handleConfirm}
        opened={showArchiveModal}
        t={t}
      />
    </>
  );
});

export default (): IColumn<Importer> => ({
  id: 'options',
  checkboxLabel: 'Options',
  Header: TableColumnHeader({
    title: 'Options',
  }),
  width: 80,
  sortable: false,
  className: tableStyles.cellCenter,
  Cell(row): JSX.Element {
    return <OptionsCell {...row} />;
  },
});

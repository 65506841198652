import { useSortable } from '@dnd-kit/sortable';
import { faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { Dispatch, FocusEvent, JSX, SetStateAction } from 'react';
import React from 'react';

import type { IListRowItem, IRowItem } from '@feathr/blackbox';
import { FieldDataType } from '@feathr/blackbox';
import { Button, Checkbox, DatePicker, Icon, Select } from '@feathr/components';
import { Input } from '@feathr/components';

import * as styles from './DraggableFormElement.css';
interface IProps {
  field: IRowItem;
  onFocusField: Dispatch<SetStateAction<IRowItem | undefined>>;
  onDeleteField: () => void;
}

function DraggableFormElement({
  field,
  field: { id, label, placeholder, type },
  onFocusField,
  onDeleteField,
}: IProps): JSX.Element | undefined {
  const { attributes, listeners, setNodeRef, isDragging } = useSortable({
    id,
    data: { containerId: 'form-builder' },
  });

  /** Stops date fields from losing focus */
  function handleDatePickerBlur(event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  const element = (function (): JSX.Element | undefined {
    const shared = { label, wrapperClassName: styles.input };
    const input = (function (): JSX.Element | undefined {
      switch (type) {
        case FieldDataType.str:
          return (
            <Input
              {...shared}
              helpText={field.help_text}
              placeholder={placeholder as string}
              required={field.required}
              type={'text'}
            />
          );

        case FieldDataType.bool:
          return (
            <Checkbox
              className={styles.checkbox}
              {...shared}
              helpText={field.help_text}
              layout={'well'}
              placeholder={placeholder}
              required={field.required}
              value={true}
            />
          );

        case FieldDataType.int:

        case FieldDataType.float:
          return (
            <Input
              {...shared}
              helpText={field.help_text}
              placeholder={placeholder?.toString() ?? '0'}
              required={field.required}
              type={'number'}
            />
          );

        case FieldDataType.date:
          return (
            <DatePicker
              {...shared}
              helpText={field.help_text}
              onBlur={handleDatePickerBlur}
              placeholder={placeholder as string}
              required={field.required}
              showCalendarIcon={true}
            />
          );

        case FieldDataType.list:
          const options = (field as IListRowItem).options.map((option) => ({
            id: option,
            name: option,
          }));

          function handleSelectMulti(): void {
            return;
          }

          return (
            <Select
              {...shared}
              helpText={field.help_text}
              isMulti={true}
              name={'form-element-select'}
              onSelectMulti={handleSelectMulti}
              options={options}
              required={field.required}
              value={[]}
            />
          );
      }
    })();

    return (
      <>
        <Button type={'naked'} {...listeners} {...attributes}>
          {!isDragging && <Icon className={styles.icon} icon={faGripDotsVertical} />}
        </Button>
        {input}
      </>
    );
  })();

  function handleFocus(): void {
    onFocusField(field);
  }

  function handleBlur(event: FocusEvent<HTMLDivElement>): void {
    if (event.relatedTarget === null) {
      onFocusField(undefined);
    }
  }

  function handleKeyDown(event: React.KeyboardEvent): void {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      event.preventDefault();
      onDeleteField();
    }
  }

  return (
    <div
      className={classNames(styles.root, { [styles.placeholder]: isDragging })}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      ref={setNodeRef}
    >
      {element}
    </div>
  );
}

export default observer(DraggableFormElement);

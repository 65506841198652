import type { DragStartEvent, UniqueIdentifier } from '@dnd-kit/core';
import { DndContext, DragOverlay, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { FieldDataType, Form, IRowItem } from '@feathr/blackbox';
import { defaultPersonAttributes, FieldCollection } from '@feathr/blackbox';
import { Button, Modal, toast } from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

import Builder from './Builder';
import ConfigurationPanel from './ConfigurationPanel';
import type { IDragOverEvent } from './FormEditor.utils';
import { getListFieldOptions, onDragOver } from './FormEditor.utils';
import Field from './FormFields/Field';

import * as styles from './FormEditor.css';

interface IProps {
  form: Form;
}

function getFieldOptions(
  dataType: string,
  formConfig: Form['formConfig'],
  fieldId: string,
): string[] | undefined {
  return dataType === 'list' ? getListFieldOptions({ config: formConfig, fieldId }) : undefined;
}

function FormEditor({ form }: IProps): JSX.Element {
  const { t } = useTranslation();
  const { CustomFields } = useStore();
  const [allFields, setAllFields] = useState<IRowItem[]>([]);
  const [availableFields, setAvailableFields] = useState<IRowItem[]>([]);
  const [usedFields, setUsedFields] = useState<IRowItem[]>([]);
  const [activeField, setActiveField] = useState<UniqueIdentifier | undefined>(undefined);
  const [focusedField, setFocusedField] = useState<IRowItem | undefined>(undefined);
  const mouseSensor = useSensor(MouseSensor);
  const sensors = useSensors(mouseSensor);
  const [isDeleteModalOpen, toggleDeleteModalOpen] = useToggle(false);

  useEffect(() => {
    async function init(): Promise<void> {
      // List of available field ids for MVP
      const allowedFormFieldIds = [
        'first_name',
        'last_name',
        'name',
        'email',
        'phone',
        'occupation',
      ];

      const defaultFieldOptions = defaultPersonAttributes
        .filter((field) => allowedFormFieldIds.includes(field.id))
        .map((field) => ({
          id: field.id,
          label: field.u_key,
          name: field.u_key,
          type: field.data_type as FieldDataType,
          options: getFieldOptions(field.data_type, form.formConfig, field.id),
        }));

      try {
        const customFields = CustomFields.list({ filters: { collection: FieldCollection.Person } });
        await when(() => !customFields.isPending);
        if (customFields.isErrored) {
          toast(t('Failed to load custom fields: {{- error}}', { error: customFields.error }), {
            type: ToastType.ERROR,
          });
          setAllFields(defaultFieldOptions);
          return;
        }
        const customFieldOptions = customFields.models.map((field) => ({
          id: field.get('u_key'),
          label: field.get('u_key'),
          name: field.get('u_key'),
          type: field.get('data_type'),
          options: getFieldOptions(field.get('data_type'), form.formConfig, field.get('u_key')),
        }));

        const allOptions = [...defaultFieldOptions, ...customFieldOptions];

        const usedOptions = form.usedFields;
        const usedIds = usedOptions.map((field) => field.id);
        const availableOptions = allOptions.filter((field) => !usedIds.includes(field.id));
        setAllFields(allOptions);
        setUsedFields(usedOptions);
        setAvailableFields(availableOptions);
      } catch (error) {
        toast(t('Failed to load custom fields: {{- error}}', { error }), {
          type: ToastType.ERROR,
        });
        setAllFields(defaultFieldOptions);
      }
    }

    init();
  }, []);

  function handleDragStart(event: DragStartEvent): void {
    setActiveField(event.active.id);
  }

  function handleDragOver(event: IDragOverEvent): void {
    onDragOver({
      availableFields,
      setAvailableFields,
      usedFields,
      setUsedFields,
      ...event,
    });
  }

  function updateFieldProperties(key: UniqueIdentifier, value: unknown): void {
    if (focusedField !== undefined) {
      const updatedField = { ...focusedField, [key]: value };
      setFocusedField(updatedField);

      function updateField(prevField: IRowItem): IRowItem {
        if (prevField.id === focusedField!.id) {
          return updatedField;
        }
        return prevField;
      }

      setUsedFields((prevFields) => prevFields.map(updateField));
    }
  }

  function handleDeleteField(): void {
    if (focusedField) {
      setUsedFields((prevFields) => prevFields.filter((field) => field.id !== focusedField.id));
      setAvailableFields((prevFields) => [...prevFields, focusedField]);
      setFocusedField(undefined);
      toggleDeleteModalOpen();
    }
  }

  return (
    <div className={styles.root}>
      <DndContext onDragOver={handleDragOver} onDragStart={handleDragStart} sensors={sensors}>
        <SortableContext
          id={'configuration-panel'}
          items={availableFields}
          strategy={rectSortingStrategy}
        >
          <ConfigurationPanel
            fields={availableFields}
            focusedField={focusedField}
            onDeleteField={toggleDeleteModalOpen}
            onFocusField={setFocusedField}
            updateFieldProperties={updateFieldProperties}
          />
        </SortableContext>
        <SortableContext id={'form-builder'} items={usedFields} strategy={rectSortingStrategy}>
          <Builder
            fields={usedFields}
            form={form}
            onDeleteField={toggleDeleteModalOpen}
            onFocusField={setFocusedField}
          />
        </SortableContext>
        <DragOverlay className={styles.dragOverlay}>
          {activeField && allFields.find((field) => field.id === activeField) ? (
            <Field
              field={allFields.find((field) => field.id === activeField)!}
              isDragOverlay={true}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
      <Modal
        description={t('Are you sure you want to delete this field?')}
        onClose={toggleDeleteModalOpen}
        opened={isDeleteModalOpen}
        rightActions={
          <>
            <Button onClick={toggleDeleteModalOpen}>{t('Cancel')}</Button>
            <Button onClick={handleDeleteField} type={'danger'}>
              {t('Delete')}
            </Button>
          </>
        }
        title={t('Delete field')}
      />
    </div>
  );
}

export default observer(FormEditor);

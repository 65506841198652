import { useDroppable } from '@dnd-kit/core';
import { observer } from 'mobx-react-lite';
import type { Dispatch, JSX, SetStateAction } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Form, IFormConfig, IRowItem } from '@feathr/blackbox';
import { Button } from '@feathr/components';

import DraggableFormElement from './DraggableFormElement';

import * as styles from './Builder.css';

interface IProps {
  fields: IRowItem[];
  form: Form;
  onFocusField: Dispatch<SetStateAction<IRowItem | undefined>>;
  onDeleteField: () => void;
}

function Builder({ fields, form, onDeleteField, onFocusField }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { setNodeRef: setDroppableRef } = useDroppable({
    id: 'form-builder',
  });
  const [config, setConfig] = useState<IFormConfig>(form.formConfig);

  // Set the updated config on the Form model as JSON.
  useEffect(() => {
    if (fields.length === 0) {
      return;
    }

    const newConfig = {
      ...config,
      rows: fields.map((field) => ({ fields: [field] })),
    };

    setConfig(newConfig);
    form.setConfig(newConfig);
  }, [fields]);

  function renderField(field: IRowItem): JSX.Element | null {
    if (field === undefined) {
      return null;
    }

    return (
      <DraggableFormElement
        field={field}
        key={field.id}
        onDeleteField={onDeleteField}
        onFocusField={onFocusField}
      />
    );
  }

  return (
    <div className={styles.root} data-name={'form-builder'} ref={setDroppableRef}>
      <div className={styles.content}>
        {fields.map(renderField)}
        {/* TODO: This button will be customizable */}
        <Button type={'primary'}>{t('Submit')}</Button>
      </div>
    </div>
  );
}

export default observer(Builder);

import { toJS } from 'mobx';

import type { User, UserRole } from '@feathr/blackbox';
import { EUserRoleIDs } from '@feathr/blackbox';

import useAccount from './useAccount';
import useFlags from './useFlags';
import useStore, { assertAuthenticatedStore } from './useStore';
import useUser from './useUser';

export type TUseRole = {
  /** The UserRole object */
  role: UserRole;

  /** The UserRole name, equivalent to role.name */
  roleName: string;

  /** Whether or not the role has billing permissions */
  hasBilling: boolean;

  /** Whether or not the role has conversions permissions */
  hasConversions: boolean;

  /** Whether or not the role has domains permissions */
  hasDomains: boolean;

  /** Whether or not the role has imports permissions */
  hasImports: boolean;

  /** Whether or not the role has integrations permissions */
  hasIntegrations: boolean;

  /** Whether or not the role has projects permissions */
  hasProjects: boolean;

  /** Whether or not the role has access to some projects */
  hasProjectsSome: boolean;

  /** Whether or not the role has roles permissions */
  hasRoles: boolean;

  /** Whether or not the role has segments permissions */
  hasSegments: boolean;

  /** Whether or not the role has access to some segments */
  hasSegmentsSome: boolean;

  /** Whether or not the role can create transactions */
  hasTransactions: boolean;

  /** Whether or not the role has users permissions */
  hasUsers: boolean;

  /** Whether or not the role is a custom role */
  isCustom: boolean;

  /** Whether or not the role is a default role */
  isDefault: boolean;

  /** Whether or not the role is a global role */
  isGlobal: boolean;

  /** Whether or not the role is hidden */
  isHidden: boolean;

  /** Whether or not the user is Admin */
  isAdmin: boolean;

  /** Whether or not the user is Accountant */
  isAccountant: boolean;

  /** Whether or not the user is Sudoer */
  isSudoer: boolean;

  /** Whether or not the user is Superuser */
  isSuperuser: boolean;

  /** Whether or not the user is Internal Onboarding Team which can be used to assign the `CSM` or `Strategist` role */
  isInternalOnboarding: boolean;

  /** The project IDs the user has access to if they have some access */
  projects: string[];

  /** The user permissions are checked for */
  user: User;

  /** Whether or not the user has the feature flag or the correct license */
  hasPermissionsLicenseOrFlag: boolean;
};

function useRole(): TUseRole {
  const store = useStore();
  assertAuthenticatedStore(store);

  const { isPlatformAdvanced } = useAccount();
  const user = useUser();
  const { showUsersAndRolesV2 = false } = useFlags();
  const { UserRoles } = store;

  // Convert the user into a model so we can access computed properties
  const role =
    UserRoles.create({ ...user.get('role') }) ?? UserRoles.create({ id: EUserRoleIDs.User });

  /*
   * Has the right license to use permissions. This check is utilized to display
   * permissions-specific features like the roles pages.
   */
  const hasPermissionsLicenseOrFlag = showUsersAndRolesV2 || isPlatformAdvanced;

  return {
    // Role related properties
    role,
    roleName: role.name,
    isCustom: role.isCustom,
    isDefault: role.isDefault,
    isGlobal: role.isGlobal,
    isHidden: role.isHidden,

    // User related properties
    isAdmin: user.isAdmin,
    isSudoer: user.isSudoer,
    isSuperuser: user.isSuperuser,
    isAccountant: user.isAccountant,
    isInternalOnboarding: user.isInternalOnboarding,

    // Access control properties
    hasBilling: user.isAdmin || role.isGlobal || role.billing.mode === 'all',
    // Superusers may not create transactions to avoid problems with intacct
    hasTransactions: user.isAccountant,
    hasConversions: user.isAdmin || role.isGlobal,
    hasDomains: role.isDefault || role.isGlobal || role.domains.mode === 'all',
    hasImports: role.isDefault || role.isGlobal || role.imports.mode === 'all',
    hasIntegrations: role.isDefault || role.isGlobal || role.integrations.mode === 'all',
    hasProjects: role.isDefault || role.isGlobal || role.projects.mode === 'all',
    hasProjectsSome: role.projects.mode === 'some',
    hasRoles: user.isAdmin || role.isGlobal,
    hasSegments: role.isDefault || role.isGlobal || role.segments.mode === 'all',
    hasSegmentsSome: role.segments.mode === 'some',
    hasUsers: user.isAdmin || role.isGlobal,

    // Additional properties
    projects: toJS(role.get('projects').allow_list) ?? [],
    user,
    hasPermissionsLicenseOrFlag,
  };
}

export default useRole;

import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type {
  AutoPinpointEmailCampaign,
  BaseCampaign,
  DripCampaign,
  EmailListFacebookCampaign,
  FacebookCampaign,
  GoogleAdsSmartCampaign,
  LandingPageCampaign,
  PinpointEmailCampaign,
  ReferralCampaign,
  SmartPinpointEmailCampaign,
  TrackedLinkCampaign,
  TTDCampaign,
} from '@feathr/blackbox';
import { CampaignClass, CampaignState } from '@feathr/blackbox';
import { Alert, AlertType, Button, Spinner, Toolbar } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import CampaignDatesAndChips from '@feathr/extender/components/CampaignDatesAndChips';
import { useCampaign } from '@feathr/extender/hooks';
import { useLocalUrl, useStore } from '@feathr/extender/state';
import { getIconForAction } from '@feathr/hooks';

import DefaultLandingPageCampaignPage from '../CampaignRouter/LandingPageCampaignPage/DefaultLandingPageCampaignPage';
import AddCampaignButton from '../CampaignsPage/AddCampaignButton';
import GoogleAdsCampaignEdit from '../GoogleAdsCampaignPage/GoogleAdsCampaignEdit';
import AdWizard from './AdWizard';
import AutoPinpointEmailCampaignEdit from './AutoPinpointEmailCampaignEdit';
import ConversationDetails from './ConversationDetails';
import DripCampaignEdit from './DripCampaignEdit/DripCampaignEdit';
import FacebookCampaignEdit from './FacebookCampaignEdit';
import LookalikeCampaignEdit from './LookalikeCampaignEdit';
import PinpointEmailCampaignEdit from './PinpointEmailCampaignEdit';
import ReferralCampaignEdit from './ReferralCampaignEdit';
import SmartPinpointEmailCampaignEdit from './SmartPinpointEmailCampaignEdit';
import TrackedLinkCampaignEdit from './TrackedLinkCampaignEdit';
const defaultWarningMessage = 'Are you sure you want to navigate away? Changes made will be lost.';

export function setRedirectWarning(message = defaultWarningMessage): void {
  window.onbeforeunload = (e: BeforeUnloadEvent): string => {
    e.returnValue = message;
    return message;
  };
}

export function unsetRedirectWarning(): void {
  window.onbeforeunload = null;
}

function CampaignEditPage(): JSX.Element {
  const localUrl = useLocalUrl();
  const { t } = useTranslation();
  const { campaignId } = useParams<{ eventId: string; campaignId: string }>();
  const { Campaigns } = useStore();
  const campaign: BaseCampaign = Campaigns.get(campaignId);
  const { allowed } = useCampaign({
    campaign,
  });

  if (campaign.isPending) {
    return (
      <Page>
        <Spinner />
      </Page>
    );
  }

  function getPageTitle(pageTitle: string): string {
    // According to i18next documentation, the - needs to be added to make the key unescaped
    return t('Edit {{- name}}', { name: pageTitle });
  }

  const campaignClassName = campaign.get('_cls');
  const name = campaign.name;
  const state = campaign.get('state');

  const sharedProps = {
    pageTitle: getPageTitle(name),
    title: name,
    width: 'wide' as const,
    description: (
      <CampaignDatesAndChips campaign={campaign} hideDates={state === CampaignState.Draft} />
    ),
    actions: allowed.reportLinkInWizardHeader ? (
      <Button link={campaign.getItemUrl()} prefix={getIconForAction('return')}>
        {t('Return to report')}
      </Button>
    ) : undefined,
  };

  if (campaignClassName === CampaignClass.Conversation) {
    return (
      <Page {...sharedProps}>
        <ConversationDetails />
      </Page>
    );
  }

  if (campaignClassName === CampaignClass.LandingPage) {
    return <DefaultLandingPageCampaignPage campaign={campaign as LandingPageCampaign} />;
  }

  if (campaignClassName === CampaignClass.TrackedLink) {
    return (
      <Page {...sharedProps}>
        <TrackedLinkCampaignEdit campaign={campaign as TrackedLinkCampaign} />
      </Page>
    );
  }

  if ([CampaignClass.Facebook, CampaignClass.EmailListFacebook].includes(campaignClassName)) {
    return (
      <Page {...sharedProps}>
        <FacebookCampaignEdit campaign={campaign as FacebookCampaign | EmailListFacebookCampaign} />
      </Page>
    );
  }

  if (campaignClassName === CampaignClass.Referral) {
    return (
      <Page {...sharedProps}>
        <ReferralCampaignEdit campaign={campaign as ReferralCampaign} />
      </Page>
    );
  }

  if (campaignClassName === CampaignClass.PinpointEmail) {
    return (
      <Page {...sharedProps}>
        <PinpointEmailCampaignEdit campaign={campaign as PinpointEmailCampaign} />
      </Page>
    );
  }

  if (campaignClassName === CampaignClass.SmartPinpointEmail) {
    return (
      <Page {...sharedProps}>
        <SmartPinpointEmailCampaignEdit campaign={campaign as SmartPinpointEmailCampaign} />
      </Page>
    );
  }

  if (campaignClassName === CampaignClass.AutoPinpointEmail) {
    return (
      <Page {...sharedProps}>
        <AutoPinpointEmailCampaignEdit campaign={campaign as AutoPinpointEmailCampaign} />
      </Page>
    );
  }

  if (campaignClassName === CampaignClass.Drip) {
    return (
      <Page {...sharedProps}>
        <DripCampaignEdit campaign={campaign as DripCampaign} />
      </Page>
    );
  }

  if (campaign.isTTDCampaign && campaignClassName !== CampaignClass.Lookalike) {
    return (
      <Page {...sharedProps}>
        <AdWizard campaign={campaign as TTDCampaign} />
      </Page>
    );
  }
  if (campaignClassName === CampaignClass.Lookalike) {
    return (
      <Page {...sharedProps}>
        <LookalikeCampaignEdit campaign={campaign} />
      </Page>
    );
  }
  if (campaignClassName === CampaignClass.GoogleAdsSmart) {
    return (
      <Page {...sharedProps}>
        <GoogleAdsCampaignEdit campaign={campaign as GoogleAdsSmartCampaign} />
      </Page>
    );
  }

  return (
    <Page pageTitle={getPageTitle(name)} title={name} width={'wide'}>
      <Alert type={AlertType.warning}>
        <Trans t={t}>
          This campaign type is outdated and can no longer be edited. Please create a{' '}
          <AddCampaignButton type={'link'}>new campaign</AddCampaignButton> instead.
        </Trans>
      </Alert>
      <Toolbar align={'left'}>
        <Button link={localUrl(campaign.getItemUrl())} type={'primary'}>
          {t('Back')}
        </Button>
      </Toolbar>
    </Page>
  );
}

export default observer(CampaignEditPage);

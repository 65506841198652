import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import debounce from 'debounce-promise';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { IListRowItem } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { AsyncCreatableSelect, Icon, toast } from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { DEFAULT_DEBOUNCE_WAIT } from '@feathr/hooks';

import * as styles from './SelectListOptions.css';

interface IProps {
  field: IListRowItem;
  onChange: (options: string[]) => void;
}

function SelectListOptions({ field, onChange }: Readonly<IProps>): React.ReactElement {
  const { t } = useTranslation();
  const { CustomFields } = useStore();

  const getListOptions = useRef<
    (inputValue: string) => Promise<Array<{ key: string; label: string; options: ISelectOption[] }>>
  >(
    debounce(async (query?: string) => {
      try {
        const options = await CustomFields.getListOptions(field.name, query);
        const selectOptions = options
          .filter(({ value }) => !!value)
          .map(({ value }) => ({ name: value, id: value }))
          .concat(field.options.map((option) => ({ name: option, id: option })));
        return [{ key: 'options', label: t('Options'), options: selectOptions }];
      } catch (error) {
        toast(t('Failed to fetch options: {{- error}}', { error }), { type: ToastType.ERROR });
        return [];
      }
    }, DEFAULT_DEBOUNCE_WAIT),
  ).current;

  function createOption(inputValue: string): ISelectOption {
    return { name: inputValue, id: inputValue };
  }

  function handleChange(options: ISelectOption[]): void {
    onChange(options.map(({ name }) => name));
  }

  return (
    <AsyncCreatableSelect
      createOption={createOption}
      defaultOptions={field.options.map((option) => ({ name: option, id: option }))}
      getNewOptionData={createOption}
      isMulti={true}
      label={t('Options')}
      loadOptions={getListOptions}
      name={`${field.name}_options`}
      onChange={handleChange}
      placeholder={
        <span className={styles.placeholder}>
          <Icon className={styles.search} icon={faSearch} />
          <span>{t('Search and select items')}</span>
        </span>
      }
      value={field.options.map((option) => ({ name: option, id: option }))}
    />
  );
}

export default observer(SelectListOptions);

import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { CampaignClass } from '@feathr/blackbox';

interface IUseTextProps {
  cls: CampaignClass;
}

interface IUseTextReturn {
  description: JSX.Element;
  helpDeskUrl?: JSX.Element;
}

export function useText({ cls }: IUseTextProps): IUseTextReturn {
  const { t } = useTranslation();

  const getHelpDeskLink = (): JSX.Element | undefined => {
    let prompt = '';
    let url = '';

    switch (cls) {
      case CampaignClass.Segment:
        prompt = t('about group best practices');
        url = 'https://help.feathr.co/hc/en-us/articles/360037462293-Best-Practices-for-Segments';
        break;

      case CampaignClass.EmailList:
        prompt = t('how to format an Email List Upload');
        url =
          'https://help.feathr.co/hc/en-us/articles/360039811873-How-to-Format-an-Email-List-Upload';
        break;

      case CampaignClass.Search:
        prompt = t('how to generate a Search Keyword List');
        url =
          'https://help.feathr.co/hc/en-us/articles/360039088334-How-To-Generate-a-Search-Keyword-List';
        break;

      default:
        return undefined;
    }

    return (
      <a href={url} rel={'noreferrer'} target={'_blank'}>
        {t('Check out our help desk to learn {{prompt}}', { prompt })}
      </a>
    );
  };

  const descriptions = {
    [CampaignClass.Segment]: (
      <p>
        {t(
          'Select a group to include or exclude from this campaign. Feathr will bid to show ads to people in included groups unless they are also in an excluded group.',
        )}
      </p>
    ),
    [CampaignClass.Facebook]: (
      <p>
        {t(
          'Retargeting Campaign Targets are derived from a new or existing group. Each Target can be configured to include or exclude the people in the group. Meta will bid to show ads to people in an included Target unless they are also in an excluded Target.',
        )}
        <br />
        <strong>{t('Note: Meta campaigns currently only support targeting by URL.')}</strong>
      </p>
    ),
    [CampaignClass.Lookalike]: t(
      'We will analyze the behavioral and characteristics of the group you select to find and choose the most appropriate lookalike audience to target with this campaign.',
    ),
    [CampaignClass.Affinity]: t(
      'Select from our comprehensive library of affinity audiences that are categorized by industry, demographic, and market.',
    ),
    [CampaignClass.SeedSegment]: t(
      'We will analyze the behavioral and characteristics of the group you select to find and choose the most appropriate audience to target with this campaign.',
    ),
    [CampaignClass.EmailList]: (
      <p>
        {t(
          'Email List Campaign Targets are made from third-party data derived from an uploaded list of email addresses. We map the email addresses to cookies through a third-party data vendor. This process can take a few days, so it is best to publish campaigns of this type well in advance to ensure it is ready to run on the first day of its duration.',
        )}
      </p>
    ),
    // Recommending a minimum of 10,000 as 2,000 may be too small for specific targeted audiences
    [CampaignClass.EmailListFacebook]: t(
      'Email list uploads are sent to Facebook and matched to users. Facebook will usually match between 60-70% of the contacts on your list. Feathr requires a minimum of 2,500 contacts and recommends 10,000 to have the most effective reach.',
    ),
    [CampaignClass.Search]: (
      <p>
        {t(
          'Search keyword campaign targets are based upon an uploaded list of search keywords. We find and target people who have recently searched for any of the provided keywords. This process can take a few days, so it’s best to publish this campaign in advance to ensure it is ready to run on the first scheduled day.',
        )}
      </p>
    ),
    [CampaignClass.MobileGeoFencing]: (
      <Trans t={t}>
        <p>
          Select places on the map or search to add them to your target. Your ads will reach people
          near these locations during your campaign.
        </p>
        <span>
          Optional: Upload a .csv file of your target locations.{' '}
          <a href={'https://feathr-static-assets.s3.amazonaws.com/data/places_template.csv'}>
            Click here
          </a>{' '}
          to download an example file to use as a template.
        </span>
      </Trans>
    ),
    [CampaignClass.MobileGeoFenceRetargeting]: (
      <Trans t={t}>
        <p>
          Select places on the map or search to add them to your target. Your ads will reach people
          who were near these locations during your campaign.
        </p>
        <span>
          Optional: Upload a .csv file of your target locations.{' '}
          <a href={'https://feathr-static-assets.s3.amazonaws.com/data/places_template.csv'}>
            Click here
          </a>{' '}
          to download an example file to use as a template.
        </span>
      </Trans>
    ),
  };

  return { description: descriptions[cls], helpDeskUrl: getHelpDeskLink() };
}

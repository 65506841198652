import { type TConstraints } from '@feathr/rachis';

import { Campaign } from './campaign';
import { monetizationConstraints } from './monetization';
import type { ICampaignAttributes, TCampaignAttributes } from './types';
import { CampaignClass } from './types';

export abstract class DisplayCampaign<
  T extends ICampaignAttributes = ICampaignAttributes,
> extends Campaign<T> {
  public override get constraints(): TConstraints<TCampaignAttributes> {
    return {
      ...super.constraints,
      destination_url: (...args: any[]) => {
        const attributes = args[1];
        if (
          [
            CampaignClass.LandingPage,
            CampaignClass.TrackedLink,
            CampaignClass.Drip,
            CampaignClass.DripStep,
            CampaignClass.PinpointEmail,
            CampaignClass.SmartPinpointEmail,
            CampaignClass.AutoPinpointEmail,
          ].includes(attributes._cls)
        ) {
          return undefined;
        }
        return {
          url: true,
          presence: {
            allowEmpty: false,
          },
        };
      },
      monetization_value: () => {
        if (this.isMonetization) {
          return monetizationConstraints.monetization_value;
        }
        return null;
      },
    };
  }
}

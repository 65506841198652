import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign } from '@feathr/blackbox';
import { CardV2 as Card, Chip, Collapse, ContextMenu, Input, Radios } from '@feathr/components';
import EditFilters from '@feathr/extender/components/EditFilters';
import { useStore } from '@feathr/extender/state';
import { getIconForAction } from '@feathr/hooks';

import type {
  ISegmentSelectSimpleChangeProps,
  ISegmentSelectSimpleProps,
} from '../SegmentSelectSimple';
import SegmentSelectSimple from '../SegmentSelectSimple';
import FacebookAudience from './FacebookAudience';

import * as styles from './SegmentSelect.css';

export interface ISegmentSelectChangeProps extends ISegmentSelectSimpleChangeProps {
  id: string;
  included: boolean;
  disableInclusion?: boolean;
  disabled?: boolean;
}

interface ISegmentSelectBaseProps extends Omit<ISegmentSelectSimpleProps, 'onChange'> {
  children?: ReactNode;
  /** Child elements to display when adding a new group. */
  campaign?: Campaign;
  childrenOnAdd?: ReactNode;
  onRemove?: (id?: string) => void;
  prefix?: ReactNode;
}

export interface ISegmentSelectWithIncludeProps extends ISegmentSelectBaseProps {
  hideIncluded?: boolean;
  included: boolean;
  onChange: (props: ISegmentSelectChangeProps) => void;
}

export interface ISegmentSelectWithoutIncludeProps extends ISegmentSelectBaseProps {
  hideIncluded: true;
  included?: never;
  onChange: (props: ISegmentSelectSimpleChangeProps) => void;
}

function isWithIncludeProps(
  props: ISegmentSelectWithoutIncludeProps | ISegmentSelectWithIncludeProps,
): props is ISegmentSelectWithIncludeProps {
  return typeof (props as ISegmentSelectWithIncludeProps).included === 'boolean';
}

function SegmentSelect(props: ISegmentSelectWithoutIncludeProps);
function SegmentSelect(props: ISegmentSelectWithIncludeProps);
function SegmentSelect(
  props: ISegmentSelectWithoutIncludeProps | ISegmentSelectWithIncludeProps,
): JSX.Element {
  const {
    campaign,
    children,
    childrenOnAdd,
    filters = [],
    disableInclusion = false,
    disabled = false,
    excludeIds = [],
    hideCreate = false,
    hideIncluded = false,
    includeIds = [],
    included,
    onChange,
    onCreate,
    onRemove,
    prefix,
    stat,
    name,
    value,
  } = props;

  const { Segments } = useStore();
  const { t } = useTranslation();

  const segment = value ? Segments.get(value) : undefined;

  function handleRemove(): void {
    // This function is only callable if onRemove is set.
    onRemove!(value);
  }

  function handleSegmentChange({
    id,
  }: ISegmentSelectSimpleChangeProps | ISegmentSelectChangeProps): void {
    if (isWithIncludeProps(props)) {
      props.onChange({
        id,
        oldId: value,
        included: included!,
      });
    } else {
      props.onChange({ id, oldId: value });
    }
  }

  function handleIncludeChange(newValue?: string): void {
    onChange({
      id: value,
      oldId: value,
      included: newValue === 'included',
    });
  }

  const groupName = Segments.get(value)?.get('name') || t('Unselected Group');

  return (
    <Card contentClassName={styles.tempFix} name={'group'}>
      <Card.Header padding={'compact'} title={groupName}>
        {onRemove && (
          <ContextMenu buttonType={'icon'} name={'goal-context-menu'}>
            {/* TODO: move the prefix editing into a modal */}
            {/* <ContextMenu.Item name={'configure_filters'} prefix={getIconForAction('edit')}>
            {t('Edit group filters')}
            </ContextMenu.Item> */}
            <ContextMenu.Item
              disabled={disabled}
              name={'remove_segment'}
              onClick={handleRemove}
              prefix={getIconForAction('delete')}
              theme={'danger'}
            >
              {t('Remove')}
            </ContextMenu.Item>
          </ContextMenu>
        )}
      </Card.Header>
      <Card.Content addVerticalGap={true}>
        {prefix}
        <SegmentSelectSimple
          disabled={disabled}
          excludeIds={excludeIds}
          filters={filters}
          hideCreate={hideCreate}
          includeIds={includeIds}
          label={'Group'}
          name={name}
          onChange={handleSegmentChange}
          onCreate={onCreate}
          required
          stat={stat}
          value={value}
        />
        {segment?.isEphemeral && (
          <>
            <Input
              attribute={'name'}
              disabled={disabled}
              label={t('Name')}
              model={segment}
              required={true}
              type={'text'}
            />
            {childrenOnAdd}
          </>
        )}
        {!hideIncluded && (
          <Radios
            dataName={'filter_mode'}
            disabled={disableInclusion || disabled}
            layout={'block'}
            onChange={handleIncludeChange}
            options={[
              { id: 'included', name: t('Included') },
              { id: 'excluded', name: t('Excluded') },
            ]}
            value={included ? 'included' : 'excluded'}
          />
        )}
        {campaign?.isFacebook && (
          <FacebookAudience campaignState={campaign.get('state')} segment={segment} />
        )}
        {segment && (
          <Collapse
            title={segment.isReadOnly ? t('View group filters') : t('Configure group filters')}
          >
            {segment.isReadOnly && (
              <Chip
                suffix={getIconForAction('info')}
                tooltip={t(
                  'This group cannot be edited because it is based upon a conversion pixel.',
                )}
              >
                {t('Read-only')}
              </Chip>
            )}
            <EditFilters
              disabled={segment.isReadOnly || disabled}
              filterContext={filters.predicates__elemMatch ? 'facebookCampaign' : 'campaign'}
              hideGauge={true}
              mode={stat === 'num_emails_total' ? 'emails' : 'reachable'}
              segment={segment}
            />
          </Collapse>
        )}
        {children}
      </Card.Content>
    </Card>
  );
}

export default observer(SegmentSelect);

import decode from 'jwt-decode';
import Cookies from 'universal-cookie';

import { moment } from '@feathr/hooks';
import type { TRachisEmpty } from '@feathr/rachis';
import { isWretchError, wretch } from '@feathr/rachis';

interface IToken {
  exp?: number;
  nbf?: number;
  user_id?: string;
}

export interface ILogInResponse extends Record<string, unknown> {
  token: string;
}

enum ETokens {
  userId = 'user_id',
}

type TValues = keyof typeof ETokens | 'token';

export const SESSION_OTP_CHAR_COUNT = 8;

// TODO: Change cookie name from auth_token to token
const TOKEN = 'auth_token';

export function isTokenValid<T extends { exp?: number; nbf?: number }>(token: string): boolean {
  try {
    const decoded = decode<T>(token);
    const now = moment.utc().unix();
    if (((decoded.nbf && now > decoded.nbf) || !decoded.nbf) && decoded.exp && now < decoded.exp) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Token is expired.');
    return false;
  }
}

class Session {
  static isLoggingOut = false;

  public static get token(): string {
    const cookies = new Cookies();
    return cookies.get(TOKEN);
  }

  public static set token(token: string) {
    const cookies = new Cookies();
    const expires = new Date(Date.now() + 24 * 60 * 60 * 1000);
    cookies.set(TOKEN, token, { domain: Session.domain, path: '/', expires });
  }

  public static get userId(): string | undefined {
    return Session.get('userId');
  }

  public static get logInUrl(): string {
    return process.env.NODE_ENV === 'production' ? `//${LOGIN_URL}/` : '//local.feathr.app:9005/';
  }

  public static get isLoggedIn(): boolean {
    const token = Session.token;
    try {
      return !!token && isTokenValid<IToken>(token);
    } catch {
      return false;
    }
  }

  public static getHeaders(useAccountContext = true): HeadersInit {
    const headers: HeadersInit = { 'Content-Type': 'application/json' };
    const token = Session.token;
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    const accountId = sessionStorage.getItem('accountId');
    if (useAccountContext && accountId) {
      headers.AccountId = accountId;
    }

    return headers;
  }

  public static async logIn(
    email: string,
    password: string,
    otp?: string,
    trust?: boolean,
  ): Promise<void> {
    const response = await wretch<ILogInResponse>(Session.url, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ email, password, otp, trust }),
      headers: { 'Content-Type': 'application/json' },
    });
    if (isWretchError(response)) {
      throw response.error;
    } else {
      Session.token = response.data.token;
    }
  }

  private static async revokeToken(): Promise<void> {
    if (!Session.isLoggingOut) {
      Session.isLoggingOut = true;
      const url = `${BLACKBOX_URL}logout`;
      const headers = Session.getHeaders();
      const method = 'POST';
      const response = await wretch<TRachisEmpty>(url, {
        headers: headers,
        method: method,
      });
      Session.isLoggingOut = false;
      if (isWretchError(response)) {
        throw response.error;
      }
    }
  }

  public static async logOut(callback?: () => void): Promise<void> {
    try {
      // It is imperative that the token is revoked before the cookie is removed.
      await Session.revokeToken();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      throw e;
    } finally {
      try {
        const cookies = new Cookies();
        cookies.remove(TOKEN, { domain: Session.domain, path: '/' });
        localStorage.clear();
        sessionStorage.clear();
        if (callback) {
          callback();
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        throw e;
      }
    }
  }

  private static url = `${BLACKBOX_URL}jwt`;
  private static domain = process.env.NODE_ENV === 'production' ? '.feathr.co' : 'local.feathr.app';

  private static get(key: TValues, defaultValue?: string): string | undefined {
    const token = Session.token;
    if (key === 'token') {
      return token ? token : defaultValue;
    }

    if (!token) {
      return defaultValue;
    }

    const decoded = decode<IToken>(token);
    const translatedKey = ETokens[key];
    return decoded[translatedKey] !== undefined ? decoded[translatedKey] : defaultValue;
  }
}

export default Session;

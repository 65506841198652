import type { TFunction } from 'i18next';

interface INestedErrors {
  [key: string | number]: string | string[] | INestedErrors | INestedErrors[] | undefined;
}

function isTFunction(arg: TFunction | string): arg is TFunction {
  return typeof arg !== 'string';
}

export function errorMessage(error: unknown, t: TFunction): string;
export function errorMessage(error: unknown, unknownError: string): string;

export function errorMessage(error: unknown, tOrUnknownError: TFunction | string): string {
  const unknownError: string = isTFunction(tOrUnknownError)
    ? tOrUnknownError('An unknown error occurred')
    : tOrUnknownError;

  if (error === undefined || error === null) {
    return unknownError;
  }
  if (error instanceof Error) {
    // RachisError, which extends Error sets message to the same value as detail
    return error.message;
  }
  if (typeof error === 'object' && 'message' in error && typeof error.message === 'string') {
    return error.message;
  }
  if (typeof error === 'string') {
    return error;
  }
  return unknownError;
}

export function flattenError(err?: string | string[] | INestedErrors | INestedErrors[]): string[] {
  if (!err) {
    return [];
  }

  function flatten(o: string | string[] | INestedErrors | INestedErrors[]): string[] {
    return Object.values(o)
      .flatMap((value) =>
        typeof value === 'object' && value !== null ? flatten(value as INestedErrors) : value,
      )
      .filter((v): v is string => !!v);
  }

  // Deduplicate error messages before returning.
  return [...new Set(flatten(err))];
}

export function flattenErrors(obj: INestedErrors): string[] {
  return flattenError(obj);
}

/**
 * @param order If omitted, object will be sorted alphabetically.
 */
export function sortErrors(obj: INestedErrors, order?: Array<keyof INestedErrors>): INestedErrors {
  return (
    Object.keys(obj)
      // Sorts alphabetically if no order is provided.
      .sort((a, b) => (order?.length ? order.indexOf(a) - order.indexOf(b) : a.localeCompare(b)))
      .reduce((acc, key) => {
        acc[key] = obj[key];

        return acc;
      }, {})
  );
}

import classNames from 'classnames';
import type { IObservableArray } from 'mobx';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, Segment, Targetable, Targeting } from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import {
  AlertV2 as Alert,
  Button,
  ButtonValid,
  CardV2 as Card,
  EAlertV2Type as EAlertType,
  EmptyState,
  Form,
} from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { flattenErrors, getIconForAction } from '@feathr/hooks';

import { useText } from './AdWizardTargetsStep.useText';
import {
  getTargetables,
  getTargetSegments,
  validateStepTargets,
} from './AdWizardTargetsStep.utils';
import AffinityTargeting from './AffinityTargeting';
import EmailListTargeting from './EmailListTargeting';
import GeoFenceTargeting from './GeoFenceTargeting';
import LookalikeTargeting from './LookalikeTargeting';
import SearchKeywordTargeting from './SearchKeywordTargeting';
import SeedSegmentTargeting from './SeedSegmentTargeting';
import SegmentTargeting from './SegmentTargeting';

import * as styles from '../AdWizard.css';

interface IProps {
  onNext: () => void;
  onPrev: () => void;
  campaign: Campaign;
  targetings: IObservableArray<Targeting>;
}

const targetingKinds: Partial<Record<CampaignClass, string>> = {
  [CampaignClass.Segment]: 'segment',
  [CampaignClass.Lookalike]: 'lookalike',
  [CampaignClass.SeedSegment]: 'lookalike',
  [CampaignClass.Affinity]: 'lookalike',
  [CampaignClass.EmailList]: 'email_list',
  [CampaignClass.Search]: 'search',
  [CampaignClass.MobileGeoFencing]: 'geofence',
  [CampaignClass.MobileGeoFenceRetargeting]: 'geo_audience',
  [CampaignClass.Facebook]: 'segment',
  [CampaignClass.EmailListFacebook]: 'email_list',
};

const NextStepButton = observer(({ campaign, targetings, onNext }: Omit<IProps, 'onPrev'>) => {
  const { Segments, Targetables } = useStore();
  const { isSegmentCampaign } = campaign;
  const { t } = useTranslation();

  let targetables: Segment[] | Targetable[] = [];
  if (isSegmentCampaign) {
    targetables = getTargetSegments(targetings, Segments);
  } else if (
    [
      CampaignClass.Search,
      CampaignClass.EmailList,
      CampaignClass.Lookalike,
      CampaignClass.SeedSegment,
      CampaignClass.Affinity,
      CampaignClass.MobileGeoFencing,
      CampaignClass.MobileGeoFenceRetargeting,
      CampaignClass.EmailListFacebook,
    ].includes(campaign.get('_cls'))
  ) {
    targetables = getTargetables(targetings, Targetables);
  }
  const validationErrors = validateStepTargets(campaign, targetings, targetables);
  return (
    <ButtonValid errors={flattenErrors(validationErrors)} name={'next_step'} onClick={onNext}>
      {t('Next')}
    </ButtonValid>
  );
});

function AdWizardTargetsStep({
  campaign,
  targetings,
  onNext,
  onPrev,
}: Readonly<IProps>): JSX.Element {
  const cls = campaign.get('_cls');
  const { description, helpDeskUrl } = useText({ cls });
  const {
    isGeofencingCampaign,
    isSegmentCampaign,
    isEmailMappingCampaign,
    isDisabledCampaignState,
  } = campaign;

  const { Targetings } = useStore();
  const { t } = useTranslation();
  const { isTTDCampaign } = campaign;

  const removeTargeting = useCallback(
    (targeting: Targeting) => {
      if (targeting.isEphemeral) {
        targeting.collection!.remove(targeting.id);
        targetings.remove(targeting);
      } else {
        targeting.set({ is_archived: true });
      }
    },
    [targetings],
  );

  const onClick = useCallback(() => {
    const model = Targetings.create({
      parent: campaign.get('id'),
      kind: targetingKinds[cls],
      included: true,
    });
    runInAction(() => {
      targetings.push(model);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetings]);

  const isDisabledCampaignClass = isGeofencingCampaign || isEmailMappingCampaign;

  const searchTargetings = targetings.filter((t) => {
    return t.get('kind') === 'search' && !t.get('is_archived');
  });

  const filteredTargetings = targetings.filter(
    (t) => !t.get('is_archived') && t.get('kind') !== 'geo',
  );

  const addTargetButton = (
    <Button
      disabled={
        (isDisabledCampaignState && isDisabledCampaignClass) ||
        (campaign.get('_cls') === CampaignClass.Search && searchTargetings.length >= 1)
      }
      key={'add'}
      name={'add_target'}
      onClick={onClick}
      prefix={getIconForAction('add')}
    >
      {t('Add target')}
    </Button>
  );

  return (
    <Form
      actions={
        // TODO: Remove this when we horizontal wizard facebook campaigns
        !isTTDCampaign && [
          <Button key={'prev'} name={'previous_step'} onClick={onPrev}>
            {t('Previous')}
          </Button>,
          <NextStepButton
            campaign={campaign}
            key={'next'}
            onNext={onNext}
            targetings={targetings}
          />,
        ]
      }
      className={classNames({ [styles.formRoot]: isTTDCampaign })}
      label={'Edit Campaign: Targets'}
      width={'wide'}
    >
      {/* Targets with groups need extra space to build predicates */}
      <Card width={isSegmentCampaign ? 'wide' : 'narrow'}>
        <Card.Header
          description={
            <>
              {description}
              {helpDeskUrl}
            </>
          }
          title={t('Targets')}
        />
        <Card.Content addVerticalGap={true}>
          {isGeofencingCampaign && (
            <Alert
              className={styles.alert}
              title={t('Geofence locations within the EU are no longer available due to GDPR.')}
              type={EAlertType.warning}
            >
              <a
                href={
                  'https://help.feathr.co/hc/en-us/articles/360039271953-Data-Protection-Compliance-with-Feathr'
                }
                target={'_blank'}
              >
                {t('Learn more about GDPR Compliance')}
              </a>
            </Alert>
          )}
          {filteredTargetings.map((targeting) => {
            if (isSegmentCampaign) {
              return (
                <SegmentTargeting
                  campaign={campaign}
                  key={targeting.listId}
                  onRemove={removeTargeting}
                  targeting={targeting}
                  targetings={targetings}
                />
              );
            }
            if (cls === CampaignClass.Lookalike) {
              return (
                <LookalikeTargeting
                  campaign={campaign}
                  key={targeting.listId}
                  onRemove={removeTargeting}
                  targeting={targeting}
                />
              );
            }
            if (cls === CampaignClass.SeedSegment) {
              return (
                <SeedSegmentTargeting
                  campaign={campaign}
                  key={targeting.listId}
                  onRemove={removeTargeting}
                  targeting={targeting}
                />
              );
            }
            if (cls === CampaignClass.Affinity) {
              return (
                <AffinityTargeting
                  campaign={campaign}
                  key={targeting.listId}
                  onRemove={removeTargeting}
                  targeting={targeting}
                />
              );
            }
            if (isEmailMappingCampaign) {
              return (
                <EmailListTargeting
                  campaign={campaign}
                  key={targeting.listId}
                  onRemove={removeTargeting}
                  targeting={targeting}
                />
              );
            }
            if (cls === CampaignClass.Search) {
              return (
                <SearchKeywordTargeting
                  campaign={campaign}
                  key={targeting.listId}
                  onRemove={removeTargeting}
                  targeting={targeting}
                />
              );
            }
            if (isGeofencingCampaign) {
              return (
                <GeoFenceTargeting
                  campaign={campaign}
                  key={targeting.listId}
                  onRemove={removeTargeting}
                  targeting={targeting}
                />
              );
            }
            throw new Error('Unexpected campaign class in AdWizardTargetsStep');
          })}
          {filteredTargetings.length === 0 ? (
            <EmptyState
              description={t('Add a target to get started')}
              label={t('No targets added')}
              theme={'slate'}
            >
              {addTargetButton}
            </EmptyState>
          ) : (
            addTargetButton
          )}
        </Card.Content>
      </Card>
    </Form>
  );
}

export default observer(AdWizardTargetsStep);

import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Template } from '@feathr/blackbox';
import { Input } from '@feathr/components';

import SuggestMergeTags from './SuggestMergeTags';

interface IProps {
  template: Template;
}

function EmailTemplateMetadataInputs({ template }: IProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <SuggestMergeTags
        input={
          <Input
            attribute={'subject'}
            helpText={t(
              'This is the subject line that will be used when emails are sent using this Template.',
            )}
            label={t('Subject')}
            model={template}
            type={'text'}
          />
        }
        path={'subject'}
        template={template}
      />
      {template.isPinpointTemplate && (
        <Input
          attribute={'preview_text'}
          helpText={t(
            'The preview text that displays along with the subject line in some email clients.',
          )}
          label={t('Preview text')}
          model={template}
          type={'text'}
        />
      )}
    </>
  );
}

export default observer(EmailTemplateMetadataInputs);

import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { BannersnackCreative, DisplayCreative } from '@feathr/blackbox';
import { Form } from '@feathr/components';
import BannersnackEditor from '@feathr/extender/components/BannersnackEditor';

import * as styles from './CreativeEditor.css';

interface IProps {
  bannersnackClassName?: string;
  creative: DisplayCreative;
}

function CreativeEditor({ bannersnackClassName, creative }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  return (
    <Form className={styles.root} label={t('Edit creative')}>
      <BannersnackEditor
        model={creative as BannersnackCreative}
        wrapperClassName={classNames(styles.bannersnackEditor, bannersnackClassName)}
      />
    </Form>
  );
}

export default observer(CreativeEditor);

import type { IBaseAttributes } from '@feathr/rachis';

import type { IAddress } from '../address';
import type { IPredicate } from '../segments';
import type { IStats } from '../stats';
import type { TAffinityCampaign } from './affinity';
import type { TConversationCampaign } from './conversation';
import type { TEmailListCampaign } from './email_list';
import type { TFacebookCampaign } from './facebook';
import type { TLandingPageCampaign } from './landing_page';
import type { TLookalikeCampaign } from './lookalike';
import type { TMobileGeoFenceRetargetingCampaign } from './mobile_geo_fence_retargeting';
import type { TMobileGeoFencingCampaign } from './mobile_geo_fencing';
import type { IMonetizationAttributes } from './monetization';
import type { TReferralCampaign } from './referral';
import type { TSearchCampaign } from './search';
import type { TSeedSegmentCampaign } from './seed_segment';
import type { ISegmentCampaignAttributes } from './segment';
import type { TTrackedLinkCampaign } from './tracked_link';

export interface INextStep {
  cta: string;
  ctaUrl: string;
  message: string;
}

export enum CampaignState {
  Draft = 'draft',
  Stopped = 'stopped',
  Erroring = 'erroring',
  Published = 'published',
  Publishing = 'publishing',
  Archived = 'archived',
}

// TODO: Update TTD campaigns to use this enum when the backend is updated
export enum ETTDCampaignClass {
  Affinity = 'Campaign.DisplayCampaign.RTBCampaign.AffinityCampaign',
  Lookalike = 'Campaign.DisplayCampaign.RTBCampaign.LookalikeCampaign',
  EmailList = 'Campaign.DisplayCampaign.RTBCampaign.EmailListCampaign',
  MobileGeoFenceRetargeting = 'Campaign.DisplayCampaign.RTBCampaign.MobileGeofenceRetargetingCampaign',
  MobileGeoFencing = 'Campaign.DisplayCampaign.RTBCampaign.MobileGeofencingCampaign',
  Search = 'Campaign.DisplayCampaign.RTBCampaign.SearchCampaign',
  SeedSegment = 'Campaign.DisplayCampaign.RTBCampaign.SeedSegmentCampaign',
  Segment = 'Campaign.DisplayCampaign.RTBCampaign.SegmentCampaign',
}

export enum CampaignClass {
  Affinity = 'Campaign.DisplayCampaign.RTBCampaign.AffinityCampaign',
  AutoPinpointEmail = 'Campaign.PinpointEmailCampaign.SmartPinpointEmailCampaign.AutoPinpointEmailCampaign',
  Conversation = 'Campaign.ConversationCampaign',
  Drip = 'Campaign.DripCampaign',
  DripStep = 'Campaign.PinpointEmailCampaign.SmartPinpointEmailCampaign.AutoPinpointEmailCampaign.DripCampaignStep',
  EmailList = 'Campaign.DisplayCampaign.RTBCampaign.EmailListCampaign',
  EmailListFacebook = 'Campaign.DisplayCampaign.RTBCampaign.FacebookCampaign.EmailListFacebookCampaign',
  Facebook = 'Campaign.DisplayCampaign.RTBCampaign.FacebookCampaign',
  GoogleAdsSmart = 'Campaign.DisplayCampaign.RTBCampaign.GoogleCampaign.GoogleAdsSmartCampaign',
  LandingPage = 'Campaign.LandingPageCampaign',
  Lookalike = 'Campaign.DisplayCampaign.RTBCampaign.LookalikeCampaign',
  MobileGeoFenceRetargeting = 'Campaign.DisplayCampaign.RTBCampaign.MobileGeofenceRetargetingCampaign',
  MobileGeoFencing = 'Campaign.DisplayCampaign.RTBCampaign.MobileGeofencingCampaign',
  PinpointEmail = 'Campaign.PinpointEmailCampaign',
  PinpointPartnerMessage = 'Campaign.PinpointEmailCampaign.PinpointPartnerMessage',
  Referral = 'Campaign.ReferralCampaign',
  Search = 'Campaign.DisplayCampaign.RTBCampaign.SearchCampaign',
  SeedSegment = 'Campaign.DisplayCampaign.RTBCampaign.SeedSegmentCampaign',
  Segment = 'Campaign.DisplayCampaign.RTBCampaign.SegmentCampaign',
  SmartPinpointEmail = 'Campaign.PinpointEmailCampaign.SmartPinpointEmailCampaign',
  TrackedLink = 'Campaign.TrackedLinkCampaign',
}

export interface ICampaignSyncProgress {
  synced_campaign: boolean;
  synced_adgroup: boolean;
  synced_audience: boolean;
  synced_targetings: boolean;
  synced_creatives: boolean;
  built_proximity: boolean;
  applied_proximity: boolean;
  uploaded_emaillist: boolean;
}

enum ELinkedCampaignKind {
  autosend_existing = 'autosend_existing',
  drip_campaign_step = 'drip_campaign_step',
}

interface ILinkedCampaign {
  kind: ELinkedCampaignKind;
  /** campaign id */
  campaign: string;
}

export interface ICampaignAttributes extends IBaseAttributes {
  readonly _cls: CampaignClass;
  /** User id */
  readonly created_by: string;
  readonly event: string;
  readonly parent: string;
  readonly parent_kind: string;
  readonly sync_progress: ICampaignSyncProgress;
  alt_text?: string;
  cloned_from?: string;
  conversion_tracking_mode: 'auto' | 'advanced';
  date_created: string;
  date_start: string;
  date_end: string;
  date_last_modified: string;
  /**
   * Toggles calculation between legacy bloomfilter and new live stats.
   * Defaults to false.
   */
  enable_live_reach: boolean;
  exposure_settings: IExposureSettings;
  linked_campaigns: ILinkedCampaign[];
  flight?: string;
  is_enabled: boolean;
  name: string;
  rerun: boolean;
  state: CampaignState;
  subadvertiser?: string;
  total_stats: IStats;
}

export interface IMergeField {
  name: string;
  type: string;
  value: string;
  defaultPath?: string;
  isCustom?: boolean;
}

export type TCampaignGroup = 'ads' | 'all' | 'email' | 'monetization' | 'other' | 'google-ads';
type ExposureSettingsTargetType = 'fixed_budget' | 'fixed_impressions' | 'uncapped';
type ExposureSettingsPace = 'pace_to_end_of_day' | 'pace_to_end_of_flight' | 'pace_ahead' | 'off';

// TODO: Further refine this interface to only include these settings where they are utilized
export interface IExposureSettings {
  // TTD and google use pace
  // defaulted in backend to pace_to_end_of_flight
  pace?: ExposureSettingsPace;
  // TTD and Google can use this
  recommended_target_percent?: boolean;
  // TTD use custom target
  // defaulted in backend to true - should be false
  custom_target?: boolean;
  // TTD uses target type
  // defaulted in backend to fixed_budget
  target_type?: ExposureSettingsTargetType;
  // Everything uses target value
  // defaulted in backend to 0 - should be undefined
  target_value?: number;
  /** Google Ads daily average budget */
  // TTD and Google ads use this but it will be just Google after this update
  target_cap_daily?: number;
}

export interface IRecipientPreferencesConfig {
  account_label: string;
  button_color: string;
  button_text_color: string;
  button_text: string;
  campaign_label: string;
  description: string;
  event_label: string;
  response: string;
  title: string;
}

export interface ICampaignSegment {
  id: string;
  included: boolean;
}

export type TCampaignEmailTestStatus =
  | 'pending'
  | 'integrating'
  | 'in_progress'
  | 'completed'
  | 'failed';

export interface IConsent {
  user?: string;
  has_consent?: boolean;
  date_consented?: string;
}

export type TPinpointSubtype = 'activity' | 'update' | 'time';

export enum ELinkedCampaignSetAction {
  clicked_link_previous_email = 'clicked_link_previous_email',
  not_clicked_link_previous_email = 'not_clicked_link_previous_email',
  not_opened_previous_email = 'not_opened_previous_email',
  opened_previous_email = 'opened_previous_email',
  previous_email_delivered = 'previous_email_delivered',
}

export interface IDripStepCampaign extends IAutoPinpointEmailBaseCampaign {
  _cls: CampaignClass.DripStep;
  drip_campaign: string;
  set_action?: ELinkedCampaignSetAction;
  step_number: number;
}

/** Excluded because they aren't used in the auto send campaign variant drip campaigns generate */
export const dripExcludedAttributes = [
  'id',
  '_cls',
  'bidding_strategy',
  'total_stats',
  'date_last_modified',
  'exposure_settings',
  'is_enabled',
  'parent_id',
  'rerun',
  'state',
  'segments',
] as const;

// The `as const` above is required to properly infer individual array elements
type TDripExclusions = (typeof dripExcludedAttributes)[number];

// Just the props that are added on the step specs object
export interface IDripCampaignStepSpec extends Omit<IAutoPinpointEmailCampaign, TDripExclusions> {
  set_action?: ELinkedCampaignSetAction;
}

export interface IEmailBaseCampaign extends ICampaignAttributes {
  readonly _cls:
    | CampaignClass.AutoPinpointEmail
    | CampaignClass.Drip
    | CampaignClass.DripStep
    | CampaignClass.PinpointEmail
    | CampaignClass.PinpointPartnerMessage
    | CampaignClass.SmartPinpointEmail;
  consent?: IConsent;
  date_send_end: string;
  date_send_start: string;
  segments: ICampaignSegment[];
}

/**
 * Drip campaigns are a series of emails that are sent to a person over time.
 *
 * - `actions`: The actions that are set on the first step of the drip.
 * - `date_send_end`: Calculated based on the latest end from the steps.
 * - `date_send_start`: Calculated based on the earliest start from the steps.
 * - `exit_on_conversions`: Whether person should exit the drip when they meet the campaign goal.
 * - `step_specs`: A list of blueprint objects (for building autosend campaigns) - essentially mirrors the attributes from AutoPinpointEmailCampaign.
 */
export interface IDripCampaign extends IEmailBaseCampaign {
  readonly _cls: CampaignClass.Drip;
  actions: IPinpointTrigger[];
  exit_on_conversion: boolean;
  send_all: boolean;
  step_specs: IDripCampaignStepSpec[];
}

export interface IPinpointEmailBaseCampaign extends IEmailBaseCampaign {
  readonly _cls:
    | CampaignClass.AutoPinpointEmail
    | CampaignClass.DripStep
    | CampaignClass.PinpointEmail
    | CampaignClass.SmartPinpointEmail
    | CampaignClass.PinpointPartnerMessage;
  address?: IAddress;
  endpoint_type: 'primary_email' | 'all_emails';
  filename?: string;
  from_address: string;
  from_name?: string;
  plain_text_body: string;
  preferences_config: IRecipientPreferencesConfig;
  /**
   * The content domain that "View in browser" links in emails from this campaign
   * will be served from
   */
  redirect: string;
  /** The "View in browser" link for this campaign */
  redirect_url: string;
  s3_key?: string;
  send_schedule?: 'now' | 'later' | 'dynamic';
  subject?: string;
  subtype?: TPinpointSubtype;
  template_id: string;
  test_campaign_status: TCampaignEmailTestStatus;
  time_to_send?: string;
}

export interface IPinpointEmailCampaign
  extends IPinpointEmailBaseCampaign,
    IMonetizationAttributes {
  readonly _cls: CampaignClass.PinpointEmail;
}

/**
 * "Attribute" predicates for email campaigns work slightly differently
 * than traditional attribute predicates. We want to send an email in
 * response to a person being updated with an attribute, not target people
 * who have that attribute already. For that reason, the backend expects
 * the predicate kind for email campaigns to be "update".
 */
export interface IPinpointTrigger extends Omit<IPredicate, 'group'> {
  kind: 'update';
}

/**
 * Anhinga supports the following units of time for delays:
 * seconds, minutes, hours, days, and weeks. However, we only allow the user to set all of these except seconds.
 * So, for the dropdowns, use type TUnitOfTime which excludes seconds.
 */
export type TBaseUnitOfTime = 'seconds' | 'minutes' | 'hours' | 'days' | 'weeks';
export type TUnitOfTime = Exclude<TBaseUnitOfTime, 'seconds'>;

export interface ISmartPinpointEmailBaseCampaign extends IPinpointEmailBaseCampaign {
  _cls: CampaignClass.AutoPinpointEmail | CampaignClass.DripStep | CampaignClass.SmartPinpointEmail;
  actions: IPinpointTrigger[];
  delay_value: number;
  delay_unit: TBaseUnitOfTime;
  mode: 'match_all' | 'match_any';
}

export interface ISmartPinpointEmailCampaign extends ISmartPinpointEmailBaseCampaign {
  readonly _cls: CampaignClass.SmartPinpointEmail;
  send_all: boolean;
}

export interface IAutoPinpointEmailBaseCampaign extends ISmartPinpointEmailBaseCampaign {
  readonly _cls: CampaignClass.AutoPinpointEmail | CampaignClass.DripStep;
  repeat: boolean;
  cooldown_value?: number;
  cooldown_unit?: TUnitOfTime;
}

export interface IAutoPinpointEmailCampaign extends IAutoPinpointEmailBaseCampaign {
  readonly _cls: CampaignClass.AutoPinpointEmail;
}

export interface IPinpointPartnerMessage extends IPinpointEmailBaseCampaign {
  readonly _cls: CampaignClass.PinpointPartnerMessage;
  invites_campaign?: string;
  date_created: string;
  date_send_start: string;
  event: string;
  from_address: string;
  from_name: string;
  name: string;
  participation: {
    mode: 'manual' | 'event' | 'campaign';
    partner_ids: string[];
  };
  state: CampaignState;
  template_id: string;
}

export interface IGoogleAdsCampaign extends ICampaignAttributes {
  business_name: string;
  date_last_upsynced: string;
  date_last_downsynced: string;
  resource_name: string;
  status: number;
}

export interface IGoogleAdsSmartCampaign extends IGoogleAdsCampaign {
  readonly _cls: CampaignClass.GoogleAdsSmart;
  advertising_language_code: 'en';
  keyword_themes: IGoogleAdsKeyword[];
  phone_number_country_code?: string;
  phone_number?: string;
}

/** The types that can be passed to the suggest method */
export type TGoogleAdsSuggestions =
  | IGoogleAdsSuggestedAdText
  | IGoogleAdsSuggestedKeywordThemes
  | IGoogleAdsSuggestedBudgets;

export interface IGoogleAdsSuggestProps {
  type: 'budget' | 'ad' | 'keyword';
  businessName?: string;
  destinationUrl: string;
  keywordThemes?: IGoogleAdsKeyword[];
}

export interface IGoogleAdsSuggestedKeywordThemes extends IBaseAttributes {
  keyword_themes: IGoogleAdsKeyword[];
}

export interface IGoogleAdsKeyword {
  display_name: string;
  resource_name?: string;
  negative: boolean;
  suggestion: boolean;
}

/**
 * Represents the budget information for a Google Ads campaign.
 * - `daily_amount`: The daily budget amount.
 * - `monthly_amount`: The monthly budget amount calculated based on the daily amount.
 * - `min_daily_clicks`: The minimum estimated daily clicks for the budget.
 * - `max_daily_clicks`: The maximum estimated daily clicks for the budget.
 */
export interface IGoogleAdsBudget {
  daily_amount: number;
  monthly_amount: number;
  min_daily_clicks: number;
  max_daily_clicks: number;
}

export enum EGoogleAdsBudgetType {
  low = 'low',
  recommended = 'recommended',
  high = 'high',
}

type TBudgetKeys = keyof typeof EGoogleAdsBudgetType;
type TBudgetTypes = { [key in TBudgetKeys]: IGoogleAdsBudget };

/** Response for the Google Ads suggested budgets */
export interface IGoogleAdsSuggestedBudgets extends IBaseAttributes, TBudgetTypes {}

export interface IGoogleAdsAdText {
  text: string;
  key: string;
}

export interface IGoogleAdsSuggestedAdText extends IBaseAttributes {
  headlines: IGoogleAdsAdText[];
  descriptions: IGoogleAdsAdText[];
}
export interface IGoogleAdsLocationSuggestion extends IBaseAttributes {
  geo_target_constant: string;
  name: string;
  canonical_name: string;
  target_type: string;
  reach: number;
}

export type TCampaignAttributes =
  | IAutoPinpointEmailCampaign
  | IAutoPinpointEmailBaseCampaign
  | ICampaignAttributes
  | IDripCampaign
  | IDripStepCampaign
  | IEmailBaseCampaign
  | IGoogleAdsCampaign
  | IGoogleAdsSmartCampaign
  | IPinpointEmailBaseCampaign
  | IPinpointEmailCampaign
  | IPinpointPartnerMessage
  | ISegmentCampaignAttributes
  | ISmartPinpointEmailBaseCampaign
  | ISmartPinpointEmailCampaign
  | TAffinityCampaign
  | TConversationCampaign
  | TEmailListCampaign
  | TFacebookCampaign
  | TLandingPageCampaign
  | TLookalikeCampaign
  | TMobileGeoFenceRetargetingCampaign
  | TMobileGeoFencingCampaign
  | TReferralCampaign
  | TSearchCampaign
  | TSeedSegmentCampaign
  | TTrackedLinkCampaign;

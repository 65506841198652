import type { UseFormInput } from '@mantine/form';
import { isNotEmpty, useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Event, IAddBillingAdjustmentArgs } from '@feathr/blackbox';
import { EDepartment } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  NumberInput,
  Select,
  submitModelWithErrorHandling,
  toast,
} from '@feathr/components';
import { enumToObject } from '@feathr/hooks';

interface IProps {
  event: Event;
  isFullWidth?: boolean;
}

interface IDepartmentOption extends ISelectOption {
  id: EDepartment;
}

function CreateTransactionButton({ event, isFullWidth }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure(false);

  function getDepartmentValue(department?: EDepartment): IDepartmentOption | undefined {
    if (!department) {
      return undefined;
    }
    return departmentOptions.find((option) => option.id === department);
  }

  // Tweaks to input props so our components work with Mantine's useForm
  const enhanceGetInputProps: UseFormInput<
    Partial<IAddBillingAdjustmentArgs>
  >['enhanceGetInputProps'] = function (payload) {
    const enhancedProps: Record<string, unknown> = {};

    if (payload.field === 'department') {
      // Special handling for Select
      enhancedProps.onChange = (value: IDepartmentOption | undefined): void => {
        form.setFieldValue('department', value?.id);
      };
      enhancedProps.value = getDepartmentValue(payload.inputProps.value);
    }

    // Our components use validationError instead of error
    enhancedProps.validationError = payload.inputProps.error;
    return enhancedProps;
  };

  const form = useForm<Partial<IAddBillingAdjustmentArgs>>({
    initialValues: {
      amount: 0,
      department: undefined,
      reason: undefined,
      sync_to_intacct: false,
    },
    enhanceGetInputProps,
    validate: {
      amount: (v) => (v !== 0 ? null : t('Amount must not be zero')),
      department: isNotEmpty(t('Department is required')),
      reason: isNotEmpty(t('Reason is required')),
    },
    validateInputOnBlur: true,
  });

  async function handleConfirm(): Promise<void> {
    if (!form.isValid()) {
      return;
    }
    await submitModelWithErrorHandling({
      // We can safely cast type to not be partial because we validated the form
      operation: event.addBillingAdjustment(form.values as IAddBillingAdjustmentArgs),
      onSuccess: () => {
        toast('Transaction successfully created.', { type: 'success' });
        form.reset();
        close();
      },
      t,
    });
  }

  const departments = enumToObject(EDepartment);
  const departmentOptions: IDepartmentOption[] = Object.entries(departments).map<IDepartmentOption>(
    ([name, id]) => ({
      id: id as EDepartment,
      name,
    }),
  );

  return (
    <>
      <Button isFullWidth={isFullWidth} onClick={open}>
        {t('Create transaction')}
      </Button>
      <Modal
        description={t('Create a new transaction for this project.')}
        onClose={close}
        opened={opened}
        rightActions={
          <>
            <Button onClick={close}>{t('Cancel')}</Button>
            <Button
              disabled={!form.isDirty() || !form.isValid()}
              onClick={handleConfirm}
              type={'primary'}
            >
              {t('Create')}
            </Button>
          </>
        }
        title={t('Create Transaction')}
      >
        <Form label={t('Create Transaction')}>
          {/* TODO: Keep Select at the top until there's a fix for the bug with Modal overflow */}
          <Select<IDepartmentOption>
            {...form.getInputProps('department')}
            key={form.key('department')}
            label={t('Department')}
            name={'department'}
            options={departmentOptions}
            placeholder={t('Select department...')}
          />
          <NumberInput
            {...form.getInputProps('amount')}
            key={form.key('amount')}
            label={t('Amount')}
            name={'amount'}
            prefix={'$'}
          />
          <Input
            {...form.getInputProps('reason')}
            key={form.key('reason')}
            label={t('Reason')}
            name={'reason'}
          />
          <Checkbox
            {...form.getInputProps('sync_to_intacct')}
            key={form.key('sync_to_intacct')}
            label={t('Sync to Intacct')}
            layout={'well'}
            name={'sync_to_intacct'}
          />
        </Form>
      </Modal>
    </>
  );
}

export default CreateTransactionButton;

import debounce from 'debounce-promise';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { OptionProps, SingleValueProps } from 'react-select';
import { components } from 'react-select';
import type { OptionsList, Response } from 'react-select-async-paginate';

import type { IThirdPartyDataOption, Targetable } from '@feathr/blackbox';
import { AsyncSelectPaginated, toast } from '@feathr/components';
import { DEFAULT_DEBOUNCE_WAIT } from '@feathr/hooks';

import * as styles from './AffinityTargetingDataSelect.css';

interface IProps {
  targetable: Targetable;
}

type TOptionProps = OptionProps<IThirdPartyDataOption>;
type TSingleValueProps = SingleValueProps<IThirdPartyDataOption>;

interface IDataProps {
  data: IThirdPartyDataOption;
}

function DataOption(props: Readonly<TOptionProps>): JSX.Element {
  const { t } = useTranslation();
  const { data } = props as IDataProps;
  return (
    <components.Option {...props}>
      <div>
        <div>
          <div>
            <strong>{data.Name}</strong>
            <span> | </span>
            <span>{t('{{count, number}} Person', { count: data.UniqueUserCount })}</span>
          </div>
          <div className={styles.path}>
            <p>{data.FullPath}</p>
            <p className={styles.description}>{data.Description}</p>
          </div>
        </div>
      </div>
    </components.Option>
  );
}

function DataSingleValue(props: Readonly<TSingleValueProps>): JSX.Element {
  const { t } = useTranslation();
  const { data } = props as IDataProps;
  return (
    <components.SingleValue {...props}>
      <div className={styles.single}>
        <div>
          <div>
            <strong>{data.Name}</strong>
            <span> | </span>
            <span>{t('{{count, number}} Person', { count: data.UniqueUserCount })}</span>
          </div>
        </div>
      </div>
    </components.SingleValue>
  );
}

function AffinityTargetingDataSelect({ targetable }: Readonly<IProps>): JSX.Element {
  const [, setSegmentQuery] = useState('');
  const { t } = useTranslation();
  const categoryId = targetable.get('category_id');

  function getDataOptionValue({ ThirdPartyDataId }: IThirdPartyDataOption): string {
    return ThirdPartyDataId;
  }

  function handleDataSelect({
    Name,
    ThirdPartyDataId,
    BrandName,
    Description,
    UniqueUserCount,
    FullPath,
  }: IThirdPartyDataOption): void {
    targetable.set({
      name: Name,
      thirdparty_data_id: ThirdPartyDataId,
      thirdparty_data_name: Name,
      thirdparty_brand_name: BrandName,
      description: Description,
      unique_user_count: UniqueUserCount,
      full_path: FullPath,
    });
  }

  const loadOptions = useCallback(
    async (
      inputValue: string,
      _options: OptionsList<IThirdPartyDataOption>,
      { page }: { page: number } = { page: 1 },
    ): Promise<Response<IThirdPartyDataOption, { page: number }>> => {
      if (!categoryId) {
        return AsyncSelectPaginated.EMPTY_PAGINATION;
      }

      try {
        const options = await targetable.getPaginatedThirdPartyData({
          inputValue,
          page,
          pageSize: AsyncSelectPaginated.DEFAULT_PAGE_SIZE,
        });
        return {
          options,
          hasMore: options.length > 0 && options.length >= AsyncSelectPaginated.DEFAULT_PAGE_SIZE,
          additional: {
            page: page + 1,
          },
        };
      } catch (error) {
        toast(
          t('Something went wrong while retrieving affinity audiences:\n{{- error}}', { error }),
          {
            type: 'error',
          },
        );

        return AsyncSelectPaginated.EMPTY_PAGINATION;
      }
    },
    [targetable, t],
  );

  const debouncedLoadOptions = useMemo(
    () => debounce(loadOptions, DEFAULT_DEBOUNCE_WAIT),
    [loadOptions],
  );

  const validateAudience = useCallback((): string | undefined => {
    return !targetable.isAttributeDirty('thirdparty_data_id') && targetable.get('audience_inactive')
      ? t('Affinity audience unavailable. You must select a different audience.')
      : undefined;
  }, [t, targetable]);

  // Clear the targetable when the category changes
  useEffect(() => {
    targetable.set({
      name: undefined,
      thirdparty_data_id: undefined,
      thirdparty_data_name: undefined,
      thirdparty_brand_name: undefined,
      description: undefined,
      unique_user_count: undefined,
      full_path: undefined,
    });
  }, [categoryId, targetable]);

  const value = targetable.get('thirdparty_data_id')
    ? ({
        ThirdPartyDataId: targetable.get('thirdparty_data_id'),
        Name: targetable.get('thirdparty_data_name'),
        UniqueUserCount: targetable.get('unique_user_count'),
        FullPath: targetable.get('full_path'),
        Description: targetable.get('description'),
      } as IThirdPartyDataOption)
    : undefined;

  return (
    <AsyncSelectPaginated<IThirdPartyDataOption, { page: number }>
      additional={{ page: 1 }}
      cacheOptions={true}
      components={{ Option: DataOption, SingleValue: DataSingleValue }}
      defaultOptions={true}
      getOptionValue={getDataOptionValue}
      helpText={t('Search for, and target, an affinity audience.')}
      key={categoryId}
      label={t('Affinity audience')}
      loadOptions={debouncedLoadOptions}
      name={'affinity_audience_select'}
      onInputChange={setSegmentQuery}
      onSelectSingle={handleDataSelect}
      paginate={true}
      placeholder={t('Choose affinity audience')}
      required={true}
      validationError={validateAudience()}
      value={value}
    />
  );
}

export default observer(AffinityTargetingDataSelect);

import type { EditorEvents } from '@tiptap/react';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Form } from '@feathr/blackbox';
import { CardV2 as Card, RichTextEditorV2 as RichTextEditor, SaveButton } from '@feathr/components';

interface IProps {
  form: Form;
}

function Settings({ form }: IProps): JSX.Element {
  const { t } = useTranslation();

  function handleChange(properties: EditorEvents['update']): void {
    const content = properties.editor.getHTML();
    form.set({ post_submit_html: content });
  }

  return (
    <Card width={'wide'}>
      <Card.Header title={t('Thank You Message')} />
      <Card.Content>
        <RichTextEditor
          content={form.get('post_submit_html')}
          excludeControls={{ heading: ['h5', 'h6'] }}
          onChange={handleChange}
        />
      </Card.Content>
      <Card.Actions>
        <SaveButton method={'patch'} model={form}>
          {t('Apply')}
        </SaveButton>
      </Card.Actions>
    </Card>
  );
}

export default observer(Settings);

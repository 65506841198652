import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Goal, ReferralCampaign } from '@feathr/blackbox';
import { Button, Form } from '@feathr/components';
import { useStore } from '@feathr/extender/state';

import type { ICampaignValidationErrors } from '../../CampaignSummary';
import CampaignSummary from '../../CampaignSummary';
import SaveCampaignButton from '../SaveCampaignButton/index';
import { getGoalSegments } from '../StepGoals';
import type { IValidateReferralCampaignProps } from './ReferralCampaignEdit';

interface IProps {
  onPrev: () => void;
  campaign: ReferralCampaign;
  goals: IObservableArray<Goal>;
  validate: (details: IValidateReferralCampaignProps) => ICampaignValidationErrors;
}

function Review({ campaign, goals, onPrev, validate }: IProps) {
  const { Segments } = useStore();
  const goalSegments = getGoalSegments(goals, Segments);
  const { t } = useTranslation();

  const errors = validate({
    campaign,
    goals,
    goalSegments,
  });

  return (
    <Form
      actions={[
        <Button key={'prev'} onClick={onPrev}>
          Previous
        </Button>,
        <SaveCampaignButton
          campaign={campaign}
          childModels={[...goals]}
          grandchildModels={[...goalSegments]}
          key={'save'}
          shouldChangeState={true}
          validate={() => validate({ campaign, goals, goalSegments })}
        />,
      ]}
      description={t(
        "Check the summary below to make sure everything is configured correctly. When you're ready, Publish your campaign!",
      )}
      label={t('Edit Campaign: Review')}
    >
      <CampaignSummary campaign={campaign} validationErrors={errors} />
    </Form>
  );
}

export default observer(Review);
